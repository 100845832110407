import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import ListMembersAndAssetsStyles from 'src/components/MapSidebar/components/ListMembersAndAssets/ListMembersAndAssets.module.scss';
import { EManagerManagersView } from 'src/features/Managers/ManageManagers';
import AssetsViewStyles from 'src/features/Members/SingleMember/SingleMember.module.scss';
import { useInviteAndAssignCommunityManagerToScmMutation } from 'src/graphql';
import { ListCommunityManagersDocument } from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import s from './NewManager.module.scss';
import { TNewManagerProps, TNewManagerFormData } from './NewManager.types';

const EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const NewManager: React.FC<TNewManagerProps> = ({ setManageManagersView }) => {
  const { t } = useTranslation();

  const configUuid = useSelector(selectActiveConfigurationUuid);

  const [formData, setFormData] = useState<TNewManagerFormData>({ email: '' });

  const [inviteAndAssignCommunityManager] = useInviteAndAssignCommunityManagerToScmMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ListCommunityManagersDocument, variables: { configUuid: configUuid } },
    ],
    onCompleted: () => {
      dispatchSuccessToast('Invitation sent');
      setManageManagersView(EManagerManagersView.LIST);
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const onClickAllManagers = () => {
    setManageManagersView(EManagerManagersView.LIST);
  };

  const onChange = useCallback(
    ({ name, value }) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setFormData],
  );

  const onSubmit = useCallback(
    () =>
      configUuid &&
      inviteAndAssignCommunityManager({
        variables: { configUuid, name: formData.email, email: formData.email },
      }),
    [formData, configUuid, inviteAndAssignCommunityManager],
  );

  const canSave = formData.email && EMAIL_VALIDATION.test(formData.email);

  return (
    <div>
      <div className={AssetsViewStyles.header} onClick={onClickAllManagers}>
        <BaseIcon
          size={14}
          icon="arrow-left-full"
          className={ListMembersAndAssetsStyles.iconBack}
        />
        {t('navigation.ALL_MANAGERS')}
      </div>
      <div className={s.container}>
        <div className={s.formContainer}>
          <div className={s.formContainer}>
            <BaseInput
              type="text"
              theme="line-dark"
              name="email"
              label="Email"
              value={formData.email}
              onChange={onChange}
              disabled={false}
            />
          </div>

          <BaseButton type="button" onClick={onSubmit} className={s.formButton} disabled={!canSave}>
            {t('commands.SAVE')}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
