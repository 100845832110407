import { ScmCommunityMember } from 'src/graphql';

export type TSingleMemberProps = {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetsList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAssetClick?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddAssetClick?: (value: any) => void;
};

export enum NewUserRole {
  Member = 'Member',
  Manager = 'Manager',
}

export type TAssetsViewUserTileProps = {
  uuid?: string | null;
  name?: string;
  avatarUrl?: string;
  email?: string;
  isAdmin?: boolean;
  address?: string;
  isNewUser?: boolean;
  zipCode?: string;
  toggleAssetView?: () => void;
};

export type TUserChangeableInfo = Pick<
  TAssetsViewUserTileProps,
  'name' | 'email' | 'address' | 'zipCode'
>;

export type TUserTileShowDataProps = TUserChangeableInfo & {
  setFormData: React.Dispatch<React.SetStateAction<TUserChangeableInfo>>;
  onSubmit: () => void;
};

export type TFormErrorAreas = Partial<Record<keyof TFormDataState, string>>;

export type TUserTileFormProps = {
  formErrorAreas: TFormErrorAreas;
  values: ScmCommunityMember;
  onChange: ({ name, value }) => void;
  className?: string;
};
export type TFormDataState = TUserChangeableInfo;
