import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import s from 'src/components/ModalAssetsManager/ModalAssetsManager.module.scss';
import { TLiteSummaryPopupProps } from 'src/features/Results/SingleCommunityResults/SingleCommunityResults.types';
import { useAssetsInside } from 'src/hooks/useAssetsInside';
import {
  selectCommunityAsset,
  selectConfiguration,
} from 'src/redux/configuration/configuration.selectors';
import { formatter } from 'src/utils/formatter';

export const LiteSummaryPopup: React.FC<TLiteSummaryPopupProps> = () => {
  const configurationCharacteristic = useSelector(selectConfiguration);
  const communityAsset = useSelector(selectCommunityAsset);
  const { assetsInside } = useAssetsInside({ hostAssetUuid: communityAsset?.uuid });

  return (
    <div className={classNames(s.modalTooltip)}>
      <div className={classNames(s.modalInner, 'gradient-dark')}>
        <div className={s.paddingAround}>
          {/* view {JSON.stringify(view)} */}
          <header className={s.header}>
            <div className={s.typeIcon}>
              <BaseIcon icon={'market'} size={12} />
            </div>

            <h3 className={s.title}>{configurationCharacteristic.name}</h3>
          </header>

          <ul className={s.assetsList}>
            {assetsInside.map((asset, index) => (
              <li key={index}>
                <button
                  type="button"
                  className={s.assetSquareBtn}
                  onClick={() => void 0}
                  disabled={false}>
                  <BaseIcon icon={asset.icon} size={16.25} />
                  <label className={s.assetsAmount}>
                    {formatter.prefixWithZeros(asset.amount)}
                  </label>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={s.triangle} />
    </div>
  );
};
