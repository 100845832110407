import { camelCase } from 'lodash';

export const objectCamelCase = <T>(obj: Record<string, unknown>): T => {
  const tmpObject: Record<string, unknown> = {};
  if (!obj) return {} as T;
  Object.keys(obj).forEach((item) => {
    if (item === 'consumption_kWh_profile') {
      return (tmpObject['consumptionKwhProfile'] = obj[item]);
    }
    tmpObject[camelCase(item)] = obj[item];
  });
  return tmpObject as T;
};
