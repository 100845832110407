import React from 'react';

import { ListManagers } from './ListManagers';
import s from './ManageManagers.module.scss';
import { NewManager } from './NewManager';

export enum EManagerManagersView {
  NEW = 'NEW',
  LIST = 'LIST',
}

export const ManageManagers: React.FC = () => {
  const [manageManagersView, setManageManagersView] = React.useState<EManagerManagersView>(
    EManagerManagersView.LIST,
  );

  return (
    <div className={s.container}>
      {(() => {
        switch (manageManagersView) {
          case EManagerManagersView.NEW:
            return <NewManager setManageManagersView={setManageManagersView} />;
          case EManagerManagersView.LIST:
            return <ListManagers setManageManagersView={setManageManagersView} />;
        }
      })()}
    </div>
  );
};
