import React, { useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { InputField } from 'src/components/InputField';
import { Tabs } from 'src/components/Tabs';
import { ETabs } from 'src/components/Tabs';
import singleAssetStyles from 'src/features/Assets/SingleAsset/SingleAsset.module.scss';
import { TFormAssetProps } from 'src/features/Assets/SingleAsset/SingleAsset.types';
import { useReadConfigurationQuery } from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

import s from '../CustomPV/CustomPV.module.scss';
import heatpumpfields from './fields';

export const CustomHeatPump: React.FC<TFormAssetProps> = ({
  values,
  handleChange,
  handleBlur,
}: TFormAssetProps) => {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !activeConfigurationUuid,
    variables: { uuid: activeConfigurationUuid! },
  });
  const configuration = useMemo(() => configurationResponse?.readConfiguration, [
    configurationResponse,
  ]);
  const fields: TFieldsTemplateUnion[] = useMemo(
    () => heatpumpfields({ configType: configuration?.type, values }),
    [configuration, values],
  );

  const onChange = ({ name, value }) => {
    handleChange([{ name, value }]);
  };

  return (
    <div className={classnames(s.customPV, s['light'])}>
      <Tabs handleClick={() => null} activeTab={ETabs.BASIC} tabs={[ETabs.BASIC]} />
      <div className={singleAssetStyles.fieldsContainer}>
        {fields
          .filter((field) => field.formView == ETabs.BASIC)
          .map((field: TFieldsTemplateUnion) => (
            <InputField
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...(field as any)}
              key={field.name as string}
              value={!!values && !!values[field.name] ? values[field.name] : field.defaultValue}
              fieldType={field.type}
              onChange={onChange}
            />
          ))}
      </div>
    </div>
  );
};
