import { ETabs } from 'src/components/Tabs';
import { TFieldsAssetArgs } from 'src/features/Assets/SingleAsset/SingleAsset.types';
import { ConfigType } from 'src/graphql';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';
import { getParentGeotagLocation } from 'src/utils/assetsFields/fieldTemplates/utils';

const Load = ({ configType }: TFieldsAssetArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.ASSET_NAME',
      defaultValue: 'Load',
      formView: ETabs.BASIC,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'labels.LOCATION',
      tooltipText: 'Parent market already has location',
      defaultValue: getParentGeotagLocation(),
      formView: ETabs.BASIC,
    },
    {
      name: 'dailyLoadProfile',
      type: 'file',
      label: 'labels.UPLOAD_PROFILE',
      tooltipText: 'tooltips.UPLOAD_LOAD_PROFILE_INFO',
      formView: ETabs.BASIC,
      disabled: configType === ConfigType.CanaryNetwork,
    },
  ];
};

export default Load;
