import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { Maybe } from 'src/graphql';
import { TUploadedProfile } from 'src/typings/base-types';
import { TFileFieldTemplate, TFileFieldWithValue } from 'src/utils/assetsFields/assetsFields.types';

/*
 *
 * Helpers
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getFile(file: TUploadedProfile | undefined) {
  if (typeof file === 'undefined') {
    return undefined;
  } else if (typeof file === 'string') {
    return file;
  } else {
    return uploadedFilesCache.get(file);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function optional<T>(key: keyof T, object: Partial<T>) {
  if (key in object) {
    return { [key]: object[key] };
  }

  return null;
}

export const optionalOrNull = <T>(
  key: keyof T,
  object: Partial<T>,
): { [key: string]: Maybe<T[keyof T]> | undefined } => {
  if (key in object) {
    return { [key]: object[key] };
  }

  return { [key]: null };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function optionalFile(
  key: TFileFieldTemplate['name'],
  value: TFileFieldWithValue['value'] | undefined,
) {
  if (value) {
    return { [key]: getFile(value) || value };
  }

  return null;
}
