import React, { useMemo } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { BaseCheckbox } from 'src/components/BaseCheckbox';
import { BaseCounter } from 'src/components/BaseCounter';
import { BaseDateRangePicker } from 'src/components/BaseDateRangePicker';
import { BaseFileUpload } from 'src/components/BaseFileUpload';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSlider } from 'src/components/BaseSlider';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { InfoHelper } from 'src/components/InfoHelper';
import { LocationSearch } from 'src/components/LocationSearch';
import { convertLngLat } from 'src/utils/worldMap/helpers';

import s from './InputField.module.scss';
import { TInputFieldProps } from './InputField.types';

export const InputField: React.FC<TInputFieldProps> = ({
  fieldType,
  error,
  name,
  label,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange = ({}: any) => null,
  onBlur,
  disabled,
  value,
  showTooltip,
  tooltipText,
  unit,
  options = [] as { value: string; label: string }[],
  theme,
  inlineAlign,
  minVal,
  maxVal,
  step,
  min,
  max,
  allowDownload,
  valueFormat,
  defaultValue,
  exclude,
  inputHeight,
  startValue,
  endValue,
  tags,
  minDate,
  maxDate,
}) => {
  const { t } = useTranslation();

  const fieldProps = useMemo(
    () => ({
      disabled,
      error,
      label: `${t(label)}`,
      name: name,
      onChange,
      defaultValue,
    }),
    [disabled, error, label, name, onChange, t, defaultValue],
  );

  const calculatedTheme = theme || 'line-dark';
  const errorWidth = undefined;
  const containerProps = {
    inlineAlign,
    showTooltip: false,
    tooltipText,
    key: name,
  };

  if (exclude) return null;

  return (
    <FieldContainer
      {...containerProps}
      key={name}
      className={classnames({
        [s.inputField]: fieldType !== 'switcher',
        [s.switchWrapper]: fieldType === 'switcher',
      })}>
      {(() => {
        switch (fieldType) {
          case 'text':
            return (
              <BaseInput
                {...fieldProps}
                type="text"
                theme={calculatedTheme}
                value={value}
                showTooltip={showTooltip}
                tooltipText={String(t(tooltipText))}
                errorWidth={errorWidth}
                autoComplete="off"
                onBlur={onBlur}
              />
            );

          case 'textarea':
            return (
              <BaseTextarea
                {...fieldProps}
                theme={calculatedTheme}
                inputHeight={inputHeight || '2'}
                value={value}
                errorWidth={errorWidth}
                onBlur={onBlur}
              />
            );

          case 'number':
            return (
              <BaseInput
                {...fieldProps}
                type="number"
                theme={calculatedTheme}
                value={value}
                unit={unit}
                errorWidth={errorWidth}
                onBlur={onBlur}
                showTooltip={showTooltip}
                tooltipText={String(t(tooltipText))}
              />
            );

          case 'switcher':
            return (
              <BaseSwitch
                {...fieldProps}
                className={s.switch}
                value={value}
                options={options.map((option) => ({
                  ...option,
                  icon: 'defaultIcon' as TIconNames,
                }))}
                theme={theme === 'dark' ? 'light' : 'gradient-dark'}
                variant="horizontal-edge"
                showTooltip
                tooltipText={tooltipText}
              />
            );

          case 'location':
            return (
              <FieldContainer {...containerProps} className={s.locationField}>
                <LocationSearch
                  {...fieldProps}
                  disabled={disabled}
                  value={value}
                  onChange={({ name, ...rest }) => {
                    onChange({ name, value: convertLngLat(rest) });
                  }}
                  theme={calculatedTheme}
                  iconLeft={null}
                  iconRight={'locate'}
                  iconRightColor={vars['color-oh-so-green']}
                  elevateLabel={true}
                  dropdownClassName={s.locationSearchDropdown}
                  onBlur={onBlur}
                />
              </FieldContainer>
            );

          case 'enum':
            return (
              <BaseSelect
                {...fieldProps}
                value={value}
                options={options.map((option) => ({
                  value: option.value,
                  label: option.label.includes('labels') ? String(t(option.label)) : option.label,
                }))}
                theme={calculatedTheme}
                showTooltip
                tooltipText={String(t(tooltipText))}
              />
            );

          case 'slider':
            return (
              <BaseSlider
                {...fieldProps}
                value={value}
                min={minVal}
                max={maxVal}
                step={step}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                label={`${label}: ${Array.isArray(value) ? (value as any[]).join('–') : value} ${
                  unit || ''
                }`}
                showButtons={false}
              />
            );

          case 'file':
            return (
              <BaseFileUpload
                {...fieldProps}
                showTooltip
                tooltipText={tooltipText}
                value={value}
                unit={unit}
                theme={calculatedTheme}
                allowDownload={allowDownload}
              />
            );

          case 'checkbox':
            return (
              <BaseCheckbox
                {...fieldProps}
                value={value}
                theme={calculatedTheme === 'line-dark' ? 'gradient-dark-extended' : 'light'}
              />
            );

          case 'counter':
            return <BaseCounter {...fieldProps} value={value} min={min} max={max} />;

          case 'dateRange':
            return (
              <>
                {label && (
                  <div className={s.lengthWrapper}>
                    <div className={s.lengthText}>{label}</div>
                    <InfoHelper
                      className={s.lengthTooltip}
                      info="Choose the dates for the run time of the simulation below. Simulations can run from 1 day up to 1 month."
                    />
                  </div>
                )}
                <BaseDateRangePicker
                  {...fieldProps}
                  theme={calculatedTheme}
                  startValue={startValue}
                  endValue={endValue}
                  valueFormat={valueFormat}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={onChange}
                  tags={tags}
                />
              </>
            );

          default:
            return null;
        }
      })()}
    </FieldContainer>
  );
};
