import { useMemo, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal } from 'src/redux/modals/modals.slice';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { v4 } from 'uuid';

export type TUseModalData = {
  id: string;
  modalTriggerRef: React.RefObject<HTMLButtonElement | HTMLDivElement | null>;
  onClick: (menuTriggerRef: HTMLButtonElement | HTMLDivElement | null) => void;
};

export const useModal = (): TUseModalData => {
  const modalId = useMemo<string>(() => v4(), []);
  const dispatch = useAppDispatch();
  const modalIsOpen = useSelector(selectIsModalOpened(modalId));

  const [modalTriggerRef, setModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | HTMLDivElement | null>
  >({
    current: null,
  });

  const close = useCallback(() => {
    setModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  }, [dispatch, modalId]);

  const open = useCallback(
    (menuTriggerRef: HTMLButtonElement | HTMLDivElement | null) => {
      setModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    },
    [dispatch, modalId],
  );

  const onClick = useCallback(
    (menuTriggerRef: HTMLButtonElement | HTMLDivElement | null) => {
      if (modalIsOpen) {
        close();
      } else {
        open(menuTriggerRef);
      }
    },
    [modalIsOpen, open, close],
  );

  return {
    id: modalId,
    modalTriggerRef,
    onClick,
  };
};
