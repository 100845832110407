import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigurationOutput } from 'src/graphql';

export type TCommunitiesState = {
  // TODO types
  communitiesList: Array<ConfigurationOutput>;
  inBuildCommunity: boolean;
  communityToCompare: string | null;
  selectedTimelineInterval: string | null;
  userToAdminRequests: {
    [key: string]: {
      type: string;
      status: string;
    }[];
  };
};

export const initialCommunitiesState: TCommunitiesState = {
  communitiesList: [],
  inBuildCommunity: false,
  communityToCompare: null,
  selectedTimelineInterval: null,
  userToAdminRequests: {},
};

const communitiesSlice = createSlice({
  name: 'communities',
  initialState: initialCommunitiesState,
  reducers: {
    setCommunitiesList(state, action: PayloadAction<TCommunitiesState['communitiesList']>) {
      state.communitiesList = action.payload;
    },
    setInBuildCommunityUuid(state, action: PayloadAction<TCommunitiesState['inBuildCommunity']>) {
      state.inBuildCommunity = action.payload;
    },
    setCommunityToCompare(state, action: PayloadAction<TCommunitiesState['communityToCompare']>) {
      state.communityToCompare = action.payload;
    },
    setSelectedTimelineInterval(
      state,
      action: PayloadAction<TCommunitiesState['selectedTimelineInterval']>,
    ) {
      state.selectedTimelineInterval = action.payload;
    },
    setUserToAdminRequests(state, action: PayloadAction<TCommunitiesState['userToAdminRequests']>) {
      state.userToAdminRequests = action.payload;
    },
  },
});

// Actions
export const {
  setCommunitiesList,
  setCommunityToCompare,
  setInBuildCommunityUuid,
  setSelectedTimelineInterval,
  setUserToAdminRequests,
} = communitiesSlice.actions;

// Reducer
export const communitiesReducer = communitiesSlice.reducer;
