import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChartSelf, EChartSelfType } from 'src/components/Charts/ChartSelf';
import { PieChart } from 'src/components/Charts/PieChart';
import {
  EnergyBillComponents,
  EnergyBillSubtitles,
  TListEnergyBillInfo,
} from 'src/components/EnergyBillComponents';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultProgress } from 'src/components/KeyResults/components/KeyResultProgress';
import { KeyResultsMember } from 'src/components/KeyResults/KeyResultsMember';
import { EPredefinedModalIds } from 'src/constants/modals';
import { DashboardHeader } from 'src/features/Results/DashboardHeader';
import {
  TBillsFees,
  TSCMHomeKpi,
  TSingleAreaResultsProps,
} from 'src/features/Results/SingleAreaResults/SingleAreaResults.types';
import {
  EnergyBillInfoContent,
  MyEnergyProfileInfoText,
  SingleHomeNotification,
  SavingsBenchmarkInfoText,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/features/Results/SingleAreaResults/SingleAreaResultsConstants';
import { FEE_MAPPINGS } from 'src/features/Results/SingleAreaResults/SingleAreaResultsConstants';
import { useScmAccumulatedBillsDifferencesQuery } from 'src/graphql';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAccumulatedResultsWithSubscription } from 'src/hooks/useAccumulatedResultsWithSubscription';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationJobUuid,
  selectSelectedAreaUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectDataResolution,
  selectHomeKpi,
  selectIsOperationalCommunity,
  selectKpiDifference,
} from 'src/redux/scm/scm.selectors';
import { setHomeKpi, setIsAppLoading, setKpiDifference } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EScreenMode } from 'src/typings/configuration.types';
import { TKpi } from 'src/typings/simulation.types';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';

import s from './SingleAreaResults.module.scss';
import { createBillInfo, withCurrency } from './utils';

export const SingleAreaResults: React.FC<TSingleAreaResultsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const settingsData = useSelector(selectSettingsData);
  const homeKpi = useSelector(selectHomeKpi);
  const kpiDifference = useSelector(selectKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();

  const {
    resultsData,
    kpiDifferenceData,
    resultsStartTime,
    resultsEndTime,
  } = useAccumulatedResults({
    initialRunQueries:
      //isOperationalCommunity
      //? []
      //:
      ['getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
  });

  const { data: billsDifferenceResponse } = useScmAccumulatedBillsDifferencesQuery({
    fetchPolicy: 'cache-first',
    skip: !activeJobUuid || !selectedAreaUuid || !resultsStartTime || !resultsEndTime,
    variables: {
      jobId: activeJobUuid!,
      uuid: selectedAreaUuid!,
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });
  const billsDifference = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bills = objectCamelCase<any>(
      !!billsDifferenceResponse?.scmAccumulatedBillsDifferences
        ? JSON.parse(billsDifferenceResponse.scmAccumulatedBillsDifferences)
        : {},
    );
    if (Object.keys(bills).length > 0) {
      bills.fees = objectCamelCase<TBillsFees>(bills.fees);
    }
    return bills;
  }, [billsDifferenceResponse]);

  useAccumulatedResultsWithSubscription({
    mode: 'home',
  });

  useSingleNotification({
    singleNotification: SingleHomeNotification,
    checkStatement: !isOperationalCommunity && isOwnerCM,
  });

  const currencySymbol = formatter.getCurrencySymbol(settingsData.currency);

  const listEnergyBillInfo = useMemo<TListEnergyBillInfo | null>(() => {
    if (Object.keys(billsDifference).length == 0) return null;

    const {
      spentToCommunity,
      gsyEnergyBillExclRevenueWithoutFees,
      gsyEnergyBillExclRevenue,
      importGridFees,
      fees,
    } = billsDifference;

    const taxes =
      importGridFees +
      Object.entries(fees).reduce((sum, [key, value]) => {
        if (!key.endsWith('Percent')) {
          sum += Number(value);
        }
        return sum;
      }, 0);
    const spentToGrid = gsyEnergyBillExclRevenueWithoutFees - spentToCommunity - taxes;
    const spentToGridPercent = spentToGrid / gsyEnergyBillExclRevenue;

    const billInfo = [
      createBillInfo(
        '#66EE66',
        t('bill_components.SPENT_TO_GRID'),
        spentToGridPercent,
        spentToGrid,
        currencySymbol,
      ),
      createBillInfo(
        '#FFB347',
        t('bill_components.SPENT_TO_COMMUNITY'),
        spentToCommunity / gsyEnergyBillExclRevenue,
        spentToCommunity,
        currencySymbol,
      ),
      createBillInfo(
        '#FC1355',
        t('bill_components.TAXES'),
        !!(taxes / gsyEnergyBillExclRevenue) ? taxes / gsyEnergyBillExclRevenue : 0,
        !!taxes ? taxes : 0,
        currencySymbol,
      ),
    ];

    FEE_MAPPINGS.forEach(({ key, color, labelKey }) => {
      if (fees.hasOwnProperty(key) && fees[key] > 0) {
        billInfo.push(
          createBillInfo(color, t(labelKey), fees[`${key}Percent`], fees[key], currencySymbol),
        );
      }
    });

    return billInfo;
  }, [billsDifference, currencySymbol, t]);

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  // unset the homekpi, kpidifference when unmounting the component
  useEffect(() => {
    return () => {
      dispatch(setHomeKpi(undefined));
      dispatch(setKpiDifference(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (resultsData?.kpi && resultsData?.billsEnergy)
      dispatch(
        setHomeKpi(
          pickKpiData({
            kpi: JSON.parse(resultsData?.kpi),
            billsEnergy: JSON.parse(resultsData?.billsEnergy),
          }),
        ),
      );
    if (!isOperationalCommunity && resultsData?.kpi) {
      dispatch(setIsAppLoading(false));
    }
  }, [resultsData, dispatch, isOperationalCommunity]);

  useEffect(() => {
    if (kpiDifferenceData)
      dispatch(setKpiDifference(objectCamelCase<TSCMHomeKpi>(JSON.parse(kpiDifferenceData))));
  }, [kpiDifferenceData, dispatch]);

  if (!sidebarExpanded) return null;

  return (
    <div className={s.container}>
      <DashboardHeader mode="home" className={s.dashboardHeader} />
      <div className={s.keyResultsMember}>
        <KeyResultsMember
          horizontal={true}
          mode={'Home'}
          currency={settingsData.currency}
          homeBillValue={billsDifference?.gsyEnergyBill}
          homeSavings={billsDifference?.savings}
          totalBenefit={billsDifference?.gsyTotalBenefit}
          title={`⚡ ${t('bill_components.ENERGY_BILL')}`}
        />
      </div>
      <div className={s.homeEnergyInfo}>
        <HomeEnergyInfo
          generatedValue={`${
            kpiDifference?.totalEnergyProducedKwh
              ? kpiDifference?.totalEnergyProducedKwh.toFixed(0)
              : 0
          } kWh`}
          consumedValue={`${
            kpiDifference?.totalEnergyDemandedKwh
              ? kpiDifference?.totalEnergyDemandedKwh.toFixed(0)
              : 0
          } kWh`}
        />
      </div>
      <div className={s.energyBillComponents}>
        {listEnergyBillInfo && (
          <EnergyBillComponents
            key={listEnergyBillInfo.map((item) => item.cost).join('')}
            list={listEnergyBillInfo}
            total={
              billsDifference?.gsyEnergyBillExclRevenue
                ? withCurrency(currencySymbol, billsDifference.gsyEnergyBillExclRevenue)
                : ''
            }
            info={<EnergyBillInfoContent />}
            title={`${t('bill_components.ENERGY_BILL_COMPONENTS')}`}
            subTitle={t(EnergyBillSubtitles[dataResolution]) || ''}
          />
        )}
      </div>
      <div className={s.selfSufficiency}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifference || {})}
          type={EChartSelfType.Sufficiency}
          className={s.selfItem}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfSufficiencyInfoText) || ''}
          isCommaSeparated={true}
        />
      </div>
      <div className={s.selfConsumption}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifference || {})}
          type={EChartSelfType.Consumption}
          className={s.selfItem}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfConsumptionInfoText) || ''}
          isCommaSeparated={true}
        />
      </div>
      <div className={s.keyResultProgress}>
        {billsDifference && (
          <KeyResultProgress
            title={t('MY_ENERGY_SAVINGS_RANKING_IN_COMMUNITY')}
            info={t(SavingsBenchmarkInfoText) || ''}
            percentage={parseInt((billsDifference.energyBenchmark * 100).toFixed(0))}
            badges={[]}
            className={s.savingsRanking}
          />
        )}
      </div>
      <PieChart
        title="Energy Supply Share"
        items={[
          {
            label: 'Community Energy',
            value: billsDifference?.boughtFromCommunity || 0,
            info: '',
          },
          {
            label: 'Imported Energy (Utility)',
            value: billsDifference?.boughtFromGrid || 0,
            info: '',
          },
          {
            label: 'Home Energy',
            value: kpiDifference?.totalSelfConsumptionKwh || 0,
            info: '',
          },
        ]}
        isDecimalPercentage={true}
        isTitleInfoActive={true}
        isItemInfoActive={false}
        titleInfo={t(SelfSufficiencyInfoText) || ''}
        className={s.energySupplyShare}
      />
      <div className={s.energyProfile}>
        {homeKpi && (
          <EnergyProfileComponent
            title={t('MY_ENERGY_PROFILE')}
            info={t(MyEnergyProfileInfoText) || ''}
            mode={dataResolution}
            initialData={homeKpi}
          />
        )}
      </div>
    </div>
  );
};
