import React from 'react';

import s from './GridMarket.module.scss';

type GridMarketModalHeaderProps = {
  formTitle: string;
  onClick?: () => void;
};

export const GridMarketModalHeader: React.FC<GridMarketModalHeaderProps> = ({
  formTitle,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      <header className={s.header}>
        <h3 className={s.title}>{formTitle}</h3>
      </header>
    </div>
  );
};
