import React from 'react';

import { useSelector } from 'react-redux';
import { ManagerInvite } from 'src/features/Managers/ListManagers/ManagerInvite';
import { SingleManagerTile } from 'src/features/Managers/ListManagers/SingleManagerTile';
import { EManagerManagersView } from 'src/features/Managers/ManageManagers';
import { useListCommunityManagersQuery } from 'src/graphql';
import { useDeleteCommunityManagersMutation } from 'src/graphql';
import { ListCommunityManagersDocument } from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import s from './ListManager.module.scss';
import { TListManagersProps } from './ListManagers.types';

export const ListManagers: React.FC<TListManagersProps> = ({ setManageManagersView }) => {
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const { data: communityManagers } = useListCommunityManagersQuery({
    fetchPolicy: 'cache-first',
    variables: {
      configUuid: configUuid as string,
    },
  });

  const [deleteCommunityManagers] = useDeleteCommunityManagersMutation({
    errorPolicy: 'none',
    refetchQueries: [
      { query: ListCommunityManagersDocument, variables: { configUuid: configUuid } },
    ],
    onCompleted: () => {
      dispatchSuccessToast();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const onClickManagerInvite = () => setManageManagersView(EManagerManagersView.NEW);

  const handleRemove = (email: string) => {
    deleteCommunityManagers({
      variables: { configUuid: configUuid as string, usernames: [email] },
    });
  };

  return (
    <div>
      <ManagerInvite onClick={onClickManagerInvite} />
      <div className={s.container}>
        {communityManagers?.listCommunityManagers?.map((manager) => (
          <SingleManagerTile
            key={manager!.username}
            username={manager!.username}
            handleRemove={handleRemove}
          />
        ))}
      </div>
    </div>
  );
};
