import { TAssetType } from 'src/typings/base-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getSubtitleProperty = (asset: any): string => {
  switch (asset?.type as TAssetType) {
    case 'Load':
      return 'common.CONSUMPTION';
    case 'ScmStorage':
      return 'common.CAPACITY';
    case 'ScmHeatPump':
      return 'common.CAPACITY';
    case 'PV':
      return 'common.CAPACITY';
    default:
      return '';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getPropertyValue = (asset: any): number => {
  switch (asset?.type as TAssetType) {
    case 'Load':
      return 0;
    case 'ScmStorage':
      return 0;
    case 'ScmHeatPump':
      return 0;
    case 'PV':
      return asset.capacityKW || asset.capacityKw || 0;
    default:
      return 0;
  }
};
