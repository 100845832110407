import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectCommunitiesList } from 'src/redux/communities/communities.selectors';
import { TCommunitiesState } from 'src/redux/communities/communities.slice';
import { selectCommunityName } from 'src/redux/configuration/configuration.selectors';
import { $ElementType } from 'utility-types';

type TGetConfigurationArgs = {
  configurationUuidForToUse: string;
};

type TGetConfigurationtoUseReturn = {
  getTheNameOfLaunchConfiguration: (payload: TGetConfigurationArgs) => string;
  getConfigurationToUse: (
    payload: TGetConfigurationArgs,
  ) => $ElementType<TCommunitiesState['communitiesList'], number> | undefined;
};

export function useGetConfigurationToUse(): TGetConfigurationtoUseReturn {
  const communitiesList = useSelector(selectCommunitiesList);
  const communityName = useSelector(selectCommunityName);

  const getConfigurationToUse = useCallback<
    (
      payload: TGetConfigurationArgs,
    ) => $ElementType<TCommunitiesState['communitiesList'], number> | undefined
  >(
    (payload) => {
      return communitiesList.find((item) => {
        return item?.uuid === payload.configurationUuidForToUse;
      });
    },
    [communitiesList],
  );

  const getTheNameOfLaunchConfiguration = useCallback<(payload: TGetConfigurationArgs) => string>(
    (payload) => {
      const configurationForToUse = getConfigurationToUse(payload);

      const cName = configurationForToUse ? (configurationForToUse.name as string) : communityName;

      return cName;
    },
    [getConfigurationToUse, communityName],
  );

  return {
    getTheNameOfLaunchConfiguration,
    getConfigurationToUse,
  };
}
