import { Chart } from 'chart.js';
import ReactDOM from 'react-dom';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { TTooltipFactoryProps } from 'src/components/Charts/TooltipFactory/TooltipFactory.types';

const getOrCreateTooltip = (chart: Chart) => {
  if (!chart.canvas) return;
  const id = `tooltip-${chart.id}`;

  let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector(
    '#' + id,
  );

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.setAttribute('id', id);

    Object.assign(tooltipEl.style, {
      position: 'fixed',
      transform: 'translate(-50%, 0)',
      transition: 'all .1s ease',
      pointerEvents: 'none',
    });

    if (chart.canvas.parentNode) {
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  }

  return tooltipEl;
};

export function TooltipFactory({
  component,
  containerWidth = 220,
  context,
  position,
}: TTooltipFactoryProps): undefined {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (!tooltipEl) return;

  // Hide if no tooltip
  if (
    tooltip.opacity === 0 ||
    !tooltip.body?.length ||
    !tooltip.dataPoints.map((p) => p.raw).filter(Boolean).length
  ) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Render content
  ReactDOM.render(component({ context, width: containerWidth }) || [], tooltipEl);

  // Display, position, and set styles for font
  const canvasRect = chart.canvas.getBoundingClientRect();
  tooltipEl.style.opacity = '1';

  switch (position) {
    case 'next-to-cursor':
      const x = tooltip.caretX > containerWidth + 10 ? 'calc(-100% - 5px)' : '5px';
      tooltipEl.style.left = canvasRect.left + tooltip.caretX + 'px';
      tooltipEl.style.top =
        canvasRect.top - parseInt(vars['map-sidebar-top']) + canvasRect.height / 2 + 'px';
      tooltipEl.style.transform = `translate(${x}, -50%)`;
      break;

    case 'over-bar':
      tooltipEl.style.left = canvasRect.left + tooltip.caretX + 'px';
      tooltipEl.style.top =
        canvasRect.top - parseInt(vars['map-sidebar-top']) + tooltip.caretY + 'px';
      tooltipEl.style.transform = `translate(-15px, calc(-100% - 20px)`;
      break;

    default:
      tooltipEl.style.left =
        Math.min(
          canvasRect.width - containerWidth / 2,
          Math.max(containerWidth / 2, canvasRect.left + tooltip.caretX),
        ) + 'px';
      tooltipEl.style.top =
        canvasRect.top - parseInt(vars['map-sidebar-top']) + tooltip.caretY + 'px';
      break;
  }
}
