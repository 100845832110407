import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TTableBillRowProps } from 'src/components/Charts/TableBills/TableBills.types';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { ESCMGroupSettingsName } from 'src/utils/assetsFields/assetsFields.types';

import s from './TableBills.module.scss';

export const TableBillRow: React.FC<TTableBillRowProps> = ({ bill, onBillClick }) => {
  const roundToTwoDecimals = (num?: number) => {
    return !num ? '0,00' : num.toFixed(2).replace('.', ',');
  };

  const { scmGroupSettingsName } = useSelector(selectSettingsData);

  const isVergy = useMemo(() => scmGroupSettingsName === ESCMGroupSettingsName.VERGY, [
    scmGroupSettingsName,
  ]);
  return (
    <tr onClick={() => onBillClick(bill.uuid)}>
      <td>
        <span className={s.borderLeft}>{bill.assetName}</span>
      </td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.boughtFromGrid)}</td>
      <td className={s.redText}>{roundToTwoDecimals(bill.spentToGrid)}</td>
      <td className={s.blueText}>{roundToTwoDecimals(bill.gridFees)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.soldToGrid)}</td>
      <td className={s.greenText}>{roundToTwoDecimals(bill.earnedFromGrid)}</td>
      {isVergy ? (
        <> </>
      ) : (
        <>
          <td className={s.redText}>{roundToTwoDecimals(bill.boughtFromCommunity)}</td>
          <td className={s.blueText}>{roundToTwoDecimals(bill.spentToCommunity)}</td>
          <td className={s.greyBackground}>{roundToTwoDecimals(bill.soldToCommunity)}</td>
          <td className={s.greenText}>{roundToTwoDecimals(bill.earnedFromCommunity)}</td>
        </>
      )}
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.homeBalanceKwh)}</td>
      <td
        className={classNames(
          bill.homeBalance > 0 ? s.redText : s.greenText,
          s.textLeft,
          s.totalText,
        )}>
        <span>{bill.homeBalance > 0 ? 'Paid' : 'Revenue'}</span>
        {roundToTwoDecimals(Math.abs(bill.homeBalance))}
      </td>
    </tr>
  );
};
