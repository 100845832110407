import React, { useCallback } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { BaseCheckbox } from 'src/components/BaseCheckbox';
import { BaseCounter } from 'src/components/BaseCounter';
import { BaseDateRangePicker } from 'src/components/BaseDateRangePicker';
import { BaseFileUpload } from 'src/components/BaseFileUpload';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSlider } from 'src/components/BaseSlider';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import s from 'src/components/FormFieldsGenerator/FormFieldsGenerator.module.scss';
import { InfoHelper } from 'src/components/InfoHelper';
import { TLocationCoordsObject } from 'src/components/LocationSearch';
import { LocationSearch } from 'src/components/LocationSearch';
import { TFieldValue, TOnChangePayload } from 'src/typings/base-types';
import { TDateRangeFieldWithValue } from 'src/utils/assetsFields/assetsFields.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import { convertLngLat } from 'src/utils/worldMap/helpers';

interface UseFormProps {
  onChange(
    args:
      | (TOnChangePayload<TFieldValue, string> & TLocationCoordsObject)
      | TOnChangePayload<TFieldValue, string>
      | TOnChangePayload<TDateRangeFieldWithValue['value'], string>,
  ): void;
  onBlur?(): void;
  readOnly?: boolean;
  theme?: 'light' | 'dark' | 'filled-gray';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any[];
}

export const useForm = ({
  onChange,
  onBlur,
  readOnly,
  fields,
}: UseFormProps): ((
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputFields: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err?: any,
  fieldsTheme?: string,
) => JSX.Element[]) => {
  const { t } = useTranslation();

  const renderInputFields = useCallback(
    (inputFields, err = undefined, fieldsTheme = 'light') => {
      return inputFields.map((f, index) => {
        const calculatedTheme = 'line-dark';
        const dateRangeTheme = 'dark';
        const containerProps = {
          inlineAlign: f.inlineAlign,
          showTooltip: false,
          tooltipText: f.tooltipText,
          key: f.name,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldProps: any = {
          disabled: f.disabled || readOnly,
          error: err?.[f.name],
          label: `${t(f.label)}`,
          name: f.name,
          onChange,
        };

        const errorWidth = f.fullWidthErr && f.inlineAlign ? '200%' : undefined;
        const marginTopSmall =
          (fields[index - 1] || {}).type === 'switcher' ||
          ((fields[index - 2] || {}).type === 'switcher' && (fields[index - 1] || {}).inlineAlign);

        const renderField = () => {
          switch (f.type) {
            case 'text':
              return (
                <BaseInput
                  {...fieldProps}
                  type="text"
                  theme={calculatedTheme}
                  value={f.value}
                  showTooltip={f.showTooltip}
                  tooltipText={t(f.tooltipText)}
                  errorWidth={errorWidth}
                  autoComplete="off"
                  onBlur={onBlur}
                />
              );

            case 'textarea':
              return (
                <BaseTextarea
                  {...fieldProps}
                  theme={calculatedTheme}
                  inputHeight="2"
                  value={f.value}
                  errorWidth={errorWidth}
                  onBlur={onBlur}
                />
              );

            case 'number':
              return (
                <BaseInput
                  {...fieldProps}
                  type="number"
                  theme={calculatedTheme}
                  value={f.value}
                  unit={f.unit}
                  errorWidth={errorWidth}
                  onBlur={onBlur}
                  showTooltip={f.showTooltip}
                  tooltipText={t(f.tooltipText)}
                />
              );

            case 'switcher':
              return (
                <BaseSwitch
                  {...fieldProps}
                  className={s.switch}
                  value={f.value}
                  options={f.options}
                  theme={fieldsTheme === 'dark' ? 'light' : 'gradient-dark'}
                  variant="horizontal-edge"
                  showTooltip
                  tooltipText={f.tooltipText}
                />
              );

            case 'location':
              return (
                <FieldContainer {...containerProps} className={s.locationField}>
                  <LocationSearch
                    {...fieldProps}
                    disabled={f.disabled || readOnly}
                    value={f.value}
                    onChange={({ name, ...rest }) => {
                      onChange({ name, value: convertLngLat(rest) });
                    }}
                    theme={calculatedTheme}
                    iconLeft={null}
                    iconRight={'locate'}
                    iconRightColor={vars['color-oh-so-green']}
                    elevateLabel={true}
                    dropdownClassName={s.locationSearchDropdown}
                    onBlur={onBlur}
                  />
                </FieldContainer>
              );

            case 'enum':
              return (
                <BaseSelect
                  {...fieldProps}
                  value={f.value}
                  options={f.options.map((option) => ({
                    value: option.value,
                    label: t(option.label),
                  }))}
                  theme={calculatedTheme}
                  showTooltip
                  tooltipText={t(f.tooltipText)}
                />
              );

            case 'slider':
              return (
                <BaseSlider
                  {...fieldProps}
                  value={f.value}
                  min={f.minVal}
                  max={f.maxVal}
                  step={f.step}
                  label={`${f.label}: ${Array.isArray(f.value) ? f.value.join('–') : f.value} ${
                    f.unit || ''
                  }`}
                  showButtons={false}
                />
              );

            case 'file':
              return (
                <BaseFileUpload
                  {...fieldProps}
                  showTooltip
                  tooltipText={f.tooltipText}
                  value={f.value}
                  unit={f.unit}
                  theme={'line-dark'}
                  allowDownload={f.allowDownload}
                />
              );

            case 'checkbox':
              return (
                <BaseCheckbox
                  {...fieldProps}
                  value={f.value}
                  theme={calculatedTheme === 'line-dark' ? 'gradient-dark-extended' : 'light'}
                />
              );

            case 'counter':
              return <BaseCounter {...fieldProps} value={f.value} min={f.min} max={f.max} />;

            case 'dateRange':
              return (
                <>
                  <div className={s.lengthWrapper}>
                    <div className={s.lengthText}>Simulation Length</div>
                    <InfoHelper
                      className={s.lengthTooltip}
                      info="Choose the dates for the run time of the simulation below. Simulations can run from 1 day up to 1 month."
                    />
                  </div>
                  <BaseDateRangePicker
                    {...fieldProps}
                    theme={dateRangeTheme}
                    startValue={f.value.startDate}
                    endValue={f.value.endDate}
                    valueFormat={f.valueFormat}
                    minDate={UTCMoment.utc(f.value.startDate).add(1, 'day').toDate()}
                    maxDate={UTCMoment.utc(f.value.endDate).subtract(1, 'day').toDate()}
                    onChange={({ startDate, endDate }) => {
                      const customEvent = {
                        name: f.name,
                        value: { startDate, endDate },
                      };
                      onChange(customEvent);
                    }}
                    tags={[
                      {
                        value: 7,
                        label: '1 Week',
                        badge: 'Recommended',
                      },
                      {
                        value: 30,
                        label: '1 Month',
                      },
                    ]}
                  />
                </>
              );

            default:
              return null;
          }
        };

        return (
          <FieldContainer
            {...containerProps}
            key={f.name}
            className={classnames({
              [s.inputField]: f.type !== 'switcher',
              [s.switchWrapper]: f.type === 'switcher',
              [s.marginTopSmall]: marginTopSmall,
            })}>
            {renderField()}
          </FieldContainer>
        );
      });
    },
    [onChange, onBlur, readOnly, fields, t],
  );

  return renderInputFields;
};
