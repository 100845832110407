import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { useForm } from 'src/hooks/useForm';

import s from './FormFieldsGenerator.module.scss';
import { TFormFieldsGeneratorProps } from './FormFieldsGenerator.types';

export const FormFieldsGenerator: React.FC<TFormFieldsGeneratorProps> = ({
  className,
  style,
  fields,
  errors,
  onSubmit,
  onChange,
  onBlur,
  id,
  focusField,
  theme,
  readOnly,
  fieldForm,
}) => {
  const containerRef = useRef<HTMLFormElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formFields, setFormFields] = useState<any[]>([]);

  const handleSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'] = (e) => {
    e.preventDefault();

    if (readOnly) return;

    onSubmit();
  };

  useEffect(() => {
    setTimeout(() => {
      if (focusField && containerRef.current) {
        const el:
          | HTMLInputElement
          | HTMLTextAreaElement
          | null = containerRef.current.querySelector(
          `input[name="${focusField}"], textarea[name="${focusField}"]`,
        );

        el?.focus();
      }
    });
  }, [focusField]);

  const renderInputFields = useForm({
    onChange,
    onBlur,
    readOnly,
    theme,
    fields,
  });

  useEffect(() => {
    setFormFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <form
      style={style}
      className={classnames(s.container, className)}
      onSubmit={handleSubmit}
      id={fieldForm ? undefined : id}
      ref={containerRef}>
      {renderInputFields(formFields, errors)}
    </form>
  );
};
