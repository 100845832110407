import React, { useCallback, useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { delay, throttle } from 'lodash';
import { useSelector } from 'react-redux';
import g from 'src/components/ModalAssetsManager/ModalAssetsManager.module.scss';
import { ModalCommunitySummary } from 'src/components/ModalCommunitySummary';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { TGridModalPosState } from 'src/components/WorldMapSelectedCommunityAssets';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { selectCanaryNetworkUsers } from 'src/redux/canaryNetwork/canaryNetwork.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetsTreeRelations,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigType,
  selectConfigurationCenter,
} from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { EUserRoles } from 'src/typings/base-types';
import { convertLngLat } from 'src/utils/worldMap/helpers';

import s from './WorldMapSelectedCommunityAssets.module.scss';
import { TWorldMapSelectedCommunityAssetsProps } from './WorldMapSelectedCommunityAssets.types';

export const WorldMapSelectedCommunityAssets: React.FC<TWorldMapSelectedCommunityAssetsProps> = React.memo(
  ({
    selectedAssetUuid,
    rawSelectedAssetUuid,
    disableInteraction,
    onAddNewCustomHouse,
    onSettingsDataSave,
    onAssetValuesSave,
    onAssetRemove,
    onCommunityRemove,
    onAssetDuplicate,
    onAddAssetUnderUuid,
    addSaveKey,
  }) => {
    const dispatch = useAppDispatch();
    const assetsValues = useSelector(selectAssetsValues);
    const communityAsset = useSelector(selectCommunityAsset);
    const configType = useSelector(selectConfigType);
    const assetsUnderSelectedUuid = useSelector(selectAssetsUnderUuid(communityAsset?.uuid));
    const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
    const configurationCenter = useSelector(selectConfigurationCenter);
    const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
    const cnUsers = useSelector(selectCanaryNetworkUsers);
    const { mapService } = useContext(WorldMapContext);
    const { isUserACommunityMember } = useIsUserACommunityMember();
    const [summaryModalPos, setSummaryModalPos] = useState({
      lng: configurationCenter?.lng,
      lat: configurationCenter?.lat,
    });
    const [gridModalPos, setGridModalPos] = useState<TGridModalPosState>({
      lng: configurationCenter?.lng,
      lat: configurationCenter?.lat,
    });

    const showCommunityOnTheMap =
      communityAsset &&
      (assetsTreeRelations[communityAsset.uuid].length > 1 || activeConfigurationUuid);
    const showCommunityModal = Boolean(
      selectedAssetUuid && selectedAssetUuid === communityAsset?.uuid,
    );
    const isCanaryNetwork = configType === 'CANARY_NETWORK';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateCommunitySummaryModalPos = useCallback(
      throttle(() => {
        const communityRing = mapService?.threeboxController.getCommunityRingObject();

        if (communityRing && configurationCenter) {
          const summaryAdjustedPos = communityRing.calculateAdjustedPosition(
            [configurationCenter.lng, configurationCenter.lat],
            { x: -2.1, y: 0, z: 0 },
          );
          const gridAdjustedPos = communityRing.calculateAdjustedPosition(
            [configurationCenter.lng, configurationCenter.lat],
            { x: -2.15, y: -18, z: 0 },
          );

          setSummaryModalPos({
            lat: summaryAdjustedPos[1],
            lng: summaryAdjustedPos[0],
          });

          setGridModalPos({
            lat: gridAdjustedPos[1],
            lng: gridAdjustedPos[0],
          });
        }
      }, 100),
      [configurationCenter, mapService?.threeboxController, summaryModalPos],
    );

    useEffect(() => {
      delay(() => {
        const communityRing = mapService?.threeboxController.getCommunityRingObject();
        if (!communityRing) {
          setGridModalPos(undefined);
        }
      }, 100);
    }, [mapService?.threeboxController, selectedAssetUuid, rawSelectedAssetUuid]);

    useEffect(() => {
      mapService?.map.on('load', calculateCommunitySummaryModalPos);
      mapService?.map.on('move', calculateCommunitySummaryModalPos);
      return () => {
        mapService?.map.off('load', calculateCommunitySummaryModalPos);
        mapService?.map.off('move', calculateCommunitySummaryModalPos);
      };
    }, [calculateCommunitySummaryModalPos, mapService]);
    const childAssets: {
      uuid: string;
      lat: number;
      lng: number;
    }[] = [];

    assetsUnderSelectedUuid.map((asset) => {
      const parent = assetsTreeRelations[asset.uuid];
      const { geoTagLocation } = assetsValues[asset.uuid];
      let lngLat;
      if (geoTagLocation) {
        lngLat = convertLngLat(geoTagLocation);
      } else {
        lngLat = {
          lat: gridModalPos?.lat,
          lng: gridModalPos?.lng,
        };
      }
      if (parent && parent.length > 0) {
        parent.map((item) => {
          childAssets.push({
            uuid: item,
            lat: lngLat.lat,
            lng: lngLat.lng,
          });
        });
      }
    });

    return (
      <>
        {showCommunityOnTheMap && configurationCenter && communityAsset && (
          <Popup
            lat={summaryModalPos.lat}
            lng={summaryModalPos.lng}
            className={communityAsset.type === 'Area' ? s.rootMarker : s.marker}
            higherZIndex={showCommunityModal}>
            <div>
              {showCommunityModal && (
                <ModalCommunitySummary
                  hostAssetUuid={communityAsset.uuid}
                  onAddAssetUnderUuid={onAddAssetUnderUuid}
                  onAssetDuplicate={onAssetDuplicate}
                  onAssetRemove={onAssetRemove}
                  onAssetValuesSave={onAssetValuesSave}
                  onCommunityRemove={onCommunityRemove}
                  onSettingsDataSave={onSettingsDataSave}
                  onAddNewCustomHouse={onAddNewCustomHouse}
                  addSaveKey={addSaveKey}
                />
              )}
            </div>
          </Popup>
        )}

        {assetsUnderSelectedUuid.map((asset) => {
          const { geoTagLocation, name } = assetsValues[asset.uuid];

          if (!geoTagLocation) return null;
          const lngLat = convertLngLat(geoTagLocation);
          const showThisAssetModal = selectedAssetUuid === asset.uuid;
          const assetName = asset.uuid ? assetsValues[asset.uuid].name : undefined;
          const cnAssetOwner = isCanaryNetwork
            ? cnUsers.find(
                (item) =>
                  item.userRole !== EUserRoles.Aggregator &&
                  item.assetsConfigured.find(
                    (a) => a.uuid === asset.uuid && a.registrationStatus === 'Approved',
                  ),
              )
            : undefined;

          return (
            <Popup
              key={asset.uuid}
              lat={lngLat.lat}
              lng={lngLat.lng}
              className={s.marker}
              higherZIndex={showThisAssetModal}>
              {!showThisAssetModal && isCanaryNetwork && (
                <button
                  type="button"
                  className={s.houseOwnerPopup}
                  disabled={disableInteraction}
                  onClick={() => dispatch(setSelectedAssetUuid(asset.uuid))}>
                  {cnAssetOwner ? (
                    cnAssetOwner.profilePicture ? (
                      <div
                        className={classNames(s.avatar, s.hasAvatar)}
                        style={{ backgroundImage: `url(${cnAssetOwner?.profilePicture})` }}></div>
                    ) : (
                      <div
                        className={s.avatar}
                        style={{
                          backgroundColor: '#a0a0ac',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {cnAssetOwner?.username?.charAt(0).toUpperCase()}
                      </div>
                    )
                  ) : (
                    <div className={s.avatar}></div>
                  )}

                  <span>{name}</span>
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 0L4.37253 6.12154C5.17017 7.23824 6.82983 7.23824 7.62747 6.12155L12 0L0 0Z"
                      fill="#211E21"
                      fillOpacity="0.7"
                    />
                  </svg>
                </button>
              )}

              {showThisAssetModal && isUserACommunityMember && (
                <div className={classNames(g.modal, g.modalTooltip)}>
                  <div className={classNames(g.modalInner, 'gradient-dark')}>
                    <div className={classNames(g.paddingAround, g.scmUserTitle)}>
                      <div className={g.summaryUserCircle} />
                      <h3 className={g.title}>{`${assetName}'s Home`}</h3>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          );
        })}
      </>
    );
  },
);
