import { Currencies, SpotMarketType } from 'src/graphql';
import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import { UTCMoment, BACKEND_DATE_FORMATS } from 'src/utils/UTCMoment';

const today = UTCMoment.utc();
const tomorrow = UTCMoment.utc().add(7, 'day');

export const DEFAULT_SETTINGS_DATA: TSettingsData = {
  slotLengthMinutes: 15,
  spotMarketType: SpotMarketType.Coefficients,
  endDate: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
  endDateTime: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA_END_DATE_TIME),
  startDate: today.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
  currency: Currencies.Eur,
  logo: '',
};
