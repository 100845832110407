import React, { useState } from 'react';

import classNames from 'classnames';
import { DashboardAssetSettings } from 'src/components/AdminDashboards/DashboardAsset/DashboardAssetSettings';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { LibraryChooser, TLibrary, TLibraryChooserProps } from 'src/components/LibraryChooser';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TFilterItem } from 'src/features/Assets/ListAssets/ListAssetsWithSearch';
import { LibraryOutput } from 'src/graphql';
import { TLibrariesTags } from 'src/typings/base-types';
import { get3dAssetIcon } from 'src/utils/assetIcons';

import s from './DashboardAssetCreateNew.module.scss';

export const DashboardAssetCreateNew: React.FC<{
  data: LibraryOutput;
  selectedAsset: string | undefined;
  setMode: React.Dispatch<React.SetStateAction<'assetPreview' | 'addNewAsset'>>;
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
  onUpdate(): void;
}> = ({ data, selectedAsset, setMode, onUpdate }) => {
  const [activeFilter, setActiveFilter] = useState<TLibrariesTags[]>(LIBRARY_FILTERS_MAPPING.All);
  const [selectedNewAsset, setSelectedNewAsset] = useState<TLibrary>();
  const [activeSettingsTab, setActiveSettingsTab] = useState<'basic' | 'advanced'>('basic');

  const onAssetSelect = (data: TLibrary) => setSelectedNewAsset(data);
  const onTabSelect = (tab: typeof activeSettingsTab) => () => setActiveSettingsTab(tab);
  const onFilterChange = (filter: TFilterItem) => setActiveFilter(filter.value);
  const onCancelClick = () => {
    setSelectedNewAsset(undefined);
    if (!selectedNewAsset) setMode('assetPreview');
  };

  const onTreeUpdate = () => onUpdate();

  const disabledLibraryChooserFilters: TLibraryChooserProps['disabledFilters'] = selectedAsset
    ? [LIBRARY_FILTERS_MAPPING.Area]
    : undefined;

  return (
    <div className={s.container}>
      <header className={s.header}>
        <p>Create Asset</p>
        <BaseButton size="mini" theme="grey3" onClick={onCancelClick}>
          Cancel
        </BaseButton>
      </header>
      <div className={s.content}>
        {!selectedNewAsset && (
          <>
            <div className={s.typeSelector}>
              <h5>What asset you want to create?</h5>
              <p>You can create based on any asset we have or create a custom one, your choice.</p>
            </div>
            <LibraryChooser
              onLibraryChoose={onAssetSelect}
              isAllButtonRequiredInFilter={false}
              activeFilter={activeFilter}
              onFilterChange={onFilterChange}
              theme="light"
              disabledFilters={disabledLibraryChooserFilters}
            />
          </>
        )}
        {selectedNewAsset && (
          <div className={s.createAsset}>
            <div className={s.hero}>
              <div className={s.icon}>
                <BaseIcon icon={get3dAssetIcon(selectedNewAsset.type)} />
              </div>
              <h5>{selectedNewAsset.title}</h5>
              <p>{selectedNewAsset.subtitle}</p>
            </div>
            <nav className={s.navigation}>
              <button
                type="button"
                className={classNames({
                  [s.active]: activeSettingsTab === 'basic',
                })}
                onClick={onTabSelect('basic')}>
                Basic Settings
              </button>
              <button
                type="button"
                className={classNames({
                  [s.active]: activeSettingsTab === 'advanced',
                })}
                onClick={onTabSelect('advanced')}>
                Advanced Settings
              </button>
            </nav>
            <DashboardAssetSettings
              mainParentData={data}
              selectedChildUuid={selectedAsset}
              newAssetData={selectedNewAsset}
              mode={activeSettingsTab}
              onUpdate={onTreeUpdate}
            />
          </div>
        )}
      </div>
    </div>
  );
};
