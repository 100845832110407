import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { AdminDashboards } from 'src/components/AdminDashboards';
import { BottomRightCopyright } from 'src/components/BottomRightCopyright';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral';
import { Copyright } from 'src/components/Copyright';
import { InformationBox } from 'src/components/InformationBox';
import { Loader } from 'src/components/LoadingWrapper';
import { MapSidebar } from 'src/components/MapSidebar';
import { ModalAddUser } from 'src/components/ModalAddUser';
import { ModalCanaryRequest } from 'src/components/ModalCanaryRequest';
import { ModalCollaborationDeploy } from 'src/components/ModalCollaborationDeploy';
import { ModalDeployProcess } from 'src/components/ModalDeployProcess';
import { ModalMapHero } from 'src/components/ModalMapHero';
import { ModalMoveToCollabs } from 'src/components/ModalMoveToCollabs';
import { ModalOperateProcess } from 'src/components/ModalOperateProcess';
import { ModalRunSimCta } from 'src/components/ModalRunSimCta';
import { ModalSearchLocation } from 'src/components/ModalSearchLocation';
import { SimulationProgressModal } from 'src/components/SimulationProgressModal';
import { WorldMap } from 'src/components/WorldMap';
import { EPredefinedModalIds } from 'src/constants/modals';
import { WorldMapContextProvider } from 'src/contexts/WorldMapContext';
import { ModalBuildCommunity } from 'src/features/Communities/NewCommity/ModalBuildCommunity';
import { ModalUploadDataSheet } from 'src/features/Communities/NewCommity/ModalUploadDataSheet';
import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import { useConfigurationEffects } from 'src/hooks/useConfigurationEffects';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useInitialSCMCommunity } from 'src/hooks/useInitialSCMCommunity';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useScreenMode } from 'src/hooks/useScreenMode';
import {
  selectModalMapHeroDismissed,
  selectScreenMode,
} from 'src/redux/application/application.selectors';
import { setOnBoardingStep } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  selectReadOnly,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { TemplateDefault } from 'src/templates/TemplateDefault';
import { EScreenMode } from 'src/typings/configuration.types';
import { v4 } from 'uuid';

import PageEffectsContainer from '../../components/PageEffectsContainer/PageEffectsContainer';
import s from './SingularityMap.module.scss';

export const SingularityMap: React.FC = () => {
  const { updateAppFlow } = useAppFlow();
  const dispatch = useAppDispatch();

  useInitialSCMCommunity();

  useEffect(() => {
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
  }, [dispatch, updateAppFlow]);

  return (
    <WorldMapContextProvider>
      <SingularityMapContent />
    </WorldMapContextProvider>
  );
};

const SingularityMapContent: React.FC = () => {
  const dispatch = useAppDispatch();

  useConfigurationEffects(); // This hook should be used only once per page

  const { graphSimulationProgressShow } = useConfigurationFlowState();
  const readOnly = useSelector(selectReadOnly);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAppLoading = useSelector(selectIsAppLoading);
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const isModalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const simulationStatus = useSelector(selectSimulationStatus);
  const showResultsTab = typeof simulationStatus !== 'undefined';
  const [showResultsTabOnClick, setShowResultsTabOnClick] = useState<boolean>(false);

  const [] = useState<string>(v4());

  useEffect(() => {
    const seenOnboardingModal = localStorage.getItem('seenOnboardingModal');
    if (!seenOnboardingModal) {
      localStorage.setItem('seenOnboardingModal', 'true');
      dispatch(setOnBoardingStep(1));
    }
  }, [dispatch]);

  useScreenMode();

  const screenMode = useSelector(selectScreenMode);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
    }
  }, [dispatch, isLoggedIn]);

  return (
    <PageEffectsContainer>
      <TemplateDefault>
        <AdminDashboards />
        <Switch>
          <Route>
            {isAppLoading && (
              <>
                <Loader />
                <p className={s.loadingText}>loading . . .</p>
              </>
            )}
            <MapSidebar
              showResultsTabOnClick={true}
              hideResultsTab={(status) => {
                setShowResultsTabOnClick(status);
              }}
            />
            {screenMode === EScreenMode.Desktop && <WorldMap className={s.worldMap} />}
            <ModalSearchLocation />
            {!isModalMapHeroDismissed && <ModalMapHero />}

            {!readOnly && <ModalRunSimCta />}

            <ConfigurationManagementCentral>
              {({ handleSettingsDataSave }) => {
                return <ModalCanaryRequest onSave={handleSettingsDataSave} />;
              }}
            </ConfigurationManagementCentral>
            <ModalCollaborationDeploy />
            <ModalUploadDataSheet />
            <ModalBuildCommunity />
            <ModalDeployProcess />
            <ModalAddUser />
            <ModalOperateProcess />
            {
              //<ModalValidateData />
            }
            <ModalMoveToCollabs />
            {screenMode === EScreenMode.Desktop &&
              graphSimulationProgressShow &&
              !isUserACommunityMember && <SimulationProgressModal />}
          </Route>
        </Switch>
        {isUserACommunityMember && screenMode === EScreenMode.Desktop && <Copyright />}
        {!isUserACommunityMember && <BottomRightCopyright />}
        {isUserACommunityMember && showResultsTab && !showResultsTabOnClick && (
          <InformationBox
            onClickAction={() => {
              setShowResultsTabOnClick(true);
            }}
          />
        )}
      </TemplateDefault>
    </PageEffectsContainer>
  );
};
