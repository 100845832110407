import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseInput } from 'src/components/BaseInput';
import { LocationSearch } from 'src/components/LocationSearch';
import { TUserTileFormProps } from 'src/features/Members/SingleMember/SingleMember.types';

import s from './MemberForm.module.scss';

export const MemberForm: React.FC<TUserTileFormProps> = ({
  formErrorAreas,
  values,
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className={classNames(s.fieldContainer)}>
        <BaseInput
          type="text"
          theme="line-dark"
          name="name"
          label={`${t('labels.FULL_NAME')}`}
          value={values.name || ''}
          error={formErrorAreas?.name}
          onChange={onChange}
        />
      </div>
      <div className={classNames(s.fieldContainer)}>
        <BaseInput
          type="text"
          theme="line-dark"
          name="email"
          label="Email"
          value={values.email || ''}
          error={formErrorAreas?.email}
          onChange={onChange}
          disabled={false}
        />
      </div>
      <div className={classNames(s.fieldContainer)}>
        <LocationSearch
          iconLeft={null}
          theme="line-dark"
          name="address"
          label={`${t('labels.ADDRESS')}`}
          error={formErrorAreas?.address || formErrorAreas?.zipCode}
          value={values.address}
          onChange={(data) => {
            onChange({ name: 'address', value: data.place_name });

            const firstContext = data.context[0];
            if (firstContext.id.includes('postcode')) {
              const { text: postcode } = firstContext;
              onChange({ name: 'zipCode', value: postcode || '' });
            }
          }}
          className={s.locationSearch}
          elevateLabel={true}
        />
      </div>
    </div>
  );
};
