import { ConfigType } from 'src/graphql';
import { ScmAdvancedSettingsOutput } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

const FIELD_NAME_TO_LABEL = {
  [ConfigType.Simulation]: {
    marketMakerRate: 'labels.SELLING_RATE',
    feedInTariff: 'labels.BUYING_RATE',
  },
  [ConfigType.Collaboration]: {
    marketMakerRate: 'labels.SELLING_RATE',
    feedInTariff: 'labels.BUYING_RATE',
  },
  [ConfigType.CanaryNetwork]: {
    marketMakerRate: 'labels.CURRENT_SELLING_RATE',
    feedInTariff: 'labels.CURRENT_BUYING_RATE',
  },
};

const FIELD_NAME_TO_TOOLTIP = {
  [ConfigType.Simulation]: {
    marketMakerRate: 'tooltips.SELLING_RATE_INFO',
    feedInTariff: 'tooltips.BUYING_RATE_INFO',
  },
  [ConfigType.Collaboration]: {
    marketMakerRate: 'tooltips.SELLING_RATE_INFO',
    feedInTariff: 'tooltips.BUYING_RATE_INFO',
  },
  [ConfigType.CanaryNetwork]: {
    marketMakerRate: 'tooltips.CURRENT_SELLING_RATE_INFO',
    feedInTariff: 'tooltips.CURRENT_BUYING_RATE_INFO',
  },
};

export type TAreaArgs = {
  configType: ConfigType;
  advancedSettings: ScmAdvancedSettingsOutput;
};

export const Area = ({ configType, advancedSettings }: TAreaArgs): TFieldsTemplateUnion[] => {
  const isDisabled = configType === ConfigType.CanaryNetwork;

  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.HOME_NAME',
      defaultValue: '',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'labels.LOCATION',
      defaultValue: '',
      tooltipText: 'Parent market already has location',
      formView: EFormVariant.Express,
    },
    {
      name: 'marketMakerRate',
      type: 'number',
      label: FIELD_NAME_TO_LABEL[configType].marketMakerRate,
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.Express,
      tooltipText: FIELD_NAME_TO_TOOLTIP[configType].marketMakerRate,
      showTooltip: true,
      disabled: isDisabled,
    },
    {
      name: 'feedInTariff',
      type: 'number',
      label: FIELD_NAME_TO_LABEL[configType].feedInTariff,
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.Express,
      tooltipText: FIELD_NAME_TO_TOOLTIP[configType].feedInTariff,
      showTooltip: true,
      disabled: isDisabled,
    },
    {
      name: 'contractedPowerMonthlyFee',
      type: 'number',
      label: 'labels.CONTRACTED_POWER_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableContractedPowerMonthlyFee,
    },
    {
      name: 'contractedPowerCargoMonthlyFee',
      type: 'number',
      label: 'labels.CONTRACTED_POWER_CARGO_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableContractedPowerCargoMonthlyFee,
    },
    {
      name: 'energyCargoFee',
      type: 'number',
      label: 'labels.ENERGY_CARGO_FEE',
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableEnergyCargoFee,
    },
    {
      name: 'gridImportFeeConst',
      type: 'number',
      label: 'Grid Import fee',
      unit: 'cents / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableGridImportFeeConst,
    },
    {
      name: 'gridExportFee',
      type: 'number',
      label: 'Grid Export fee',
      unit: 'cents / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableGridExportFeeConst,
    },
    {
      name: 'taxesSurcharges',
      type: 'number',
      label: 'labels.ELECTRICITY_TAX',
      unit: '%',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableTaxesSurcharges,
    },
    {
      name: 'electricityTax',
      type: 'number',
      label: 'labels.ELECTRICITY_TAX',
      unit: '%',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableElectricityTax,
    },
    {
      name: 'fixedMonthlyFee',
      type: 'number',
      label: 'bill_components.FIXED_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableFixedMonthlyFee,
    },

    {
      name: 'assistanceMonthlyFee',
      type: 'number',
      label: 'bill_components.ASSISTANCE_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableAssistanceMonthlyFee,
    },
    {
      name: 'serviceMonthlyFee',
      type: 'number',
      label: 'bill_components.SERVICE_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableServiceMonthlyFee,
    },
    {
      name: 'marketplaceMonthlyFee',
      type: 'number',
      label: 'labels.MARKETPLACE_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      disabled: isDisabled,
      exclude: !advancedSettings.enableMarketplaceMonthlyFee,
    },
  ];
};
