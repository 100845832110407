import { prepareBattery } from 'src/features/Assets/prepareBattery';
import { prepareHeatPump } from 'src/features/Assets/prepareHeatPump';
import { prepareLoad } from 'src/features/Assets/prepareLoad';
import { preparePV } from 'src/features/Assets/preparePV';
import { CustomBattery } from 'src/features/Assets/SingleAsset/CustomBattery';
import batteryfields from 'src/features/Assets/SingleAsset/CustomBattery/fields';
import { CustomHeatPump } from 'src/features/Assets/SingleAsset/CustomHeatPump';
import heatpumpfields from 'src/features/Assets/SingleAsset/CustomHeatPump/fields';
import { CustomLoad } from 'src/features/Assets/SingleAsset/CustomLoad';
import loadfields from 'src/features/Assets/SingleAsset/CustomLoad/fields';
import { CustomPV } from 'src/features/Assets/SingleAsset/CustomPV';
import pvfields from 'src/features/Assets/SingleAsset/CustomPV/fields';
import { TAssetType } from 'src/typings/base-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT: Partial<Record<TAssetType, any>> = {
  PV: { fields: pvfields, params: preparePV, component: CustomPV },
  Load: { fields: loadfields, params: prepareLoad, component: CustomLoad },
  ScmStorage: { fields: batteryfields, params: prepareBattery, component: CustomBattery },
  ScmHeatPump: { fields: heatpumpfields, params: prepareHeatPump, component: CustomHeatPump },
};
