import React, { useCallback, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { MemberForm } from 'src/features/Members/SingleMember/MemberForm/MemberForm';
import { TFormErrorAreas } from 'src/features/Members/SingleMember/SingleMember.types';
import { ScmCommunityMember, useUpdateCommunityMemberMutation } from 'src/graphql';
import { useReadConfigurationQuery } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { selectSelectedMember } from 'src/redux/application/application.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { areObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';

import s from './EditMember.module.scss';
import { TEditMemberProps } from './EditMember.types';

const EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EditMember: React.FC<TEditMemberProps> = ({ onClickClose }) => {
  const { t } = useTranslation();

  const configUUid = useSelector(selectActiveConfigurationUuid);
  const selectedMember = useSelector(selectSelectedMember);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUUid,
    variables: { uuid: configUUid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const member: ScmCommunityMember | undefined = useMemo(
    () =>
      (configuration?.scenarioData?.homeInfo?.scmHomeDetails?.find(
        (member) => member?.uuid === selectedMember,
      ) ?? undefined) as ScmCommunityMember | undefined,
    [configuration, selectedMember],
  );
  const [updateCommunityMemberMutation] = useUpdateCommunityMemberMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUUid } }],
    onCompleted: () => {
      dispatchSuccessToast();
      setOriginalMember(newMember);
      onClickClose();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newMember, setNewMember] = useState<any>(member);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [originalMember, setOriginalMember] = useState<any>(member);
  const [formErrorAreas, setFormErrorAreas] = useState<TFormErrorAreas>({});

  const onChange = useCallback(
    ({ name, value }) => {
      setNewMember({ ...newMember, [name]: value });
    },
    [newMember],
  );

  const onSubmit = useCallback(() => {
    if (!configUUid) return;

    const formKeys = ['name', 'email', 'address'];

    const isFormValid = formKeys.every((key) => newMember[key]);

    if (!isFormValid) {
      // find the first empty field and focus on it
      const emptyFields = formKeys.filter((key) => !newMember[key]);
      if (emptyFields.length > 0) {
        const errorAreas: TFormErrorAreas = {};
        emptyFields.forEach((field) => {
          errorAreas[field] = 'This field is required';
        });
        setFormErrorAreas(errorAreas);
      }
      return;
    }

    if (selectedMember && configUUid) {
      updateCommunityMemberMutation({
        variables: {
          name: newMember.name,
          email: newMember.email,
          zipcode: newMember.zipCode,
          address: newMember.address,
          memberUuid: selectedMember,
          configUuid: configUUid,
        },
      });
    }
  }, [newMember, updateCommunityMemberMutation, configUUid, selectedMember]);

  const canSave =
    newMember.name &&
    newMember.email &&
    newMember.address &&
    EMAIL_VALIDATION.test(newMember.email) &&
    !areObjectsEqualByFields(newMember, originalMember);

  return (
    <div className={s.editMember}>
      <BaseButtonSquare
        onClick={onClickClose}
        className={s.button}
        icon="close"
        size="2"
        svgSize="3"
      />
      <MemberForm formErrorAreas={formErrorAreas} values={newMember} onChange={onChange} />
      <BaseButton type="button" onClick={onSubmit} className={s.formButton} disabled={!canSave}>
        {t('commands.SAVE')}
      </BaseButton>
    </div>
  );
};
