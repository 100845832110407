import React from 'react';

import classnames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';

import { InfoHelper } from '../InfoHelper';
import s from './AssetBlock.module.scss';
import { TAssetBlockProps } from './AssetBlock.types';

export const AssetBlock: React.FC<TAssetBlockProps> = ({
  className,
  icon,
  title,
  subtitle,
  description,
  theme = 'dark',
  aspectRatio = 107,
  onClick,
  onThreeDotsClick,
  topLeftComponent,
  bottomRightComponent,
  chartAssetClass,
}: TAssetBlockProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MainComponent: any = onClick ? 'button' : 'div';

  return (
    <div className={classnames(className, s.container, s[`theme-${theme}`])}>
      {topLeftComponent && <div className={s.topLeftComponent}>{topLeftComponent}</div>}

      <div className={s.blockOuter} style={{ paddingTop: aspectRatio + '%' }}>
        <MainComponent
          className={classnames(s.block, chartAssetClass)}
          type="button"
          onClick={onClick}>
          <BaseIcon className={s.mainIcon} icon={icon} />

          <div className={s.detailsSize}>
            <h3 className={s.title}>{title}</h3>
            <p className={s.subtitle}>{subtitle}</p>
          </div>
        </MainComponent>

        {onThreeDotsClick && (
          <button onClick={onThreeDotsClick} type="button" className={s.threeDots}>
            <BaseIcon icon="more-horizontal" size={10} />
          </button>
        )}

        {description && <InfoHelper className={s.infoButton} size={8} info={description} />}
        {bottomRightComponent && <div className={s.bottomRight}>{bottomRightComponent}</div>}
      </div>
      {!onClick && (
        <div className={s.overlay}>
          <div className={s.comingSoonTag}>Coming Soon</div>
        </div>
      )}
    </div>
  );
};
