import { Group } from 'src/three';

export type TLayerEntityInputProps = {
  group: Group;
  origin?: number[];
  positive?: boolean | null;
  scale?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalProps?: any | null;
  layerType?: number;
};

export type TUpdateLayerScaleInputProps = {
  group: Group;
  initialScale: number;
  maxScale?: number;
  minScale?: number;
  x?: boolean;
  y?: boolean;
  z?: boolean;
};

export enum EAssetType {
  AREA = 'Area',
  PV = 'PV',
  LOAD = 'Load',
  STORAGE = 'Storage',
  HEATPUMP = 'HeatPump',
}
