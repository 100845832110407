import { RouteProps } from 'react-router-dom';
import { AccountActivated } from 'src/pages/AccountActivated';
import { DesktopLogin } from 'src/pages/DesktopLogin';
import { Embed } from 'src/pages/Embed';
import { Login } from 'src/pages/Login';
import { PageNotFound } from 'src/pages/PageNotFound';
import { ResetPassword } from 'src/pages/ResetPassword';
import { ScmInvitation } from 'src/pages/ScmInvitation';
import { SingularityMap } from 'src/pages/SingularityMap';

const getBaseUrl = () => {
  if (window.location.href.includes('scm.gridsingularity')) {
    return 'https://gridsingularity.com/';
  }
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:3000/';
  } else {
    return 'https://phoenix.ui-dev.gridsingularity.com/';
  }
};

const BASE_URL = getBaseUrl();

export const routesConfig = {
  home: (): string => BASE_URL,
  singularityMap: (): string => `${BASE_URL}singularity-map`,
  scm: (): string => `${BASE_URL}scm`,
  company: (): string => `${BASE_URL}company/our-approach`,
  resources: (): string => `${BASE_URL}resources/trending`,
  scmMap: (): string => '/scm-map',
  scmMapResults: (configurationUuid?: string, assetUuid?: string): string =>
    `/scm-map/results/${configurationUuid || ':configurationUuid'}${
      typeof assetUuid === 'string' ? `/${assetUuid}` : '/:assetUuid'
    }`,
  scmMapCreate: (): string => '/scm-map/create',
  scmSingularityMapResults: (configurationUuid?: string): string =>
    `/scm/singularity-map/results/${configurationUuid || ':configurationUuid'}`,
  singularityMapResults: (configurationUuid?: string, assetUuid?: string): string =>
    `/singularity-map/results/${configurationUuid || ':configurationUuid'}${
      typeof assetUuid === 'string' ? `/${assetUuid}` : '/:assetUuid'
    }`,

  embed: (configurationUuid?: string): string =>
    `/embed/${configurationUuid || ':configurationUuid'}`,
  confirmEmail: (): string => '/confirm-email/:token',
  accountActivated: (): string => '/account_activated',
  resetPassword: (): string => '/reset/:resetId/:resetKey',
  notFound: (): string => '/404',
  login: (): string => '/login',
};

export const routesTitles: {
  [route in keyof typeof routesConfig]: string;
} = {
  home: 'Home',
  company: 'Company',
  resources: 'Resources',
  singularityMap: 'Singularity Map',
  scm: 'SCM',
  scmMap: 'SCM Map',
  scmMapCreate: 'SCM Map Create',
  singularityMapResults: 'Singularity Map',
  scmMapResults: 'SCM Map Results',
  scmSingularityMapResults: 'SCM invitation',
  notFound: 'Page Not Found',
  confirmEmail: 'Confirm Email',
  accountActivated: 'Account Activated',
  resetPassword: 'Reset Password',
  login: 'Login',
  embed: 'Singularity Map',
};

export type TRouteNames = keyof typeof routesConfig;

export type TAppRouteProps = {
  private?: boolean;
  routeName: TRouteNames;
} & RouteProps;

export const routes: TAppRouteProps[] = [
  {
    routeName: 'home',
    path: routesConfig.home(),
    exact: true,
  },
  {
    routeName: 'company',
    path: routesConfig.company(),
    exact: true,
  },
  {
    routeName: 'resources',
    path: routesConfig.resources(),
    exact: true,
  },
  {
    routeName: 'singularityMap',
    path: routesConfig.singularityMap(),
    exact: true,
  },
  {
    routeName: 'scmSingularityMapResults',
    path: routesConfig.scmSingularityMapResults(),
    component: ScmInvitation,
    exact: true,
  },
  {
    routeName: 'scmMap',
    path: [routesConfig.scmMap()],
    component: SingularityMap,
  },
  {
    routeName: 'resetPassword',
    path: routesConfig.resetPassword(),
    component: ResetPassword,
    exact: true,
  },
  {
    routeName: 'confirmEmail',
    path: routesConfig.confirmEmail(),
    component: ({ match }: { match: { params: { token: string } } }): null => {
      const endpoint = 'rest-accounts/registration/account-confirm-email/';
      window.location.href = process.env.REACT_APP_D3A_WEB_API_URL + endpoint + match.params.token;
      return null;
    },
    exact: true,
  },
  {
    routeName: 'accountActivated',
    path: routesConfig.accountActivated(),
    component: AccountActivated,
    exact: true,
  },

  {
    routeName: 'embed',
    path: routesConfig.embed(),
    component: Embed,
    exact: true,
  },
  {
    routeName: 'scm',
    path: routesConfig.scm(),
    exact: true,
  },
  {
    routeName: 'login',
    path: routesConfig.login(),
    component: DesktopLogin,
    exact: true,
  },
  {
    routeName: 'notFound',
    path: '*',
    component: PageNotFound,
  },
];

export const mobileRoutes: TAppRouteProps[] = [
  {
    routeName: 'home',
    path: routesConfig.home(),
    exact: true,
  },
  {
    routeName: 'singularityMap',
    path: routesConfig.singularityMap(),
    exact: true,
  },
  {
    routeName: 'scm',
    path: routesConfig.scm(),
    exact: true,
  },
  {
    routeName: 'company',
    path: routesConfig.company(),
    exact: true,
  },
  {
    routeName: 'resources',
    path: routesConfig.resources(),
    exact: true,
  },
  {
    routeName: 'scmSingularityMapResults',
    path: routesConfig.scmSingularityMapResults(),
    component: ScmInvitation,
    exact: true,
  },
  {
    routeName: 'scmMap',
    path: routesConfig.scmMap(),
    component: SingularityMap,
  },
  {
    routeName: 'login',
    path: routesConfig.login(),
    component: Login,
  },

  {
    routeName: 'resetPassword',
    path: routesConfig.resetPassword(),
    component: ResetPassword,
    exact: true,
  },
  {
    routeName: 'notFound',
    path: '*',
    component: PageNotFound,
  },
];
