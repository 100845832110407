import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { ContextMenu } from 'src/components/ContextMenu';
import { ListCommunityAssetsTile } from 'src/features/Assets/ListAssets/ListCommunityAssets/ListCommunityAssetsTile';
import { useReadConfigurationQuery } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectIsOperationalCommunity,
  selectMeasurementRequests,
} from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { objectCamelCase } from 'src/utils/objectCamelCase';

import s from './ListCommunityAssets.module.scss';
import { TListCommunityAssetsProps } from './ListCommunityAssets.types';

export const ListCommunityAssets: React.FC<TListCommunityAssetsProps> = ({
  usersData,
  selectedItems,
  searchValue,
  onItemClick,
  onAssetRemove,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id: modalId } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const measurementRequests = useSelector(selectMeasurementRequests);

  const [openedAssetConfigUuid, setOpenedAssetConfigUuid] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !activeConfigurationUuid,
    variables: { uuid: activeConfigurationUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const communityArea = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    const community = scenario?.children.find((child) => child.type === 'Area');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectCamelCase<any>(community);
  }, [configuration]);
  const communityAssetAreas = useMemo(() => {
    const areas =
      communityArea?.children?.map((
        area, // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => objectCamelCase<any>(area)) || [];
    return areas;
  }, [communityArea]);

  const selectboxUsersList = useMemo(
    () =>
      usersData
        .filter((user) => !user.isAggregator && !user.isGridOperator)
        .map(({ name, uuid }) => ({ name, uuid })),
    [usersData],
  );

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (isOwnerAdmin || isOwnerCM)) {
    dropDownItems.push({
      title: t('commands.REMOVE'),
      icon: 'custom-trash',
      onClick: () => {
        if (configUuid && openedAssetConfigUuid)
          onAssetRemove({ assetUuid: openedAssetConfigUuid });
        dispatch(closeModal(modalId));
      },
    });
  }

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const onExpandClick = (uuid: string) => {
    setExpandedItem(uuid);
  };

  return (
    <div className={s.assetsList}>
      <React.Fragment key={0}>
        <ListCommunityAssetsTile
          asset={communityArea}
          childrenCount={communityArea.children.length}
          usersList={selectboxUsersList}
          selected={false}
          onClick={undefined}
          onMenuClick={undefined}
          onExpandClick={undefined}
          measurementStatus={null}
        />
      </React.Fragment>
      {communityAssetAreas
        .filter((area) =>
          !!searchValue && searchValue.length > 0 ? area.name.includes(searchValue) : true,
        )
        .map((area) => {
          const isSelected = selectedItems.includes(area.uuid);
          const isExpanded = expandedItem === area.uuid;

          const parentMeasurementStatus =
            measurementRequests.find((item) => item.areaUuid === area.uuid)?.status || null;

          return (
            <React.Fragment key={area.uuid}>
              <ListCommunityAssetsTile
                asset={area}
                childrenCount={area?.children?.length || 0}
                usersList={selectboxUsersList}
                selected={isSelected}
                onClick={onItemClick}
                onMenuClick={
                  dropDownItems.length > 0 && !isOperationalCommunity ? onMenuClick : undefined
                }
                onExpandClick={isOperationalCommunity ? onExpandClick : undefined}
                measurementStatus={parentMeasurementStatus}
              />
              {isExpanded &&
                isOperationalCommunity &&
                area.children.map((asset) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  asset = objectCamelCase<any>(asset);
                  const childMeasurementStatus =
                    measurementRequests.find((item) => item.areaUuid === asset.uuid)?.status ||
                    null;

                  const selected = selectedItems.includes(asset.uuid);
                  return (
                    <ListCommunityAssetsTile
                      asset={asset}
                      key={asset.uuid}
                      isChildren
                      usersList={selectboxUsersList}
                      selected={selected}
                      onClick={onItemClick}
                      measurementStatus={childMeasurementStatus}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
      {errorMessage && (
        <div
          className={s.customToast}
          style={{
            top: menuModalTriggerRef.current?.getBoundingClientRect().top
              ? menuModalTriggerRef.current?.getBoundingClientRect().top - 45
              : 0,
          }}>
          {errorMessage} <BaseClose className={s.closeBtn} onClick={() => setErrorMessage(null)} />
        </div>
      )}
    </div>
  );
};
