import React from 'react';

import classnames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseOverflowMenu } from 'src/components/BaseOverflowMenu';

import s from './ContextMenu.module.scss';
import { TContextMenuProps } from './ContextMenu.types';

const DEFAULT_POSITION = {
  side: 'right' as const,
  anchorPosition: 50,
};

export const ContextMenu: React.FC<TContextMenuProps> = ({
  relativeElement,
  modalId,
  position = DEFAULT_POSITION,
  items,
}) => {
  return (
    <BaseOverflowMenu
      relativeElement={relativeElement}
      modalId={modalId}
      position={position}
      size="auto">
      {items.map((item, index) => (
        <button
          type="button"
          className={classnames(s.menuItem, {
            'loading-shimmer': item.loading,
          })}
          key={index}
          onClick={item.onClick}
          disabled={item.disabled}>
          <BaseIcon className={s.icon} icon={item.icon} size={15} />
          {item.title}
        </button>
      ))}
    </BaseOverflowMenu>
  );
};
