import { optionalFile, optional } from 'src/features/Assets/utils';
import { AreaInput } from 'src/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function preparePV({ values }): AreaInput {
  const output: AreaInput = {
    type: values.type,
    uuid: values.uuid,
    name: values.name,
    ...optional<AreaInput>('geoTagLocation', values),
    ...optional<AreaInput>('capacityKw', values),
    ...optionalFile('powerProfile', values.powerProfile),
    powerProfileUuid: values.powerProfileUuid,
    azimuth: values.azimuth,
    tilt: values.tilt,
  };
  return output;
}
