import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { OperationDefinitionNode } from 'graphql';
import { batch } from 'react-redux';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { TAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { ELsKey, ls } from 'src/utils/localStorage';

function createClient(dispatch: TAppDispatch) {
  const endpoint = 'graphql/'; // That slash at the end is very important
  const uri = `${process.env.REACT_APP_D3A_WEB_API_URL}${endpoint}`;

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listCommunityManagers: {
            keyArgs: ['configUuid'],
            merge(_, incoming) {
              return incoming;
            },
          },
          readConfiguration: {
            keyArgs: ['uuid'],
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  const authLink = new ApolloLink((operation, forward) => {
    // Add authorization header if not logging in
    if (operation && operation.operationName !== 'tokenAuth') {
      const token = ls.get(ELsKey.TOKEN_AUTH)?.token;

      if (token) {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            Authorization: `JWT ${token}`,
          },
        }));
      }
    }

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.error(`[GraphQL error]: Message: ${error.message}`);
      });

      if (process.env.NODE_ENV !== 'production' && !!graphQLErrors[0]?.message) {
        dispatch(
          openToast({
            message: graphQLErrors[0]?.message,
            type: 'error',
          }),
        );
      }
    }

    if (networkError) {
      const { statusCode } = networkError as ServerError;

      if (statusCode && statusCode.toString() === '401') {
        batch(() => {
          dispatch(setTokenAuth(null));
        });
      } else {
        console.error('[Network Error]: ', networkError);
        dispatch(
          openToast({
            message: `Failed to fetch. Please try again later`,
            type: 'error',
          }),
        );
      }
    }
  });

  const uploadLink = createUploadLink({
    uri,
  });

  // @ts-ignore
  const httpLink = ApolloLink.from([errorLink, authLink, uploadLink]);

  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_D3A_WEBSOCKET_URL}`,
    options: {
      reconnect: true,
      lazy: true,
      inactivityTimeout: 30000,
      minTimeout: 4000,
      timeout: 60000,
      connectionParams: () => ({
        authToken: ls.get(ELsKey.TOKEN_AUTH)?.token,
      }),
    },
  });

  const link = ApolloLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    uri,
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
}

export const client = (dispatch: TAppDispatch): ApolloClient<NormalizedCacheObject> =>
  createClient(dispatch);
