import 'react-tooltip/dist/react-tooltip.css';

import React from 'react';

import { useTranslation } from 'react-i18next';
import s from 'src/features/Communities/ListCommunities/ListCommunities.module.scss';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import { convertLngLat } from 'src/utils/worldMap/helpers';

import { CommunityListItem } from './CommunityListItem';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ListItem = ({ index, style, data }) => {
  const { t } = useTranslation();
  const item = data.items[index];
  const location = { lat: item.latitude, lng: item.longitude };

  if (!location) return null;

  const locationCoords = convertLngLat(location);

  return (
    <div className={s.listItem} style={style}>
      <CommunityListItem
        item={item}
        className={s.listItem}
        description={
          item.timestamp
            ? `${t('messages.UPDATED_ON')} ${UTCMoment.fromBackend(item.timestamp).format(
                FRONTEND_DATE_FORMATS.COMMUNITY_DATE,
              )}`
            : ''
        }
        locationCoords={locationCoords}
        onMenuClick={(menuTriggerRef, { communityUuid }) =>
          data.onMenuClick(menuTriggerRef, {
            projectID: item.project?.uuid,
            communityUuid,
            configurationUuid: item.uuid,
            uuid: item.uuid,
          })
        }
      />
    </div>
  );
};
