import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { TModalAssetsManagerProps } from 'src/components/ModalAssetsManager';
import { fieldTemplates } from 'src/features/Communities/EditCommunity/BasicSettingsForm/formFields';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { EFormVariant } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';
import { TCommunitySettingsFields, TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldValuesByName,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';
import { $Keys } from 'utility-types';

export type TFieldsArray = Array<$Keys<TFieldsVisibility>>;

export type TGetVisibleFieldsArgs = {
  fields: ReturnType<typeof fieldTemplates>;
  fieldsVisibility: TFieldsVisibility;
  formVariant: TFormSettingsDataProps['formVariant'];
  isAdmin: boolean;
  visibleFieldsInfoFromProp?: TFieldsVisibility;
  visibleFieldsOrderFromProp?: TFieldsArray;
};

export type TFieldsVisibility = {
  [key in
    | keyof TCommunitySettingsFields
    | 'gridFeeEnabled'
    | 'coefficientPercentage'
    | 'gridFeeConstant']?: boolean;
};

export type TSettingsSaveProps = {
  settingsData: TSettingsData;
  communityAssetSettings: Pick<
    NonNullable<TValuesByFieldName>,
    'gridFeeConstant' | 'useMarketMakerRate' | 'feedInTariff'
  >;
} & Pick<TConfigurationState, 'name' | 'description' | 'locationVisible' | 'timezone'>;

export type TRelatedFormFields = Array<{
  fieldName: keyof TValuesByFieldName;
  relatedFields: Array<keyof TValuesByFieldName>;
}>;

export type TFormFieldsArgs = {
  values: Omit<TCommunitySettingsFields, 'startEndDate'> & Partial<TFieldValuesByName<'Area'>>;
  configurationCharacteristic: TConfigurationCharacteristic;
};

export type TFormSettingsDataProps = {
  className?: string;
  hasErrorsRef?: React.MutableRefObject<boolean>;
  formVariant?: EFormVariant;
  theme?: TFormFieldsGeneratorProps['theme'];
  onSubmit(payload: TSettingsSaveProps): void;
  visibleFieldsInfo?: TFieldsVisibility;
  visibleFieldsOrder?: TFieldsArray;
} & Omit<
  TFormFieldsGeneratorProps,
  'fields' | 'onChange' | 'errors' | 'onSubmit' | 'focusField' | 'readOnly'
>;

export type TMapSidebarSingleCommunityProps = {
  className?: string;
  showResultsTabOnClick?: boolean;
  hideResultsTab?: (arg: boolean) => void;
  style?: React.CSSProperties;
  showHeaderTabs?: boolean;
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
  onAddNewAsset?: NonNullable<TModalAssetsManagerProps['onAddNewAsset']>;
  onAddNewAssetUnderUuid?: NonNullable<TModalAssetsManagerProps['onAddAssetUnderUuid']>;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  onChangeSettingsTab?: TFormAssetsParamsProps['onChangeSettingsTab'];
  onAssetRemove?(payload: { assetUuid: TAsset['uuid'] }): void;
  onCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
};

export enum ECollaborationTab {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Grid = 'Grid',
}

export enum ECommunityTab {
  GridMarket = 'GRID_MARKET',
  GridAgent = 'GRID_AGENT',
}

export enum EResultsTab {
  Home = 'Home',
  Community = 'Community',
}

export type TPresentationNames =
  | 'KeyResults'
  | 'KeyResultsSCM'
  | 'AssetCount'
  | 'SS-SC'
  | 'ChartEnergyTradeProfile'
  | 'ChartDayProfile'
  | 'ChartPrices'
  | 'TradesBidsAndOffers'
  | 'TableEnergyBillsAndNetTraded'
  | 'AssetHighlight'
  | 'ChartSavings'
  | 'ChartAsset'
  | 'ChartAssetAgent'
  | 'ChartDeviceProfile'
  | 'EnergyFlow';

export enum EActiveTabState {
  Community = 'community',
  Settings = 'settings',
  Registry = 'registry',
  CollaborationSettings = 'collaborationSettings',
  Profile = 'profile',
  GridMarketResults = 'gridmarketresults',
  GridMarketSettings = 'gridmarketsettings',
  Results = 'results',
  MessagingPortal = 'messagingPortal',
}

export type TKeysCollaborationTab = keyof typeof ECollaborationTab;

export type TCollaborationFields = Record<
  TKeysCollaborationTab,
  Pick<TFormSettingsDataProps, 'visibleFieldsInfo' | 'visibleFieldsOrder'>
>;

export type TCollaborationFieldsDefault = Record<
  Extract<TKeysCollaborationTab, 'Basic' | 'Advanced'>,
  Pick<TFormSettingsDataProps, 'visibleFieldsInfo' | 'visibleFieldsOrder'>
>;

export type TMapSidebarSingleCommunityTabsProps = {
  activeTab: EActiveTabState;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleTabChange: Function;
  showConnectCommunityTooltip: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleResultsTabClick: Function;
};
