import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import {
  TRegistryUserTileProps,
  TRegistryUsersListProps,
} from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList';
import { RegistryUserTile } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList/RegistryUserTile';
import { InviteStatusOutputEnum } from 'src/graphql';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';
import { EUserRoles } from 'src/typings/base-types';

import s from './RegistryList.module.scss';

export const RegistryUsersList: React.FC<TRegistryUsersListProps> = ({
  assetsData,
  usersData,
  selectedItems,
  onItemClick,
  getParentUuid,
  searchValue,
}) => {
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const communityAsset = useSelector(selectCommunityAsset);
  const assetsUnderSelectedUuid = useSelector(selectAssetsUnderUuid(communityAsset?.uuid));
  const assetValues = useSelector(selectAssetsValues);

  const list = useMemo<TRegistryUserTileProps['data'][]>(() => {
    return usersData.reduce<TRegistryUserTileProps['data'][]>((acc, user) => {
      const isSearched =
        searchValue.length > 0
          ? user.subtitle?.toLowerCase().includes(searchValue.toLowerCase())
          : true;

      if (!isSearched) return acc;

      const assignedAsset = assetsData.find((item) => item.uuid === user.uuid);

      let data = {};
      if (assignedAsset) {
        const parentUuid = getParentUuid(assignedAsset.uuid);

        // find children assets in assetsData
        const children = assetsData.filter(
          (asset) => getParentUuid(asset.uuid) === assignedAsset.uuid,
        );

        data = {
          uuid: assignedAsset.uuid,
          name: assignedAsset.name,
          type: assignedAsset.type,
          icon: 'house' as TIconNames,
          owners: [],
          children,
          status: 'Applied', //(appliedUser ? 'Applied' : usersToAssetRelations[user.name]) as TCanaryAssetState,
          appliedUserName: undefined,
          appliedUserAvatar: undefined,
          parentUuid: assignedAsset.type !== 'Area' && parentUuid ? parentUuid : null,
        };
      }
      acc.push({
        uuid: user.uuid,
        name: user.name,
        assignedAssets: data,
        joined: ![InviteStatusOutputEnum.Blocked, InviteStatusOutputEnum.Invited].includes(
          user.canaryInvitations.find((item) => item.configUuid === configUuid)
            ?.status as InviteStatusOutputEnum,
        ),
        avatarUrl: user.avatarUrl,
        subtitle: user?.subtitle,
        isNewUser: user?.isNewUser,
      });
      return acc;
    }, []);
  }, [assetsData, configUuid, getParentUuid, usersData, searchValue]);

  const getHomesList = useCallback(
    () =>
      assetsUnderSelectedUuid.reduce<TRegistryUserTileProps['homesList']>((acc, item) => {
        const name = assetValues[item.uuid].name as string;
        acc.push({
          uuid: item.uuid,
          name,
        });
        return acc;
      }, []),
    [assetValues, assetsUnderSelectedUuid],
  );

  return (
    <div className={s.usersList}>
      {list.map((item) => {
        const isSelected = userRole === EUserRoles.DSO ? true : selectedItems.includes(item.name);
        const homesList = getHomesList();
        return (
          <React.Fragment key={item.name}>
            <RegistryUserTile
              data={item}
              selected={isSelected}
              onClick={onItemClick}
              homesList={homesList}
              withoutSelectedUIEffect={userRole === EUserRoles.DSO}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};
