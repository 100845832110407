import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { TAreaSummaryResult } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import {
  selectAssets,
  selectAssetsTreeRelations,
  selectAssetsValues,
  selectRootAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TAssetsTreeRelations } from 'src/typings/configuration.types';

export type TCreateAreaSummaryArgs = {
  assets: TConfigurationState['assets'];
  assetsTreeRelations: TAssetsTreeRelations;
  assetsValues: TConfigurationState['assetsValues'];
  rootAssetUuid: TConfigurationState['rootAssetUuid'];
};

export type TUseAreaSummaryResponse = {
  createAreaSummary: (payload: TCreateAreaSummaryArgs) => TAreaSummaryResult | undefined;
  areaSummary: TAreaSummaryResult | undefined;
};

export function useAreaSummary(): TUseAreaSummaryResponse {
  const assets = useSelector(selectAssets);
  const assetsValues = useSelector(selectAssetsValues);
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
  const rootAssetUuid = useSelector(selectRootAssetUuid);

  const createAreaSummary: (payload: TCreateAreaSummaryArgs) => TAreaSummaryResult | undefined = ({
    assets,
    assetsTreeRelations,
    assetsValues,
    rootAssetUuid,
  }) => {
    const createSummaryObject = (
      uuid: string,
      children?: TAreaSummaryResult[],
    ): TAreaSummaryResult => ({
      name: assetsValues[uuid].name || '',
      type: assets[uuid].type,
      uuid: uuid,
      children,
    });

    if (rootAssetUuid) {
      const createTree = (items) => {
        return items.map((uuid) => {
          const obj = createSummaryObject(uuid);
          if (assetsTreeRelations[uuid].length > 0) {
            obj.children = createTree(assetsTreeRelations[uuid]);
          }
          return obj;
        });
      };

      return createSummaryObject(rootAssetUuid, createTree(assetsTreeRelations[rootAssetUuid]));
    }
    return undefined;
  };

  const areaSummary = useMemo<TAreaSummaryResult | undefined>(() => {
    //console.log('useAreaSummary', { assets, assetsTreeRelations, assetsValues, rootAssetUuid });
    return createAreaSummary({ assets, assetsTreeRelations, assetsValues, rootAssetUuid });
  }, [assets, assetsTreeRelations, assetsValues, rootAssetUuid]);

  return {
    createAreaSummary,
    areaSummary,
  };
}
