import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TScmTableBill } from 'src/components/Charts/TableBills/TableBills.types';
import { TCreateMonthObjectResult } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/MonthlyResultsIndicatorSCM/MonthlyResultsIndicatorSCM.helpers';
import {
  EActiveTabState,
  EResultsTab,
} from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/MapSidebarSingleCommunity.types';
import { ETabs } from 'src/components/Tabs';
import {
  TBillsDifference,
  TSCMHomeKpi,
  TSCMSimulationGraphData,
} from 'src/features/Results/SingleAreaResults';
import { TSCMCommunityKpi } from 'src/features/Results/SingleCommunityResults';
import { DataAggregationResolution, MeasurementVerificationRequest } from 'src/graphql';
import { APP_FLOW } from 'src/hooks/useAppFlow';
import { ELsKey, ls } from 'src/utils/localStorage';

export type TSCMHome = {
  address: string;
  assetCount: number;
  coefficientPercentage: number;
  email: string;
  feedInTariff: number;
  fixedMonthlyFee: number;
  gridFeeConstant: number;
  marketMakerRate: number;
  marketplaceMonthlyFee: number;
  assistanceMonthlyFee: number;
  serviceMonthlyFee: number;
  contractedPowerMonthlyFee: number;
  contractedPowerCargoMonthlyFee: number;
  energyCargoFee: number;
  name: string;
  taxesSurcharges: number;
  electricityTax: number;
  uuid: string;
  zipCode: string;
  geoTagLocation: [number, number];
};

export type TMembers = {
  id?: string;
  name: string;
  email: string;
  address: string;
};

export type TMeasurementRequest = Pick<
  MeasurementVerificationRequest,
  'areaUuid' | 'configUuid' | 'status'
>;

export type TSCMState = {
  dataSheetResponse: unknown;
  activeSCMStep: number;
  isSCMFlow: boolean;
  isAppLoading: boolean;
  isOperationalCommunity: boolean;
  scmCommunityManager: string | undefined;
  scmSidebarTab: EActiveTabState | undefined;
  scmHomeDetails: TSCMHome[];
  homeBillsDifferences: TBillsDifference | undefined;
  communityBillsDifferences: TBillsDifference | undefined;
  homeKpi: TSCMSimulationGraphData | undefined;
  communityKpi: TSCMSimulationGraphData | undefined;
  kpiDifference: TSCMHomeKpi | undefined;
  communityKpiDifference: TSCMCommunityKpi | undefined;
  scmBillTableData: Array<TScmTableBill>;
  dataResolution: DataAggregationResolution.Daily | DataAggregationResolution.Monthly;
  activeTabResults: EResultsTab;
  activeCommunityListTab: ETabs;
  newOperationalCommunityUuid: string | undefined;
  measurementRequests: Array<TMeasurementRequest>;
  activeLiveAccumulatedSubscriptionId: string | undefined;
  preventPushResultsScreen: boolean;
  selectedMonthlyIndicator: TCreateMonthObjectResult | undefined;
  selectedDailyIndicator: number | undefined;
  hideSidebarHeader: boolean;
  unreadMessageCount: number | null | undefined;
  userListItemClicked: boolean;
  disableScmTabChanges: boolean;
};

const scmFlow = ls.get(ELsKey.APP_FLOW) === APP_FLOW.SCM;

export const initialSCMState: TSCMState = {
  dataSheetResponse: null,
  activeSCMStep: 0,
  isSCMFlow: scmFlow,
  isAppLoading: false,
  isOperationalCommunity: false,
  scmCommunityManager: 'contact@gridsingularity.com',
  scmSidebarTab: undefined,
  scmHomeDetails: [],
  homeBillsDifferences: undefined,
  communityBillsDifferences: undefined,
  homeKpi: undefined,
  communityKpi: undefined,
  kpiDifference: undefined,
  communityKpiDifference: undefined,
  scmBillTableData: [],
  dataResolution: DataAggregationResolution.Daily,
  activeTabResults: EResultsTab.Home,
  activeCommunityListTab: ETabs.SIMULATIONS,
  newOperationalCommunityUuid: undefined,
  measurementRequests: [],
  activeLiveAccumulatedSubscriptionId: undefined,
  preventPushResultsScreen: false,
  selectedMonthlyIndicator: undefined,
  selectedDailyIndicator: undefined,
  hideSidebarHeader: false,
  unreadMessageCount: 0,
  userListItemClicked: false,
  disableScmTabChanges: false,
};

const scmSlice = createSlice({
  name: 'scm',
  initialState: initialSCMState,
  reducers: {
    setDataSheetResponse(state, action: PayloadAction<TSCMState['dataSheetResponse']>) {
      state.dataSheetResponse = action.payload;
    },
    setActiveSCMStep(state, action: PayloadAction<TSCMState['activeSCMStep']>) {
      state.activeSCMStep = action.payload;
      ls.set(ELsKey.SCM_STEP, String(action.payload));
    },
    setSCMFlow(state, action: PayloadAction<TSCMState['isSCMFlow']>) {
      state.isSCMFlow = action.payload;
    },
    setIsAppLoading(state, action: PayloadAction<TSCMState['isAppLoading']>) {
      state.isAppLoading = action.payload;
    },
    setSCMCommunityManager(state, action: PayloadAction<TSCMState['scmCommunityManager']>) {
      state.scmCommunityManager = action.payload;
    },
    setSCMsidebarTab(state, action: PayloadAction<TSCMState['scmSidebarTab']>) {
      state.scmSidebarTab = action.payload;
    },
    setCommunityMembers(state, action: PayloadAction<TSCMState['scmHomeDetails']>) {
      state.scmHomeDetails = action.payload;
    },
    setHomeBillsDifferences(state, action: PayloadAction<TSCMState['homeBillsDifferences']>) {
      state.homeBillsDifferences = action.payload;
    },
    setHomeKpi(state, action: PayloadAction<TSCMState['homeKpi']>) {
      state.homeKpi = action.payload;
    },
    setCommunityKpi(state, action: PayloadAction<TSCMState['communityKpi']>) {
      state.communityKpi = action.payload;
    },
    setKpiDifference(state, action: PayloadAction<TSCMState['kpiDifference']>) {
      state.kpiDifference = action.payload;
    },
    setDataResolution(state, action: PayloadAction<TSCMState['dataResolution']>) {
      state.dataResolution = action.payload;
    },
    setCommunityBillsDifferences(
      state,
      action: PayloadAction<TSCMState['communityBillsDifferences']>,
    ) {
      state.communityBillsDifferences = action.payload;
    },
    setCommunityKpiDifference(state, action: PayloadAction<TSCMState['communityKpiDifference']>) {
      state.communityKpiDifference = action.payload;
    },
    setActiveTabResults(state, action: PayloadAction<TSCMState['activeTabResults']>) {
      state.activeTabResults = action.payload;
    },
    setActiveCommunityListTab(state, action: PayloadAction<TSCMState['activeCommunityListTab']>) {
      state.activeCommunityListTab = action.payload;
    },
    setNewOperationalCommunityUuid(
      state,
      action: PayloadAction<TSCMState['newOperationalCommunityUuid']>,
    ) {
      state.newOperationalCommunityUuid = action.payload;
    },
    setIsOperationalCommunity(state, action: PayloadAction<TSCMState['isOperationalCommunity']>) {
      state.isOperationalCommunity = action.payload;
    },
    setMeasurementRequests(state, action: PayloadAction<TSCMState['measurementRequests']>) {
      state.measurementRequests = action.payload;
    },
    setActiveLiveAccumulatedSubscriptionId(
      state,
      action: PayloadAction<TSCMState['activeLiveAccumulatedSubscriptionId']>,
    ) {
      state.activeLiveAccumulatedSubscriptionId = action.payload;
    },
    setSCMBillTableData(state, action: PayloadAction<TSCMState['scmBillTableData']>) {
      state.scmBillTableData = action.payload;
    },
    setSelectedMonthlyIndicator(
      state,
      action: PayloadAction<TSCMState['selectedMonthlyIndicator']>,
    ) {
      state.selectedMonthlyIndicator = action.payload;
    },
    setSelectedDailyIndicator(state, action: PayloadAction<TSCMState['selectedDailyIndicator']>) {
      state.selectedDailyIndicator = action.payload;
    },
    clearSCMResults(state) {
      state.homeBillsDifferences = undefined;
      state.communityBillsDifferences = undefined;
      state.homeKpi = undefined;
      state.communityKpi = undefined;
      state.kpiDifference = undefined;
      state.communityKpiDifference = undefined;
      state.scmBillTableData = [];
      state.selectedMonthlyIndicator = undefined;
      state.selectedDailyIndicator = undefined;
      state.preventPushResultsScreen = false;
      state.hideSidebarHeader = false;
      state.userListItemClicked = false;
    },
    setPreventPushResultsScreen(
      state,
      action: PayloadAction<TSCMState['preventPushResultsScreen']>,
    ) {
      state.preventPushResultsScreen = action.payload;
    },
    setHideSidebarHeader(state, action: PayloadAction<TSCMState['hideSidebarHeader']>) {
      state.hideSidebarHeader = action.payload;
    },
    setUnreadMessageCount(state, action: PayloadAction<TSCMState['unreadMessageCount']>) {
      state.unreadMessageCount = action.payload;
    },
    setUserListItemClicked(state, action: PayloadAction<TSCMState['userListItemClicked']>) {
      state.userListItemClicked = action.payload;
    },
    setDisableScmTabChanges(state, action: PayloadAction<TSCMState['disableScmTabChanges']>) {
      state.disableScmTabChanges = action.payload;
    },
  },
});

// Actions
export const {
  setDataSheetResponse,
  setActiveSCMStep,
  setSCMFlow,
  setIsAppLoading,
  setIsOperationalCommunity,
  setSCMCommunityManager,
  setSCMsidebarTab,
  setCommunityMembers,
  setHomeBillsDifferences,
  setCommunityBillsDifferences,
  setHomeKpi,
  setCommunityKpi,
  setKpiDifference,
  setCommunityKpiDifference,
  setDataResolution,
  setActiveTabResults,
  setActiveCommunityListTab,
  setNewOperationalCommunityUuid,
  setMeasurementRequests,
  setActiveLiveAccumulatedSubscriptionId,
  setSCMBillTableData,
  setSelectedMonthlyIndicator,
  setSelectedDailyIndicator,
  clearSCMResults,
  setPreventPushResultsScreen,
  setHideSidebarHeader,
  setUnreadMessageCount,
  setUserListItemClicked,
  setDisableScmTabChanges,
} = scmSlice.actions;

// Reducer
export const scmReducer = scmSlice.reducer;
