import produce, { castDraft } from 'immer';
import { TConfigurationCharacteristic, TUsedNames } from 'src/typings/configuration.types';
import {
  TAllFieldNames,
  TFieldsTemplateUnion,
  TFieldsUnionWithValue,
  TIsLibrary,
} from 'src/utils/assetsFields/assetsFields.types';

type TName = TAllFieldNames;

// prettier-ignore
const __removeExcludeKey = <
  T extends
    | TFieldsUnionWithValue
    | TFieldsTemplateUnion
    | TFieldsUnionWithValue[]
    | TFieldsTemplateUnion[]
>(
  fields: T,
): T extends TFieldsUnionWithValue ? TFieldsUnionWithValue :
  T extends TFieldsTemplateUnion ? TFieldsTemplateUnion :
  T extends TFieldsUnionWithValue[] ? TFieldsUnionWithValue[] :
  TFieldsTemplateUnion[] => {
  const fieldsArr = Array.isArray(fields) ? fields : [];
  const outputArr = produce(fieldsArr, (draftState) => {
    draftState.forEach((f: TFieldsUnionWithValue | TFieldsTemplateUnion) => {
      delete f.exclude;
    });
  });

  return Array.isArray(fields) ? outputArr : outputArr[0];
};

/* 
  Find a field with a given name
*/
const findField = (
  fields: TFieldsUnionWithValue[],
  name: TName,
): TFieldsUnionWithValue | undefined => fields.find((f) => f.name === name);

/* 
  Find out if a device is a single library
*/
const isSingleLibraryDevice = ({
  isLibrary,
  configurationCharacteristic: { gridMakerHasUploadedProfile, marketMakerRate },
}: {
  isLibrary: TIsLibrary;
  configurationCharacteristic: TConfigurationCharacteristic;
}): boolean => isLibrary && !gridMakerHasUploadedProfile && !marketMakerRate;

/* 
  Replace one field with another
*/
type TReplaceFieldArgs = {
  fields: TFieldsUnionWithValue[];
  name: TName;
  newFields: TFieldsUnionWithValue[];
  deleteCount?: number;
};
const replaceField = ({
  fields,
  name,
  newFields,
  deleteCount = 1,
}: TReplaceFieldArgs): TReplaceFieldArgs['fields'] => {
  // We use immer for immutability, cloneDeep is too expensive
  return produce(fields, (draftState) => {
    const index = draftState.findIndex((f) => f.name === name);

    if (index !== -1) {
      draftState.splice(index, deleteCount, ...castDraft(__removeExcludeKey(newFields)));
    }
  });
};

/* 
  Remove fields with given names
*/
type TRemoveFieldsArgs = {
  fields: TFieldsUnionWithValue[];
  names: TName[];
};
const removeFields = ({ fields, names }: TRemoveFieldsArgs): TRemoveFieldsArgs['fields'] => {
  return produce(fields, (draftState) => {
    names.forEach((name) => {
      const index = draftState.findIndex((f) => f.name === name);

      if (index !== -1) {
        draftState.splice(index, 1);
      }
    });
  });
};

/* Add a field directly after a specified field */
type TAddFieldsArgs = {
  fields: TFieldsUnionWithValue[];
  newFields: TFieldsUnionWithValue[];
  appendAfterName: TName;
};
const addFields = ({
  fields,
  newFields,
  appendAfterName,
}: TAddFieldsArgs): TAddFieldsArgs['fields'] => {
  return produce(fields, (draftState) => {
    const index = draftState.findIndex((f) => f.name === appendAfterName);

    if (index !== -1) {
      draftState.splice(index + 1, 0, ...castDraft(__removeExcludeKey(newFields)));
    } else {
      console.error(`A field with a name: ${appendAfterName} was not found`);
    }
  });
};

/* Enable fields to be edited */
type TEnableFieldsArgs = {
  fields: TFieldsUnionWithValue[];
  names: TName[];
};
const enableFields = ({ fields, names }: TEnableFieldsArgs): TEnableFieldsArgs['fields'] => {
  return produce(fields, (draftState) => {
    names.forEach((name) => {
      const index = draftState.findIndex((f) => f.name === name);

      if (index !== -1) {
        draftState[index].disabled = false;
      } else {
        console.error(`A field with a name: ${names} was not found`);
      }
    });
  });
};

type TGetUniqueAssetNameArgs = {
  newName: string;
  usedAssetsNames: TUsedNames;
};
function getUniqueAssetName({
  newName,
  usedAssetsNames,
}: TGetUniqueAssetNameArgs): TGetUniqueAssetNameArgs['newName'] {
  const similarNamesNumbers: Array<number | null> = [];
  const escapedString = String(newName)
    .replace(/([-()\[\]{}+?*.$^|,:#<!\\])/g, '\\$1')
    .replace(/\x08/g, '\\x08');

  const regexp = new RegExp(`^${escapedString} *(\\d*)$`);
  let uniqueName = newName;

  usedAssetsNames.forEach((name) => {
    if (name) {
      const match = name.match(regexp);
      if (match) {
        similarNamesNumbers.push(match[1] !== '' ? parseInt(match[1], 10) : null);
      }
    }
  });

  if (similarNamesNumbers.length) {
    const last = similarNamesNumbers
      .filter(Boolean)
      .sort((a, b) => Number(a) - Number(b))
      .slice(-1)[0];

    if (last != null) {
      const num = last + 1;
      uniqueName = `${newName} ${num}`;
    } else {
      const num = 2;
      uniqueName = `${newName} ${num}`;
    }
  }

  if (usedAssetsNames.indexOf(uniqueName) > -1) {
    console.error('Function getUniqueAssetName() failed in "src/utils/fieldUtils.ts"', {
      usedAssetsNames,
      uniqueName,
    });
  }

  return uniqueName;
}

export {
  addFields,
  enableFields,
  findField,
  getUniqueAssetName,
  isSingleLibraryDevice,
  removeFields,
  replaceField,
};
