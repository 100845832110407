import React, { useMemo, useRef, useEffect } from 'react';

import { Chart, ChartTypeRegistry, PieController, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { InfoHelper } from 'src/components/InfoHelper';
import { COLOR_PALETTE } from 'src/constants/colorPalette';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { EScreenMode } from 'src/typings/configuration.types';
import { formatter } from 'src/utils/formatter';
import { sumOfField } from 'src/utils/general';

import s from './PieChart.module.scss';
import { TPieChartProps } from './PieChart.types';

Chart.register(PieController, ArcElement, Tooltip, Legend, ChartDataLabels);

export const PieChart: React.FC<TPieChartProps> = ({
  title,
  items,
  isItemInfoActive = true,
  isTitleInfoActive = false,
  titleInfo,
  className,
}) => {
  const screenMode = useSelector(selectScreenMode);

  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const data = useMemo(() => items.map((item) => item.value), [items]);
  const backgroundColors = useMemo(() => COLOR_PALETTE.slice(1, items.length + 1), [items]);
  const sum = useMemo(() => sumOfField(items, 'value'), [items]);

  useEffect(() => {
    if (!canvasRef.current) return;

    if (chartRef.current) {
      chartRef.current.clear();
      chartRef.current.destroy();
    }

    chartRef.current = new Chart<keyof ChartTypeRegistry, number[], unknown>(canvasRef.current, {
      type: 'pie',
      data: {
        datasets: [
          {
            data,
            backgroundColor: backgroundColors,
            borderWidth: 0,
          },
        ],
      },
      options: {
        layout: {
          padding: 0,
        },

        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: '#fff',
            formatter: (value: number) => value ? `${((value / sum) * 100).toFixed(0)}%` : '',
          },
        },
      },
    });

    chartRef.current.resize();

    return () => {
      chartRef.current?.reset();
      chartRef.current?.clear();
    };
  }, [items, backgroundColors, data, sum]);

  return (
    <ChartWrapper
      title={title}
      titleRightSlotComponent={
        isTitleInfoActive && <InfoHelper info={titleInfo} position="bottom" />
      }
      className={classnames(s.container, className)}>
      <ChartDataLoadingWrapper loading={!items}>
        <div className={classnames(s.content)}>
          <div className={s.doughnut}>
            <canvas ref={canvasRef} data-type={title} data-progress={items ?? 0} />
          </div>
          <div className={s.legendContainer}>
            {items.map((item, index) => (
              <div key={index} className={s.legend}>
                {screenMode !== EScreenMode.Mobile && (
                  <div
                    className={s.legendDot}
                    style={{ backgroundColor: backgroundColors[index] }}
                  />
                )}
                <p className={classnames(s.legendLabel, { [s.height18]: index === 1 })}>
                  {item.label}
                </p>
                <span>
                  {formatter.toOptionalFixed(item.value).toFixed(2).replace('.', ',')} kWh
                </span>
                {isItemInfoActive && <InfoHelper info={item.info} position="left" />}
              </div>
            ))}
          </div>
        </div>
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
