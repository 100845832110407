export enum ETabs {
  BASIC = 'navigation.BASIC_SETTINGS',
  ADVANCED = 'navigation.ADVANCED_SETTINGS',
  SIMULATIONS = 'navigation.SIMULATIONS',
  OPERATIONAL = 'navigation.OPERATIONAL',
}

export type TTabsProps<T> = {
  tabs: Array<T>;
  activeTab: T;
  handleClick: (tab: T) => void;
  className?: string;
};
