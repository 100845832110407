import { ChartOptions, ChartType } from 'chart.js';
import { TBidsAndOffersDataPoint } from 'src/components/Charts/ChartBidsAndOffers';
import {
  commonChartOptions,
  commonInteraction,
  commonTooltipSettings,
  commonXScale,
} from 'src/components/Charts/chartsData/commonOptions';
import {
  DeviceProfileFourConfig,
  DeviceProfileOneConfig,
  DeviceProfileTankConfig,
  DeviceProfileThreeConfig,
  DeviceProfileTwoConfig,
} from 'src/components/Charts/chartsData/deviceProfile.config';
import { SavingsConfig } from 'src/components/Charts/chartsData/savings.config';
import { tooltipCallbacks } from 'src/components/Charts/chartsData/tooltipCallbacks';
import { formatter } from 'src/utils/formatter';
import { naturalSort } from 'src/utils/naturalSort';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export enum EChartName {
  Prices = 'PRICES',
  DayProfile = 'DAY_PROFILE',
  Self = 'SELF',
  HouseSummary = 'HOUSE_SUMMARY',
  SimulationProgress = 'SIMULATION_PROGRESS',
  BidsAndOffers = 'BIDS_AND_OFFERS',
  EnergyTradeProfile = 'ENERGY_TRADE_PROFILE',
  DeviceProfileOne = 'DEVICE_PROFILE_ONE',
  DeviceProfileTwo = 'DEVICE_PROFILE_TWO_FOUR',
  DeviceProfileThree = 'DEVICE_PROFILE_THREE',
  DeviceProfileFour = 'DEVICE_PROFILE_FOUR',
  Savings = 'SAVINGS',
  EnergyBill = 'ENERGY_BILL',
  DeviceProfileTank = 'DEVICE_PROFILE_TANK',
}

export const chartTypes: { [key in EChartName]: ChartType } = {
  [EChartName.Prices]: 'line',
  [EChartName.DayProfile]: 'line',
  [EChartName.Self]: 'doughnut',
  [EChartName.HouseSummary]: 'line',
  [EChartName.SimulationProgress]: 'line',
  [EChartName.BidsAndOffers]: 'bubble',
  [EChartName.EnergyTradeProfile]: 'bar',
  [EChartName.DeviceProfileOne]: 'line',
  [EChartName.DeviceProfileTwo]: 'bar',
  [EChartName.DeviceProfileThree]: 'bar',
  [EChartName.DeviceProfileFour]: 'bar',
  [EChartName.DeviceProfileTank]: 'line',
  [EChartName.Savings]: 'bar',
  [EChartName.EnergyBill]: 'doughnut',
};

export function getChartType(chartName: EChartName): ChartType {
  return chartTypes[chartName];
}

function toEuroCents(cents: number) {
  const euros = String(formatter.toOptionalFixed(cents / 100, 4));
  if (euros.match(/\.\d\b/g)) {
    return `${euros}0`;
  }
  return euros;
}

export function getChartOptions(chartName: EChartName): ChartOptions {
  switch (chartName) {
    case EChartName.DayProfile:
      return {
        ...commonChartOptions,
        scales: {
          x: commonXScale,
          y: {
            ticks: {
              padding: 0,
              display: false,
            },
            grid: {
              drawBorder: false,
              borderDash: [2, 5],
              drawTicks: false,
            },
          },
          // yVolumeTrades: {
          //   ...dayProfileCommonYAxis,
          //   grid: {
          //     drawBorder: false,
          //     borderDash: [2, 5],
          //     drawTicks: false,
          //   },
          // },
          // yPricesAndFees: dayProfileCommonYAxis,
        },
        interaction: commonInteraction,
        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      } as ChartOptions;
    case EChartName.Prices:
      return {
        ...commonChartOptions,
        scales: {
          x: commonXScale,
          y: {
            ticks: {
              padding: 10,
              display: false,
            },
            grid: {
              drawBorder: false,
              borderDash: [2, 5],
              drawTicks: false,
            },
          },
        },
        interaction: commonInteraction,

        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      } as ChartOptions;
    case EChartName.Self:
      return {
        layout: {
          padding: 0,
        },
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      } as ChartOptions;
    case EChartName.EnergyBill:
      return {
        layout: {
          padding: 10,
        },
        borderWidth: 2,
        borderColor: '#fff',
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: true,
          },
        },
      } as ChartOptions;
    case EChartName.HouseSummary:
      return {
        ...commonChartOptions,
        scales: {
          x: {
            display: false,
            type: 'time',
          },
          y: {
            display: false,
          },
          'y-net-energy': {
            display: false,
          },
          'y-prices': {
            display: false,
          },
        },
        interaction: commonInteraction,

        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      } as ChartOptions;
    case EChartName.SimulationProgress:
      return {
        ...commonChartOptions,
        responsive: false,
        scales: {
          x: {
            type: 'time',
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 1,
          },
        },
        plugins: {
          decimation: {
            enabled: true,
            // @ts-ignore
            algorithm: 'lttb',
            samples: 200,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      } as ChartOptions;
    case EChartName.BidsAndOffers:
      return {
        responsive: true,
        animation: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            type: 'time',
            ticks: {
              autoSkip: true,
            },
            grid: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
            },
            afterBuildTicks(axis) {
              axis.ticks = axis.ticks.filter((item) => {
                const minutes = +UTCMoment.fromUnix(item.value / 1000).format('mm');
                return !(minutes % 5);
              });
            },
          },
          y: {
            ticks: {
              padding: 7,
            },
            grid: {
              drawBorder: false,
              borderDash: [1, 5],
              drawTicks: false,
              tickWidth: 0,
            },
          },
        },
        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            displayColors: false,
            yAlign({ tooltip }) {
              const { caretY } = tooltip;
              // @ts-ignore
              return caretY < tooltip._chart.height / 2 ? 'top' : 'bottom';
            },
            callbacks: {
              title(context) {
                const { dataIndex, dataset } = context[0];
                const timestamp = (dataset.data[dataIndex] as TBidsAndOffersDataPoint).x;
                return UTCMoment.utc(timestamp).format(FRONTEND_DATE_FORMATS.TOOLTIP_CHART_PRECISE); // There are situations where timestamps differs only in seconds, that's why we display seconds.
              },
              label: function (context) {
                const { chart, raw } = context;
                const { min, max, height } = chart.scales.y;
                const hoveredData = raw as TBidsAndOffersDataPoint;

                const visibleDatasets = chart.config.data.datasets.filter((p) => !p.hidden);
                const overlappingEntriesSorted = visibleDatasets
                  .map((dataset) => {
                    const filteredData = dataset.data.filter((item) => {
                      if (!item) return false;

                      const {
                        creation_time,
                        time,
                        energy_rate: energyRate,
                      } = item as TBidsAndOffersDataPoint;

                      return (
                        (creation_time === hoveredData.creation_time ||
                          time === hoveredData.time) &&
                        Math.abs(energyRate - hoveredData.energy_rate) <=
                          ((max - min) / height) * hoveredData.r
                      );
                    });

                    return naturalSort(
                      filteredData as TBidsAndOffersDataPoint[],
                      (p) => p.buyer_origin || p.seller_origin,
                    );
                  })
                  .flat();

                return overlappingEntriesSorted.map((entry) => {
                  const {
                    buyer_origin: buyerOrigin,
                    seller_origin: sellerOrigin,
                    energy_rate: energyRate,
                    type,
                    energy,
                  } = entry;
                  // Show 'Infinite' if energy value is too high. It means the device is a Market Maker or Infinite Bus
                  const itemEnergy =
                    energy > 999999999999 ? 'Infinite' : formatter.toOptionalFixed(energy, 4);
                  const energyRateFormatted = toEuroCents(energyRate);

                  if (type === 'Trade') {
                    return `Trade: ${sellerOrigin} → ${buyerOrigin} (${itemEnergy} kWh at ${energyRateFormatted}  € / kWh)`;
                  }

                  return `${type === 'Offer' ? sellerOrigin : buyerOrigin} ${type} (${itemEnergy} ${
                    itemEnergy === 'Infinite' ? '' : 'kWh'
                  } at ${energyRateFormatted} € / kWh)`;
                });
              },
            },
          },
        },
      };
    case EChartName.EnergyTradeProfile:
      return {
        animation: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            stacked: true,
            type: 'time',
            ticks: {
              display: false,
            },
            // time: {
            //   unit: 'day', // This changes how data is plotted, that's why we use AxisXCustom
            // },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              display: false,
            },
            grid: {
              drawTicks: false,
              drawBorder: false,
              borderDash: [2, 5],
            },
          },
        },
        interaction: commonInteraction,
        plugins: {
          tooltip: {
            ...commonTooltipSettings,
            callbacks: {
              title: tooltipCallbacks.title,
              label: tooltipCallbacks.label,
            },
          },
        },
      };
    case EChartName.DeviceProfileTank:
      return DeviceProfileTankConfig;

    case EChartName.DeviceProfileOne:
      return DeviceProfileOneConfig;

    case EChartName.DeviceProfileTwo:
      return DeviceProfileTwoConfig;

    case EChartName.DeviceProfileThree:
      return DeviceProfileThreeConfig;

    case EChartName.DeviceProfileFour:
      return DeviceProfileFourConfig;

    case EChartName.Savings:
      return SavingsConfig;
  }
}
