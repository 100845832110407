import React from 'react';

import classNames from 'classnames';
import { InfoHelper } from 'src/components/InfoHelper';
import { TKeyResultInfoProps } from 'src/components/KeyResults/components/KeyResultInfoArea/KeyResultInfoArea.types';

import s from '../../KeyResults.module.scss';

export const KeyResultInfoArea: React.FC<TKeyResultInfoProps> = ({
  value,
  symbol,
  symbolPosition,
  resultInfo,
  className,
  useTooltip = true,
}) => {
  const { title, description } = resultInfo;
  return (
    <div className={classNames(s.assetInfo, s.block, className)}>
      <header>
        <div className={s.earningStatus}>{title}</div>
        {useTooltip && <InfoHelper position="bottom" info={description} />}
      </header>
      <div className={s.cost}>
        <span>
          {symbolPosition === 'left' && symbol}
          {value}
          {symbolPosition === 'right' && symbol}
        </span>
      </div>
      {!useTooltip && <span className={s.description}>{description}</span>}
    </div>
  );
};
