import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TFilterItem } from 'src/features/Assets/ListAssets/ListAssetsWithSearch/AssetsListWithSearch.types';

export const MAX_ASSETS_LOGGED_IN = 1000;
export const MAX_ASSETS_GUEST = 50;

export const FILTER_BUTTONS: Array<TFilterItem> = [
  {
    label: 'All',
    value: LIBRARY_FILTERS_MAPPING.All,
  },
  {
    icon: 'house',
    label: 'Home',
    value: LIBRARY_FILTERS_MAPPING.Area,
  },
  {
    icon: 'pv',
    label: 'Solar Panels (PVs)',
    value: LIBRARY_FILTERS_MAPPING.PV,
  },
  {
    icon: 'plug',
    label: 'Consumption',
    value: LIBRARY_FILTERS_MAPPING.Load,
  },
  {
    icon: 'battery',
    label: 'Battery',
    value: LIBRARY_FILTERS_MAPPING.Storage,
  },
  {
    icon: 'heatpump',
    label: 'Heat Pump',
    value: LIBRARY_FILTERS_MAPPING.HeatPump,
  },
];
