import React from 'react';

import { ListCommunitiesCentral } from 'src/features/Communities/ListCommunities/ListCommunitiesCentral';

import s from './ListCommunities.module.scss';
import { TListCommunitiesWrapperProps } from './ListCommunities.types';
import { ListCommunitiesHeader } from './ListCommunitiesHeader';

export const ListCommunitiesWrapper: React.FC<TListCommunitiesWrapperProps> = () => {
  return (
    <div className={s.container}>
      <ListCommunitiesHeader />
      <ListCommunitiesCentral />
    </div>
  );
};
