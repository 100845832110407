import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSearch } from 'src/components/BaseSearch';
import { FormAssetsParams } from 'src/components/FormAssetsParams';
import {
  EOrientationParam,
  TOrientationParam,
} from 'src/features/Assets/SingleAsset/CustomPV/Orientation/Orientation.types';
import { LibraryOutput } from 'src/graphql';
import { useLibraryHelpers } from 'src/hooks/useLibraryHelpers';
import { selectTokenAuth } from 'src/redux/auth/auth.selectors';
import { selectAssetValuesForUuid } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { get2dAssetIcon, get3dAssetIcon } from 'src/utils/assetIcons';
import { nonNullable } from 'src/utils/ts-utils';

import { TAssetListItemProps, TAssetsListData } from './DashboardAsset.types';
import s from './DashboardAssetPreview.module.scss';

const NAVIGATION = [
  { title: 'Assets', id: 'assets' },
  { title: 'Basic Settigns', id: 'basicSettings' },
  { title: 'Advanced Settings', id: 'advancedSettings' },
];

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

const AssetListItem: React.FC<TAssetListItemProps> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={classNames(s.assetListItem, {
        [s.isSelected]: props.isSelected,
        [s.isSelectable]: props.isSelectable,
      })}
      style={props.style}>
      <BaseIcon icon={props.icon} size={16} />
      <p>{props.title}</p>
      <button type="button">
        <BaseIcon icon="more-horizontal" size={16} />
      </button>
    </div>
  );
};

export const DashboardAssetPreview: React.FC<{
  data: LibraryOutput;
  selectedAsset: string | undefined;
  setMode: React.Dispatch<React.SetStateAction<'assetPreview' | 'addNewAsset'>>;
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
  onPreviewAction(actionType: 'delete' | 'duplicate', data?: LibraryOutput): void;
}> = ({ data, selectedAsset, setMode, setSelectedAsset, onPreviewAction }) => {
  //show asset tab or not
  const isStandaloneAsset =
    data?.tags?.includes('DefaultLib') || data?.scenarioData?.representation?.type !== 'Area';

  const userInfo = useSelector(selectTokenAuth);

  const dispatch = useAppDispatch();
  const { deleteLibrary, duplicateLibrary } = useLibraryHelpers();

  const formHasErrorsRef = useRef(false);

  const assetValues = useSelector(selectAssetValuesForUuid(data.uuid!));

  const [activeTab, setActiveTab] = useState(NAVIGATION[0].id);
  const [searchValue, setSearchValue] = useState<string>('');

  const tabsNavigation = NAVIGATION.filter((item) =>
    isStandaloneAsset ? item.id !== 'assets' : true,
  );

  const scenarioData = useMemo(
    () => JSON.parse(data?.scenarioData?.representation?.serialized || ''),
    [data?.scenarioData?.representation?.serialized],
  );

  const marketsCount = useMemo(
    () => scenarioData.children?.filter((item) => item.type === 'MarketMaker').length || 0,
    [scenarioData.children],
  );

  const getAsset = (uuid: string) => {
    return scenarioData.children.find((item) => item.uuid === uuid);
  };

  const onAddAssetClick = () => setMode('addNewAsset');

  const onDeleteClick = async () => {
    if (data.uuid) {
      const deleteLib = await deleteLibrary({
        uuid: data.uuid,
      });
      if (deleteLib) {
        dispatch(
          openToast({
            type: 'success',
            message: 'Library deleted',
          }),
        );
        setSelectedAsset(undefined);
        onPreviewAction('delete');
      }
    }
  };

  const onDuplicateClick = async () => {
    if (data.uuid) {
      const duplicatedLib = await duplicateLibrary({
        uuid: data.uuid,
        isPrivate: true,
      });
      if (duplicatedLib) {
        dispatch(
          openToast({
            type: 'success',
            message: 'Library duplicated',
          }),
        );
        setSelectedAsset(undefined);
        onPreviewAction('duplicate', duplicatedLib);
      }
    }
  };

  const selectListItem = (value) => {
    setSelectedAsset(selectedAsset === value ? undefined : value);
  };

  const onSearchChange = ({ value }) => {
    setSearchValue(value);
  };

  const renderAssetsList = (items: TAssetsListData[], level = 0) => {
    return items.map(({ children, ...item }, i) => (
      <React.Fragment key={i}>
        {(searchValue ? item.title.toLowerCase().includes(searchValue) : true) && (
          <AssetListItem
            key={i}
            {...item}
            style={{ marginLeft: `${20 * level}px` }}
            isSelected={selectedAsset === item.uuid}
            onClick={() => children && !searchValue.length && selectListItem(item.uuid)}
          />
        )}
        {children &&
          (searchValue.length || selectedAsset === item.uuid) &&
          renderAssetsList(children, level + 1)}
      </React.Fragment>
    ));
  };

  const assetsList = useMemo<TAssetsListData[]>(() => {
    if (!scenarioData) return [];

    const createDataItem = (item): TAssetsListData => ({
      uuid: item.uuid,
      title: `${item.name}`,
      icon: get2dAssetIcon(item.type),
      isSelected: false,
      isSelectable: !!item.children,
      children: item.children ? createList(item.children) : undefined,
    });

    const createList = (items: TAssetsListData[]) =>
      items.reduce<TAssetsListData[]>((acc, item) => {
        acc.push(createDataItem(item));
        return acc;
      }, []);

    return scenarioData.children ? createList(scenarioData.children) : [];
  }, [scenarioData]);

  useEffect(() => {
    setActiveTab(NAVIGATION[isStandaloneAsset ? 1 : 0].id);
  }, [isStandaloneAsset]);

  // const isOwner = data?.user === userName;
  const isOwner = data?.user === userInfo?.email;
  const selectedAssetData = selectedAsset && getAsset(selectedAsset);
  const isCustomPV = Boolean(nonNullable(scenarioData.azimuth) && nonNullable(scenarioData.tilt));

  return (
    <div className={s.container}>
      {!data && <BaseIcon icon="spinner" size={40} />}
      {data && (
        <>
          <div className={s.hero}>
            <header>
              <div className={s.usage}>
                <span>used by</span>
                <span>used in</span>
              </div>
              <div>{isOwner ? 'YOU' : data.user?.split('@')[0]}</div>
            </header>
            <div className={s.icon}>
              <BaseIcon icon={get3dAssetIcon(scenarioData.type)} />
            </div>
            <p>
              Markets: {marketsCount} Assets: {data.scenarioData?.deviceCountNumber}
            </p>
          </div>
          <nav className={s.navigation}>
            {tabsNavigation.map((item, i) => (
              <button
                key={i}
                type="button"
                className={classNames({
                  [s.active]: activeTab === item.id,
                })}
                onClick={() => setActiveTab(item.id)}>
                {item.title}
              </button>
            ))}
          </nav>
          {activeTab === 'assets' && (
            <div className={s.assetsList}>
              <div className={s.content}>
                {assetsList.length ? (
                  <>
                    <BaseSearch
                      className={s.search}
                      name="search"
                      value={searchValue}
                      onChange={onSearchChange}
                    />

                    {renderAssetsList(assetsList)}
                  </>
                ) : (
                  <p style={{ textAlign: 'center', opacity: 0.6 }}>
                    <strong>{data?.name}</strong> does not have any child assets.
                  </p>
                )}
                {isOwner && (
                  <button type="button" className={s.addAssetButton} onClick={onAddAssetClick}>
                    <BaseIcon icon="plus" size={9} /> Add Asset
                    {selectedAssetData && <span>({selectedAssetData.name})</span>}
                  </button>
                )}
              </div>
            </div>
          )}
          {[NAVIGATION[1].id, NAVIGATION[2].id].includes(activeTab) && (
            <div className={s.settings}>
              <FormAssetsParams
                hasErrorsRef={formHasErrorsRef}
                id={'library-settings'}
                assetUuid={data.uuid!}
                assetType={data!.scenarioData!.representation!.type! as TAssetType}
                formVariant={
                  EFormVariant[activeTab === NAVIGATION[1].id ? 'BasicLibrarySettings' : 'Advanced']
                }
                onSubmit={() => {
                  //
                }}
                currentValues={assetValues}
                disableLocationField={true}
                isCustomPV={isCustomPV}
                azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
                setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                isEdit={isOwner}
                theme="dark"
              />
              {isOwner && (
                <button type="submit" form="library-settings" className={s.saveSettings}>
                  Save
                </button>
              )}
            </div>
          )}
          <footer>
            <button type="button" onClick={onDuplicateClick}>
              <BaseIcon icon="duplicate" size={13} />
              <span>Duplicate</span>
            </button>
            <button type="button">
              <BaseIcon icon="download" size={13} />
              <span>Download</span>
            </button>
            {isOwner && (
              <button type="button" onClick={onDeleteClick}>
                <BaseIcon icon="custom-trash" size={13} />
                <span>Delete</span>
              </button>
            )}
          </footer>
        </>
      )}
    </div>
  );
};
