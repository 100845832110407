import { TFormSettingsDataProps } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';

export type TNewCommunityProps = {
  className?: string;
  style?: React.CSSProperties;
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
};

export enum EFormVariant {
  Basic = 'BASIC',
  Advanced = 'ADVANCED',
}

export enum EActiveTabState {
  General = 'GENERAL_INFORMATION',
}
