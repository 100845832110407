import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import {
  TAssetFieldTemplatesArgs,
  TFieldsUnionWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import { baseFieldTemplates } from 'src/utils/assetsFields/fieldTemplates';

/*
 *
 * This function returns all asset's templates with values injected
 *
 */
export function getAllFieldTemplatesWithValuesForAsset({
  type,
  configType,
  values,
  advancedSettings,
}: TAssetFieldTemplatesArgs): TFieldsUnionWithValue[] {
  const fieldTemplates = baseFieldTemplates({
    type,
    configType,
    advancedSettings,
    values,
  });

  const fieldsTemplatesWithValues: TFieldsUnionWithValue[] = fieldTemplates
    .filter((field) => !field.exclude)
    .map(
      (field) =>
        ({
          ...field,
          value: !!values
            ? Object.keys(values).includes(field.name)
              ? values![field.name]
              : field.defaultValue
            : field.defaultValue,
        } as TFieldsUnionWithValue),
    );

  return fieldsTemplatesWithValues;
}

type TAssetValues = TConfigurationState['assetsValues']['assetType'];
export function getAssetValues(fields: TFieldsUnionWithValue[]): TAssetValues {
  return fields.reduce((acc, item) => {
    // @ts-ignore
    acc[item.name] = item.value;
    return acc;
  }, {} as TAssetValues);
}
