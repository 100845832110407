import { ETabs } from 'src/components/Tabs';
import { GENERATION_PROFILE_OPTIONS } from 'src/constants/application';
import { TFieldsAssetArgs } from 'src/features/Assets/SingleAsset/SingleAsset.types';
import { ConfigType } from 'src/graphql';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';
import { getParentGeotagLocation } from 'src/utils/assetsFields/fieldTemplates/utils';

const PV = ({
  configType = ConfigType.Collaboration,
  values,
}: TFieldsAssetArgs): TFieldsTemplateUnion[] => {
  const isDisabled = configType === ConfigType.CanaryNetwork;
  const profileMode = values?.profileMode || GENERATION_PROFILE_OPTIONS[1].value;

  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.ASSET_NAME',
      defaultValue: 'PV',
      formView: ETabs.BASIC,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'labels.LOCATION',
      tooltipText: 'Parent market already has location',
      defaultValue: getParentGeotagLocation(),
      formView: ETabs.BASIC,
    },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'common.CAPACITY',
      formView: ETabs.ADVANCED,
      defaultValue: 5,
      unit: 'kWp',
      disabled: isDisabled,
    },
    {
      name: 'profileMode',
      type: 'enum',
      label: 'common.PROFILE',
      options: GENERATION_PROFILE_OPTIONS,
      formView: ETabs.ADVANCED,
      showTooltip: true,
      tooltipText: 'messages.GET_CORRECT_RESULTS',
      defaultValue: GENERATION_PROFILE_OPTIONS[1].value,
      disabled: isDisabled,
    },
    {
      name: 'powerProfile',
      type: 'file',
      label: 'labels.OVERRIDE_PROFILE',
      formView: ETabs.ADVANCED,
      disabled: isDisabled,
      exclude: profileMode === GENERATION_PROFILE_OPTIONS[1].value,
    },
    { name: 'cloudCoverage', type: 'number', defaultValue: 5, exclude: true },
    { name: 'azimuth', type: 'number', defaultValue: 0, exclude: true },
    { name: 'tilt', type: 'number', defaultValue: 0, exclude: true },
  ];
};

export default PV;
