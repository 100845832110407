import {
  TFieldsUnionWithValue,
  TReactionOutput,
  TReactionPayload,
} from 'src/utils/assetsFields/assetsFields.types';
import { addFields, removeFields, replaceField } from 'src/utils/fieldUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const siblingFieldsReactions: any = {
  /* PV */
  PV: {
    profileMode: ({ fields, newValue }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (newValue != 4) {
        output = removeFields({
          fields: output,
          names: ['powerProfile', 'powerProfileUuid'],
        });
      }
      if (newValue == 4) {
        output = removeFields({
          fields: output,
          names: ['capacityKw'],
        });

        const newField = null;

        if (newField) {
          output = addFields({
            fields: output,
            newFields: [newField],
            appendAfterName: 'cloudCoverage',
          });
        }
      } else {
        output = removeFields({
          fields: output,
          names: ['powerProfile'],
        });
      }

      return output;
    },
    powerProfile: ({ fields }: TReactionPayload): TReactionOutput => {
      let output = fields;

      output = removeFields({ fields: output, names: ['powerProfileUuid'] });

      return output;
    },
  },

  /* Load */
  Load: {
    loadProfileOption: ({ fields, newValue }: TReactionPayload): TReactionOutput => {
      let output = fields;

      if (newValue === 'userConfigure') {
        const index = output.findIndex((f) => f.name === 'dailyLoadProfile');

        if (index !== -1) {
          const newFields = [];

          output = replaceField({
            fields: output,
            name: 'dailyLoadProfile',
            newFields: newFields.filter(Boolean) as TFieldsUnionWithValue[],
          });

          output = removeFields({
            fields: output,
            names: ['dailyLoadProfileUuid'],
          });
        }
      } else if (newValue === 'userUpload') {
        const newField = null;

        if (newField) {
          output = replaceField({
            fields: output,
            name: 'avgPowerW',
            deleteCount: 3,
            newFields: [newField],
          });
        }
      }

      return output;
    },
  },
};
