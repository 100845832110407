import { store } from 'src/redux/store';
import { TCommonFieldValues } from 'src/utils/assetsFields/valuesByFieldName.types';

export function getParentGeotagLocation(): TCommonFieldValues['geoTagLocation'] {
  const currentState = store.getState();

  let currentParentGeotag: TCommonFieldValues['geoTagLocation'] = null;
  const selectedAssetUuid = currentState.configuration.selectedAssetUuid;
  if (selectedAssetUuid)
    currentParentGeotag =
      currentState?.configuration?.assetsValues[selectedAssetUuid]?.geoTagLocation || null;
  return currentParentGeotag;
}
