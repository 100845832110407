import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TAccordionProps } from 'src/components/Accordion/Accordion.types';
import { BaseIcon } from 'src/components/BaseIcon';
import s from 'src/features/Assets/SingleAsset/CustomPV/CustomPV.module.scss';

export const Accordion: React.FC<TAccordionProps> = ({ name, handleClick, isOpen, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(s.accordion, className)} onClick={() => handleClick(name)}>
      <div className={s.title}>{t(name)}</div>
      <BaseIcon
        className={classnames(s.arrowIcon, {
          [s.arrowOpen]: isOpen,
        })}
        icon="chevron-down"
        size={12}
      />
    </div>
  );
};
