import React, { useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { CustomResultHeader } from 'src/features/Results/DashboardHeader/CustomResultHeader';
import { DownloadResultsButton } from 'src/features/Results/DashboardHeader/CustomResultHeader/DownloadResultsButton';
import { TDashboardHeaderProps } from 'src/features/Results/DashboardHeader/DashboardHeader.types';
import { useReadConfigurationQuery } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationUuid,
  selectAssetsValues,
} from 'src/redux/configuration/configuration.selectors';
import { selectSelectedAreaUuid } from 'src/redux/configuration/configuration.selectors';
import { setSelectedAreaUuid } from 'src/redux/configuration/configuration.slice';
import { selectDataResolution } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './DashboardHeader.module.scss';

export const DashboardHeader: React.FC<TDashboardHeaderProps> = ({ className, mode }) => {
  const dispatch = useAppDispatch();

  const dataResolution = useSelector(selectDataResolution);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const assetsValues = useSelector(selectAssetsValues);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const communityArea = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    return scenario?.children.find((child) => child.type === 'Area');
  }, [configuration]);
  const memberArea = useMemo(() => {
    return communityArea?.children.find((area) => area.uuid === selectedAreaUuid);
  }, [communityArea, selectedAreaUuid]);

  const { zoomIntoConfiguration } = useConfigurationUtils();
  const { resultsStartTime, resultsEndTime, handleResolutionButton } = useAccumulatedResults({
    initialRunQueries: ['getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
  });

  const { isUserACommunityMember } = useIsUserACommunityMember();

  const onClickGoBack = () => {
    dispatch(setSidebarExpanded(true));
    dispatch(setSelectedAreaUuid(communityArea.uuid));
    zoomIntoConfiguration({ assetsValues });
  };
  return (
    <div className={classnames(s.header, className)}>
      <CustomResultHeader
        onClickGoBack={onClickGoBack}
        handleResolutionButton={handleResolutionButton}
        selectedResolution={dataResolution}
        isHomeNameActive={mode == 'community' ? false : !isUserACommunityMember}
        isCommunityLabelActive={mode == 'community' ? !isUserACommunityMember : false}
        flag={isOperationCommunity ? 'Operation' : 'Simulation'}
        homeName={mode == 'community' ? null : memberArea?.name}
      />
      {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
        <div className={s.downloadResultContainer}>
          <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
        </div>
      )}
    </div>
  );
};
