import React, { useMemo } from 'react';

import classnames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ConfigType } from 'src/graphql';
import { useReadConfigurationQuery } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectConfigType,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { selectMeasurementRequests, selectUnreadMessageCount } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './MapSidebarSingleCommunity.module.scss';
import { EActiveTabState } from './MapSidebarSingleCommunity.types';
import { TMapSidebarSingleCommunityTabsProps } from './MapSidebarSingleCommunity.types';

export const MapSidebarSingleCommunityTabs: React.FC<TMapSidebarSingleCommunityTabsProps> = ({
  activeTab,
  handleTabChange,
  showConnectCommunityTooltip,
  handleResultsTabClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const unreadMessageCount = useSelector(selectUnreadMessageCount);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const isEmbed = useSelector(selectIsEmbed);
  const activeStep = useSelector(selectActiveSCMStep);
  const isAdmin = useSelector(selectIsAdmin);
  const measurementRequests = useSelector(selectMeasurementRequests);
  const simulationStatus = useSelector(selectSimulationStatus);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const configType: ConfigType = useMemo(() => configuration?.type || ConfigType.Collaboration, [
    configuration,
  ]);

  const { isOwnerCM, isOwnerAdmin } = useIsAdminInspecting();
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const showResultsTab = typeof simulationStatus !== 'undefined';
  const showMessagingPortal = isUserACommunityMember || isOwnerCM || isOwnerAdmin;
  const showUnreadCount = useMemo(() => {
    if (unreadMessageCount === 0) return false;
    return true;
  }, [unreadMessageCount]);

  const showInfoToolTip = useMemo(
    () => measurementRequests && measurementRequests.length > 0 && !isAdmin,
    [measurementRequests, isAdmin],
  );

  const handleMessagePortalTabClick = () => {
    handleTabChange(EActiveTabState.MessagingPortal);
    dispatch(setSidebarExpanded(true));
  };

  return (
    <>
      <div className={s.header}>
        <ul className={s.headerNav}>
          {isUserACommunityMember ? (
            <li
              className={classnames({
                [s.active]: activeTab === EActiveTabState.Profile,
              })}>
              <button
                type="button"
                onClick={() => {
                  dispatch(setSidebarExpanded(false));
                  handleTabChange(EActiveTabState.Profile);
                }}
                title="Profile">
                {t('MY_PROFILE')}
              </button>
            </li>
          ) : (
            <li
              className={classnames({
                [s.active]: [EActiveTabState.Settings].includes(activeTab),
              })}>
              <button
                type="button"
                onClick={() => handleTabChange(EActiveTabState.Settings)}
                title="Settings">
                {t('navigation.SETTINGS')}
              </button>
            </li>
          )}

          {!isEmbed && !isUserACommunityMember && (
            <>
              {configType &&
                configType === ConfigType.CanaryNetwork &&
                activeTab === EActiveTabState.Registry && (
                  <li
                    className={classnames({
                      [s.active]: activeTab === EActiveTabState.Registry,
                    })}>
                    <div
                      id="review-membership-element"
                      data-tooltip-html={ReactDOMServer.renderToString(
                        <div className={s.tooltipContent}>
                          <h3> 👈 Review Membership</h3>
                          <p>
                            You can add or remove already added members or edit their information
                            before sending invitations.
                          </p>
                        </div>,
                      )}>
                      <button
                        type="button"
                        onClick={() => handleTabChange(EActiveTabState.Registry)}
                        title="Members">
                        {t('navigation.MEMBERS')}
                      </button>
                    </div>
                    <Tooltip
                      anchorId="review-membership-element"
                      isOpen={
                        activeStep === 4 &&
                        activeTab === EActiveTabState.Registry &&
                        showInfoToolTip
                      }
                      place="right"
                      position={{ x: 350, y: 180 }}
                    />
                  </li>
                )}
              {configType &&
                configType === ConfigType.CanaryNetwork &&
                [EActiveTabState.Settings, EActiveTabState.MessagingPortal].includes(activeTab) && (
                  <li
                    className={classnames(s.membersWithConnectTooltip, {
                      [s.active]: false,
                    })}>
                    <>
                      <div
                        id="connect-community-element"
                        data-tooltip-html={ReactDOMServer.renderToString(
                          <div className={s.tooltipContent}>
                            <h3> 👈 Connect Community</h3>
                            <p>
                              After saving market settings, click on Members tab to proceed with
                              data connection
                            </p>
                          </div>,
                        )}>
                        <button
                          type="button"
                          onClick={() => handleTabChange(EActiveTabState.Registry)}
                          title="Members">
                          {t('navigation.MEMBERS')}
                        </button>
                      </div>
                    </>

                    <Tooltip
                      anchorId="connect-community-element"
                      isOpen={showConnectCommunityTooltip && showInfoToolTip}
                      place="right"
                      position={{ x: 170, y: 170 }}
                    />
                  </li>
                )}
              {configType && configType === ConfigType.Collaboration && (
                <li
                  className={classnames({
                    [s.active]: activeTab === EActiveTabState.Registry,
                  })}>
                  <button
                    type="button"
                    onClick={() => handleTabChange(EActiveTabState.Registry)}
                    title="Members">
                    {t('navigation.MEMBERS')}
                  </button>
                </li>
              )}
            </>
          )}
          {showResultsTab && (
            <li
              className={classnames(s.communityName, {
                [s.active]: activeTab === EActiveTabState.Community,
              })}
              title="Results">
              <button type="button" onClick={() => handleResultsTabClick()}>
                {t('RESULTS')}
              </button>
            </li>
          )}
          {showMessagingPortal && (
            <li
              className={classnames(s.communityName, s.withNotoficationBubble, {
                [s.active]: activeTab === EActiveTabState.MessagingPortal,
              })}
              title="MessagingPortal">
              <button type="button" onClick={() => handleMessagePortalTabClick()}>
                {t('MESSAGING_PORTAL')}
                {isUserACommunityMember && showUnreadCount && (
                  <div className={s.notificationsBubble}>
                    <span className={s.notificationsBubbleText}>{unreadMessageCount}</span>
                  </div>
                )}
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
