import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './Tabs.module.scss';
import { TTabsProps } from './Tabs.types';

export const Tabs = <T,>({
  tabs,
  activeTab,
  handleClick,
  className,
}: TTabsProps<T>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <nav className={classnames(s.navigation, className)}>
      <ul>
        {tabs.map((tab) => (
          <li
            key={String(tab)}
            className={classnames({
              [s.active]: activeTab === tab,
            })}>
            <button type="button" onClick={() => handleClick(tab)}>
              {t(String(tab))}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
