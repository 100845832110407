import { ELsKey, ls } from 'src/utils/localStorage';

export const downloadFile = async (filename: string): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_D3A_WEB_API_URL}downloads/download-static`;
    const url = `${endpoint}/${filename}`;
    const token = ls.get(ELsKey.TOKEN_AUTH)?.token;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Connection Error');
    }

    const blob = await response.blob();
    const link = document.createElement('a');
    const downloadUrl = window.URL.createObjectURL(blob);
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
