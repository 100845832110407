import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Copyright } from 'src/components/Copyright';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { ListMembersAndAssets } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import { MobileInfoArea } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/MobileInfoArea';
import { RegularNoResults } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/RegularNoResults';
import { SidebarSubTab } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/SidebarSubTab';
import { TSidebarSubTabItemList } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/SidebarSubTab/SidebarSubTab.types';
import { MessagingPortal } from 'src/components/MapSidebar/components/MessagingPortal';
import { UserProfile } from 'src/components/MapSidebar/components/UserProfile';
import { EDomIds } from 'src/constants/domSelectors';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { AdvancedSettingsForm } from 'src/features/Communities/EditCommunity/AdvancedSettingsForm';
import {
  BasicSettingsForm,
  TBasicSettingsFormProps,
} from 'src/features/Communities/EditCommunity/BasicSettingsForm';
import { GridMarketForm } from 'src/features/Communities/EditCommunity/GridMarketForm/GridMarketForm';
import { ManageManagers } from 'src/features/Managers';
import { SingleAreaResults } from 'src/features/Results/SingleAreaResults';
import { SingleCommunityResults } from 'src/features/Results/SingleCommunityResults';
import { ConfigType } from 'src/graphql';
import { ConfigurationOutput } from 'src/graphql';
import { useReadConfigurationQuery } from 'src/graphql';
import { useCurrentUserItemForSCM } from 'src/hooks/useCurrentUserItemForSCM';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import {
  selectCommunityNotFound,
  selectScreenMode,
} from 'src/redux/application/application.selectors';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { selectIsLoggedIn, selectTokenAuth, selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectCommunityName,
  selectConfigType,
  selectReadOnly,
  selectRootAssetUuid,
  selectSelectedAreaUuid,
  selectSimulationResults,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAreaUuid,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import {
  selectActiveSCMStep,
  selectActiveTabResults,
  selectDisableScmTabChanges,
  selectHideSidebarHeader,
  selectIsOperationalCommunity,
  selectScmSidebarTab,
  selectUserListItemClicked,
} from 'src/redux/scm/scm.selectors';
import {
  setActiveSCMStep,
  setDisableScmTabChanges,
  setPreventPushResultsScreen,
  setSCMsidebarTab,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EFormVariant, EUserRoles } from 'src/typings/base-types';
import { EScreenMode } from 'src/typings/configuration.types';
import { v4 } from 'uuid';

import s from './MapSidebarSingleCommunity.module.scss';
import {
  EActiveTabState,
  EResultsTab,
  TMapSidebarSingleCommunityProps,
} from './MapSidebarSingleCommunity.types';
import { MapSidebarSingleCommunityTabs } from './MapSidebarSingleCommunityTabs';

export const MapSidebarSingleCommunity: React.FC<TMapSidebarSingleCommunityProps> = ({
  onSettingsDataSave,
  hideResultsTab,
  onAssetValuesSavePromise,
  onAssetValuesSave,
  onChangeSettingsTab,
  onAssetRemove,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  showHeaderTabs = true,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { triggerResultsLossAlert } = useContext(ApplicationContext);
  const { mapService } = useContext(WorldMapContext);

  const userRole = useSelector(selectUserRole);

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const sidebarTab = useSelector(selectScmSidebarTab);
  const loggedIn = useSelector(selectIsLoggedIn);
  const communityName = useSelector(selectCommunityName);
  const hideSidebarHeader = useSelector(selectHideSidebarHeader);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const activeResultsTab = useSelector(selectActiveTabResults);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const userListItemClicked = useSelector(selectUserListItemClicked);
  const screenMode = useSelector(selectScreenMode);
  const disableScmTabChanges = useSelector(selectDisableScmTabChanges);
  const simulationResults = useSelector(selectSimulationResults);
  const configType = useSelector(selectConfigType);
  const communityNotFound = useSelector(selectCommunityNotFound);
  const readOnly = useSelector(selectReadOnly);
  const isOperationalTab = useSelector(selectIsOperationalCommunity);
  const simulationStatus = useSelector(selectSimulationStatus);
  const auth = useSelector(selectTokenAuth);
  const formHasErrorsRef = useRef(false);
  const formId = useRef(v4());
  const disableScmTabChangesRef = useRef(disableScmTabChanges);
  const hasConnectCommunityTooltipShown = useRef<boolean>(false);

  const { currentItem } = useCurrentUserItemForSCM();
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const { flyToAsset } = useFlyTo({ mapService });

  const [showConnectCommunityTooltip, setShowConnectCommunityTooltip] = useState<boolean>(false);
  // this check will be updated and used when the user is a community member
  // invited through the invitation link
  const [formVariant, setFormVariant] = useState(EFormVariant.Express);
  const [activeTab, setActiveTab] = useState<EActiveTabState>(
    isUserACommunityMember ? EActiveTabState.Profile : EActiveTabState.Settings,
  );

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const communityArea = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    return scenario?.children.find((child) => child.type === 'Area');
  }, [configuration]);
  const communityMembers = useMemo(() => {
    const members = configuration?.scenarioData?.homeInfo?.scmHomeDetails;
    return members;
  }, [configuration]);

  useEffect(() => {
    disableScmTabChangesRef.current = disableScmTabChanges;
  }, [disableScmTabChanges]);

  const handleTabChange = (value: EActiveTabState) => {
    if (activeTab === value) return;

    if (
      ((!isOperationalCommunity && simulationStatus !== 'running') || isOperationalCommunity) &&
      [EActiveTabState.Registry, EActiveTabState.Settings].includes(value)
    ) {
      dispatch(setPreventPushResultsScreen(true));
    }
    setActiveTab(value);
    const expandedTabs = [EActiveTabState.MessagingPortal, EActiveTabState.Community];

    dispatch(setSidebarExpanded(expandedTabs.includes(value)));

    if (sidebarTab === EActiveTabState.Registry && value !== EActiveTabState.Registry) {
      dispatch(setSCMsidebarTab(undefined));
    }
    if (isOperationalTab && !isUserACommunityMember) {
      dispatch(setActiveSCMStep(4));
      return;
    }

    if (value == EActiveTabState.Registry) dispatch(setActiveSCMStep(1));
    if (value == EActiveTabState.Settings) dispatch(setActiveSCMStep(2));
    if (value == EActiveTabState.Community) dispatch(setActiveSCMStep(3));
    if (sidebarTab === EActiveTabState.Settings && value !== EActiveTabState.Settings) {
      dispatch(setSCMsidebarTab(undefined));
    }
  };

  useEffect(() => {
    if (disableScmTabChangesRef.current) {
      dispatch(setDisableScmTabChanges(false));
      return;
    }

    const isCommunityTab =
      (sidebarTab === EActiveTabState.Community && simulationResults) || activeSCMStep === 3;
    const isCollaborationTab = sidebarTab === EActiveTabState.Settings || activeSCMStep === 2;
    const isBasicCollaborationTab = activeSCMStep === 0 && !isUserACommunityMember;
    const isRegistryTab = sidebarTab === EActiveTabState.Registry || activeSCMStep === 1;
    const isProfileTab = sidebarTab === EActiveTabState.Profile;
    const isSidebarExpanded = activeSCMStep !== 3;

    if (isCommunityTab && !userListItemClicked) {
      setActiveTab(EActiveTabState.Community);
      dispatch(setSidebarExpanded(true));

      if (userRole === EUserRoles.Admin) {
        dispatch(setSelectedAreaUuid(communityArea?.uuid));
      } else {
        const member = communityMembers?.find((member) => member?.email === auth?.email);
        dispatch(setSelectedAreaUuid(String(member?.uuid)));
      }
    }
    if (isCollaborationTab || isBasicCollaborationTab) {
      setActiveTab(EActiveTabState.Settings);
    }
    if (isRegistryTab) {
      setActiveTab(EActiveTabState.Registry);
    }
    if (isProfileTab) {
      setActiveTab(EActiveTabState.Profile);
    }

    if (isSidebarExpanded) {
      //dispatch(setSidebarExpanded(false));
    }
  }, [
    sidebarTab,
    currentItem,
    activeSCMStep,
    isUserACommunityMember,
    dispatch,
    simulationResults,
    userListItemClicked,
    communityArea,
    userRole,
    auth?.email,
    communityMembers,
  ]);

  const SettingsSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Collaboration Basic Settings',
        onClick: () => setFormVariant(EFormVariant.Express),
        label: t('navigation.BASIC'),
        isActive: () => formVariant === EFormVariant.Express,
      },
      {
        liTitle: 'Collaboration Advanced Settings',
        onClick: () => setFormVariant(EFormVariant.Advanced),
        label: t('navigation.ADVANCED'),
        isActive: () => formVariant === EFormVariant.Advanced,
      },
      {
        liTitle: 'Grid Market Settings',
        onClick: () => {
          setFormVariant(EFormVariant.GridMarket);
        },
        label: t('navigation.PRICING'),
        isActive: () => formVariant === EFormVariant.GridMarket,
      },
      {
        liTitle: 'Community Managers',
        onClick: () => {
          setFormVariant(EFormVariant.CommunityManagers);
        },
        label: t('navigation.COMMUNITY_MANAGERS'),
        isActive: () => formVariant === EFormVariant.CommunityManagers,
      },
    ],
    [formVariant, setFormVariant, t],
  );

  const handleSettingsDataSave: TBasicSettingsFormProps['onSubmit'] = async (payload) => {
    await triggerResultsLossAlert();
    onSettingsDataSave?.(payload);
  };

  const handleResultsTabClick = () => {
    handleTabChange(EActiveTabState.Community);
    dispatch(setSidebarExpanded(true));
    if (hideResultsTab) hideResultsTab(true);
  };

  const handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'] = async (
    payload,
  ): Promise<boolean> => {
    await triggerResultsLossAlert();

    return onAssetValuesSavePromise
      ? onAssetValuesSavePromise(payload)
      : new Promise((resolve) => resolve(false));
  };

  const renderSettingsTabsContent = (tab) => {
    switch (tab) {
      case EFormVariant.Express:
        return (
          <BasicSettingsForm
            className={s.formMaxHeight}
            id={formId.current}
            hasErrorsRef={formHasErrorsRef}
            onSubmit={handleSettingsDataSave}
            disableLocationField={false}
            theme="dark"
            isStartEndDateDisabled={isOperationalTab}
          />
        );
      case EFormVariant.Advanced:
        return <AdvancedSettingsForm />;
      case EFormVariant.GridMarket:
        return (
          <GridMarketForm
            handleAssetValuesSavePromise={handleAssetValuesSavePromise}
            hostAssetUuid={rootAssetUuid ?? ''}
            theme="dark"
            formId={formId}
            readOnly={readOnly}
          />
        );
      case EFormVariant.CommunityManagers:
        return <ManageManagers />;
    }
  };

  useEffect(() => {
    if (isOperationalTab && simulationStatus === 'running') {
      setTimeout(() => {
        dispatch(setActiveSCMStep(4));
        setActiveTab(EActiveTabState.Community);
        dispatch(setSidebarExpanded(true));
      }, 1000);
    }
  }, [simulationStatus, isOperationalTab, dispatch]);

  useEffect(() => {
    if (activeTab == EActiveTabState.Profile) dispatch(setSelectedAssetUuid(currentItem?.uuid));
    onChangeSettingsTab?.(activeTab);
  }, [activeTab, onChangeSettingsTab, currentItem, dispatch]);

  useEffect(() => {
    dispatch(
      setSCMsidebarTab(isUserACommunityMember ? EActiveTabState.Profile : EActiveTabState.Settings),
    );
  }, [isUserACommunityMember, dispatch]);

  useEffect(() => {
    if (selectedAreaUuid) flyToAsset(selectedAreaUuid, { specificZoom: 18 });
  }, [flyToAsset, selectedAreaUuid]);

  useEffect(() => {
    if (activeTab) {
      hasConnectCommunityTooltipShown.current = false;
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      configType === ConfigType.CanaryNetwork &&
      activeTab === EActiveTabState.Settings &&
      activeSCMStep === 4 &&
      formVariant === EFormVariant.Express &&
      hasConnectCommunityTooltipShown.current === false
    ) {
      hasConnectCommunityTooltipShown.current = true;
      setShowConnectCommunityTooltip(true);
      return;
    }
    setShowConnectCommunityTooltip(false);
  }, [activeSCMStep, activeTab, configType, formVariant]);

  // TODO::this check needs to be updated once we have live results flag from BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showLiveResultsIndidcator = useMemo(() => configType !== ConfigType.Collaboration, [
    configType,
  ]);

  return (
    <>
      <div
        className={s.container}
        id={EDomIds.MODAL_MAP_SIDEBAR}
        style={{
          pointerEvents: 'auto',
        }}>
        <>
          {showHeaderTabs && (
            <>
              {!loggedIn && (
                <div className={s.header}>
                  <h3>{communityName}</h3>
                </div>
              )}
              {loggedIn && !hideSidebarHeader && (
                <MapSidebarSingleCommunityTabs
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                  showConnectCommunityTooltip={showConnectCommunityTooltip}
                  handleResultsTabClick={handleResultsTabClick}
                />
              )}
            </>
          )}
          {/*
            Used conditional rendering instead of switching "display: none",
            because there is some issues with d3 charts when they are not visible.
            https://gridsingularity.atlassian.net/browse/PH-464
        */}
          {(() => {
            switch (activeTab) {
              case EActiveTabState.MessagingPortal:
                return (
                  <div className={classNames(s.graphsList, s.scmMessagingPortal)}>
                    <MessagingPortal
                      hostAssetUuid={rootAssetUuid ?? ''}
                      theme="dark"
                      formId={formId}
                      readOnly={readOnly}
                    />
                    {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                      <Copyright mode="mobileRelative" />
                    )}
                  </div>
                );
              case EActiveTabState.Community:
              case EActiveTabState.GridMarketResults: {
                if (simulationStatus === undefined) {
                  return (
                    <div className={s.graphsList}>
                      <RegularNoResults communityNotFound={communityNotFound} />
                    </div>
                  );
                }
                return (
                  <div className={classNames(s.graphsList, s.scmResultsContainer)}>
                    {((isUserACommunityMember && activeResultsTab === EResultsTab.Home) ||
                      (!isUserACommunityMember && selectedAreaUuid !== communityArea?.uuid)) && (
                      <SingleAreaResults />
                    )}
                    {((isUserACommunityMember && activeResultsTab === EResultsTab.Community) ||
                      (!isUserACommunityMember && selectedAreaUuid === communityArea?.uuid)) && (
                      <SingleCommunityResults />
                    )}
                    {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                      <Copyright mode="mobileRelative" />
                    )}
                  </div>
                );
              }
              case EActiveTabState.Registry:
                return (
                  <ListMembersAndAssets
                    onAddNewAsset={onAddNewAsset}
                    onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
                    onAssetValuesSave={onAssetValuesSave}
                    onAssetRemove={onAssetRemove}
                    onAssetValuesSavePromise={onAssetValuesSavePromise}
                  />
                );
              case EActiveTabState.Profile:
                return (
                  <div className={s.profile}>
                    <UserProfile
                      onAssetValuesSave={onAssetValuesSave}
                      onAssetValuesSavePromise={onAssetValuesSavePromise}
                    />
                    {screenMode === EScreenMode.Mobile && (
                      <MobileInfoArea onClick={handleResultsTabClick} />
                    )}
                    {isUserACommunityMember && screenMode === EScreenMode.Mobile && (
                      <Copyright mode="mobileRelative" />
                    )}
                  </div>
                );

              case EActiveTabState.Settings:
                return (
                  <>
                    <SidebarSubTab tabs={SettingsSubtabs} />
                    <div className={s.settingsList}>
                      <div className={s.formWrapper}>{renderSettingsTabsContent(formVariant)}</div>
                    </div>
                  </>
                );
            }
          })()}
        </>
      </div>
    </>
  );
};
