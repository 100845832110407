import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { LocationSearch } from 'src/components/LocationSearch';
import { TUserTileShowDataProps } from 'src/features/Members/SingleMember/SingleMember.types';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';

import s from './SingleMember.module.scss';

export const UserTileShowData: React.FC<TUserTileShowDataProps> = ({
  name,
  email,
  address,
  //zipCode,
  setFormData,
  onSubmit,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const formOnchange = useCallback(
    ({ name, value }) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setFormData],
  );
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const handleSubmit = useCallback(() => {
    onSubmit();
    setIsEdit(false);
  }, [onSubmit]);

  return (
    <>
      <div className={classNames(s.name, s.textContainer)}>{name}</div>
      <div className={classNames(s.email, s.textContainer)}>{email}</div>
      {isEdit ? (
        <div className={classNames(s.address, s.textContainer)}>
          <LocationSearch
            iconLeft={null}
            theme="line-dark"
            name="address"
            label="Address"
            value={address}
            onChange={(data) => {
              formOnchange({ name: 'address', value: data.place_name });

              const { text: postcode } = data.context[0];
              formOnchange({ name: 'zipCode', value: postcode || '' });
            }}
            className={s.locationSearch}
            elevateLabel={true}
          />
          <BaseButton type="button" className={s.OKButton} onClick={handleSubmit}>
            OK
          </BaseButton>
        </div>
      ) : (
        <div
          className={classNames(s.address, s.textContainer)}
          onClick={() => {
            if (!isUserACommunityMember) setIsEdit(true);
          }}>
          <BaseIcon size={14} icon="pin-outline" className={s.iconMap} />
          {address ? `${address}` : 'Add Address'}
        </div>
      )}
    </>
  );
};
