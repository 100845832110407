import React, { useMemo } from 'react';

import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { DatePickerCustomHeader } from 'src/components/BaseDateRangePicker/DatePickerCustomHeader';
import { useLaunchDateTime } from 'src/hooks/useLaunchDateTime';
import {
  selectResultsEndTime,
  selectResultsStartTime,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsOperationalCommunity,
  selectSelectedDailyIndicator,
} from 'src/redux/scm/scm.selectors';
import { setSelectedDailyIndicator } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import s from './DailyResultsIndicator.module.scss';

export const DailyResultsIndicator: React.FC = () => {
  const [isCalendarOpened, setIsCalendarOpened] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const selectedDate = useSelector(selectSelectedDailyIndicator);
  const resultsStartTime = useSelector(selectResultsStartTime);
  const resultsEndTime = useSelector(selectResultsEndTime);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);

  const { launchDatetime } = useLaunchDateTime();
  const handleChange = (date: Date) => dispatch(setSelectedDailyIndicator(date.getTime()));

  const RESULTFORMAT = useMemo(() => BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME, []);

  const yesterdayTimeForResults = useMemo(
    () => UTCMoment.fromBackend(Date.now()).subtract(3, 'day').format(RESULTFORMAT),
    [RESULTFORMAT],
  );

  const minDate = useMemo(() => {
    if (isOperationalCommunity && launchDatetime) return UTCMoment.utc(launchDatetime).toDate();
    return UTCMoment.utc(resultsStartTime).toDate();
  }, [resultsStartTime, isOperationalCommunity, launchDatetime]);

  const maxDate = useMemo(() => {
    if (isOperationalCommunity) return UTCMoment.utc(yesterdayTimeForResults).toDate();
    return UTCMoment.utc(resultsEndTime).toDate();
  }, [resultsEndTime, isOperationalCommunity, yesterdayTimeForResults]);

  return (
    <div
      className={classNames({
        [s.container]: true,
        [s.themeLight]: true,
        [s.calendarOpened]: !!isCalendarOpened,
        [s.startCalendarActive]: isCalendarOpened,
        themeLight: true,
      })}>
      <div className={s.pickers}>
        <DatePicker
          className={s.datepickerInput}
          selected={(selectedDate && UTCMoment.utc(selectedDate)?.toDate()) || maxDate || undefined}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(d) => handleChange(d as Date)}
          dateFormat="dd MMMM yyyy"
          disabled={false}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={'placeholderText'}
          renderCustomHeader={DatePickerCustomHeader}
          onCalendarOpen={() => {
            setIsCalendarOpened(true);
          }}
          onCalendarClose={() => {
            setIsCalendarOpened(false);
          }}
        />
      </div>
    </div>
  );
};
