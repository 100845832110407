import React from 'react';

import { InputField } from './InputField';
import s from './InputField.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InputFieldWithLabel: React.FC<any> = (props: any) => {
  const { label, onClick, ...rest } = props;
  return (
    <div className={s.inputFieldWithLabel} onClick={onClick}>
      <header>
        <h3 className={s.label}>{label}</h3>
      </header>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      <InputField {...rest} fieldType={props.type || props.fieldType} />
    </div>
  );
};
