import React, { useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { TDownloadResultsButtonProps } from 'src/features/Results/DashboardHeader/CustomResultHeader/CustomResultHeader.types';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { selectActiveConfigurationJobUuid } from 'src/redux/configuration/configuration.selectors';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import s from './CustomResultHeader.module.scss';

export const DownloadResultsButton: React.FC<TDownloadResultsButtonProps> = ({
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const { triggerDownloadAlert } = useContext(ApplicationContext);

  const resultsDownloadAPI = useDownloadAPI({
    type: 'simulation-results',
    resultsUuid: activeConfigurationJobUuid,
    startDate: UTCMoment.fromBackend(startDate, BACKEND_DATE_FORMATS.SETTINGS_DATA)
      .startOf('day')
      .format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
    endDate: UTCMoment.fromBackend(endDate, BACKEND_DATE_FORMATS.SETTINGS_DATA)
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
  });

  const showDownloadMessage = async (message) => {
    await triggerDownloadAlert(message);
  };

  return (
    <BaseButton
      className={classNames(s.settingsActionButton, s.settingsActionButtonBorder)}
      onClick={async () => {
        await resultsDownloadAPI.handleDownload(showDownloadMessage);
      }}
      isLoading={resultsDownloadAPI.loading}
      icon={'download'}>
      {t('commands.DOWNLOAD_RESULTS')}
    </BaseButton>
  );
};
