import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useAvailableUserRolesLazyQuery } from 'src/graphql';
import { setAvailableUserRoles } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';

type TPageEffectsContainer = {
  children: React.ReactNode;
};

const PageEffectsContainer: React.FC<TPageEffectsContainer> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [fetchAvailableUserRoles] = useAvailableUserRolesLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ availableUserRoles }) => {
      const roles = availableUserRoles!.userRoles!.map((item) => ({
        roleName: getFrontendUserRoleName(item!.roleName!),
        value: item!.value!,
      }));

      dispatch(setAvailableUserRoles(roles || []));
    },
  });

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchAvailableUserRoles();
  }, [fetchAvailableUserRoles, isLoggedIn]);

  return <>{children}</>;
};
export default PageEffectsContainer;
