import { LngLatBoundsLike } from 'mapbox-gl';
import { TAssetsInside } from 'src/components/ModalAssetsManager';
import { SpotMarketType } from 'src/graphql';
import { TAssetType, TLibrariesTags } from 'src/typings/base-types';
import { TEnumFieldTemplate } from 'src/utils/assetsFields/assetsFields.types';
import { mergedTimezones } from 'src/utils/mergedTimezones';

export const GRID_MARKET_ASSET_TYPES: Readonly<TAssetType[]> = ['MarketMaker', 'InfiniteBus'];

export const ICON_BY_ASSET_TYPE: Readonly<
  { [key in Exclude<TAssetType, 'InfiniteBus' | 'MarketMaker'>]: TAssetsInside[0]['icon'] }
> = {
  Area: 'house',
  FiniteDieselGenerator: 'factory',
  PV: 'pv',
  Load: 'plug',
  Storage: 'battery',
  ScmStorage: 'battery',
  HeatPump: 'heatpump',
  ScmHeatPump: 'heatpump',
};

export const GENERATION_PROFILE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: 4, label: 'labels.UPLOAD_PROFILE' },
  { value: 5, label: 'labels.LOCAL_GENERATION_PROFILE' },
];

export const SPOT_MARKET_TYPE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: SpotMarketType.OneSided, label: 'One-sided' },
  { value: SpotMarketType.TwoSided, label: 'Two-sided' },
];

export const CURRENCY_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: 'USD', label: '🇺🇸 US Dollar' },
  { value: 'EUR', label: '🇪🇺 Euro' },
  { value: 'JPY', label: '🇯🇵 Japanese Yen' },
  { value: 'GBP', label: '🇬🇧 Pound Sterling' },
  { value: 'AUD', label: '🇦🇺 Australian Dollar' },
  { value: 'CAD', label: '🇨🇦 Canadian Dollar' },
  { value: 'CHF', label: '🇨🇭 Swiss Franc' },
  { value: 'CNY', label: '🇨🇳 Chinese Renminbi' },
];

export const ZOOM = {
  ADD_ASSET: 4,
  QUIT_COMMUNITY: 6,
};

export const DEFAULT_COMMUNITY_NAME = 'Phoenix Community';

export const GRID_MARKET_MODE = 'GRID_MARKET_MODE';

export const LIBRARY_FILTERS_MAPPING = {
  All: ['DefaultLib'] as TLibrariesTags[],
  Custom: ['CustomAsset', 'DefaultLib'] as TLibrariesTags[],
  Area: ['Home', 'DefaultLib'] as TLibrariesTags[],
  PV: ['PV', 'DefaultLib'] as TLibrariesTags[],
  Load: ['Load', 'DefaultLib'] as TLibrariesTags[],
  Storage: ['Storage', 'DefaultLib'] as TLibrariesTags[],
  HeatPump: ['HeatPump', 'DefaultLib'] as TLibrariesTags[],
};

export const NET_ENERGY_KEY = 'Net energy';

export const SWITCHER_ICON_SIZES = {
  tick: 16,
  close: 10,
};

export const START_LOCATION: LngLatBoundsLike = [
  [-70, 70],
  [70, -70],
];

export const PASSWORD_MIN_LENGTH = 8;

export const TIMEZONE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = mergedTimezones;
export const LANGUAGE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'English (Default)',
    value: 1,
  },
  {
    label: 'German',
    value: 2,
  },
  {
    label: 'Spanish',
    value: 3,
  },
];

export const GRID_MARKET_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'General Market',
    value: 1,
  },
  {
    label: 'Variable Grid Market',
    value: 2,
  },
  {
    label: 'Positive Grid Market',
    value: 3,
  },
];

export const MOBILE_WIDTH = 768;
