import React, { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { TCreateMonthObjectResult } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/MonthlyResultsIndicatorSCM/MonthlyResultsIndicatorSCM.helpers';
import { useLaunchDateTime } from 'src/hooks/useLaunchDateTime';
import {
  selectConfigType,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsOperationalCommunity,
  selectSelectedMonthlyIndicator,
} from 'src/redux/scm/scm.selectors';
import { setSelectedMonthlyIndicator } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { UTCMoment } from 'src/utils/UTCMoment';

import { createMonthObjectsArray } from './MonthlyResultsIndicatorSCM.helpers';
import s from './MonthlyResultsIndicatorSCM.module.scss';
import { TMonthlyResultsIndicatorSCMProps } from './MonthlyResultsIndicatorSCM.types';

export const MonthlyResultsIndicatorSCM: React.FC<TMonthlyResultsIndicatorSCMProps> = () => {
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);
  //const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);

  const configType = useSelector(selectConfigType);
  const resultsStartTime = useSelector(selectResultsStartTime);
  const resultsEndTime = useSelector(selectResultsEndTime);
  const status = useSelector(selectSimulationStatus);
  const selectedMonthlyIndicator = useSelector(selectSelectedMonthlyIndicator);

  const { launchDatetime } = useLaunchDateTime();

  const isCN = configType === 'CANARY_NETWORK';

  const update = (item: TCreateMonthObjectResult) => {
    //setSelectedMonthIndex(index);
    dispatch(setSelectedMonthlyIndicator(item));
    // dispatch(setResultsStartTime(startUtc.toISOString()));
    // dispatch(setResultsEndTime(endUtc.toISOString()));
    setOpened(false);
  };

  const monthList = useMemo(() => {
    const startDate = isOperationalCommunity && launchDatetime ? launchDatetime : resultsStartTime;
    const endDate = isOperationalCommunity ? Date.now() : resultsEndTime;

    return createMonthObjectsArray(
      UTCMoment.utc(startDate),
      UTCMoment.utc(endDate),
      status,
    ).reverse();
  }, [launchDatetime, status, isOperationalCommunity, resultsStartTime, resultsEndTime]);

  const selectedMonthIndex = useMemo(() => {
    if (!monthList || !selectedMonthlyIndicator) return 0;

    const selectedIndex = monthList.findIndex(
      (item) => item.title === selectedMonthlyIndicator.title,
    );

    return selectedIndex > 0 ? selectedIndex : 0;
  }, [selectedMonthlyIndicator, monthList]);

  return (
    <div
      className={classNames(s.container, {
        [s.opened]: opened,
      })}>
      <div className={s.valueButton} onClick={() => setOpened(!opened)}>
        <BaseButtonSquare icon="time" theme="flat-blue" iconSize={13} className={s.square} />
        <p>
          {isCN && selectedMonthlyIndicator?.isCurrent && <strong>Current - </strong>}
          {monthList[selectedMonthIndex]?.title}{' '}
        </p>
        <div className={s.icon}>
          <BaseIcon icon="arrow" size={10} />
        </div>
      </div>
      <div className={s.dropdown}>
        {monthList.map((item, i) => (
          <button key={i} type="button" onClick={() => update(item)}>
            <span></span> {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};
