import { store } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';

const DEFAULT_SUCCESS_MESSAGE = 'Saved';
const DEFAULT_ERROR_MESSAGE = 'Error';

export const dispatchSuccessToast = (message: string = DEFAULT_SUCCESS_MESSAGE): void => {
  store.dispatch(
    openToast({
      message,
      type: 'success',
    }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const dispatchErrorToast = (error: any): void => {
  let message;
  try {
    if (error.message) {
      if (typeof error.message === 'string') {
        message = error.message;
      } else {
        message = JSON.parse(error.message);
      }
    }
  } catch {
    message = DEFAULT_ERROR_MESSAGE;
  }

  store.dispatch(
    openToast({
      message: message,
      type: 'error',
    }),
  );
};
