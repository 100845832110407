import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import { ConfigType, ScmAdvancedSettingsOutput } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

const FIELD_NAME_TO_LABEL = {
  [ConfigType.Collaboration]: {
    marketMakerRate: 'Selling rate',
    feedInTariff: `Buying rate`,
  },
  [ConfigType.CanaryNetwork]: {
    marketMakerRate: `Current Selling rate`,
    feedInTariff: `Current Buying rate`,
  },
};

const FIELD_NAME_TO_TOOLTIP = {
  [ConfigType.Collaboration]: {
    marketMakerRate:
      'Selling rate is a constant price at which the community buys energy deficit from the external grid (utility)',
    feedInTariff: `Buying rate is a constant price at which the external grid (utility) buys surplus from the community`,
  },
  [ConfigType.CanaryNetwork]: {
    marketMakerRate: `Current Selling rate is the price at which the community buys energy deficit from the external grid (utility) at this moment (${new Date().toLocaleString()})`,
    feedInTariff: `Current Buying rate is the price at which the external grid (utility) buys surplus from the community at this moment (${new Date().toLocaleString()})`,
  },
};

export type TInfiniteBusArgs = {
  configType: ConfigType;
  advancedSettings: ScmAdvancedSettingsOutput;
};

export const InfiniteBus = ({
  configType,
  advancedSettings,
}: TInfiniteBusArgs): TFieldsTemplateUnion[] => {
  return [
    {
      name: 'marketMakerRate',
      type: 'number',
      label: FIELD_NAME_TO_LABEL[configType].marketMakerRate,
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridAgent,
      tooltipText: FIELD_NAME_TO_TOOLTIP[configType].marketMakerRate,
      showTooltip: true,
    },
    {
      name: 'feedInTariff',
      type: 'number',
      label: FIELD_NAME_TO_LABEL[configType].feedInTariff,
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridAgent,
      tooltipText: FIELD_NAME_TO_TOOLTIP[configType].feedInTariff,
      showTooltip: true,
    },
    {
      name: 'contractedPowerMonthlyFee',
      type: 'number',
      label: 'labels.CONTRACTED_POWER_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableContractedPowerMonthlyFee,
    },
    {
      name: 'contractedPowerCargoMonthlyFee',
      type: 'number',
      label: 'labels.CONTRACTED_POWER_CARGO_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableContractedPowerCargoMonthlyFee,
    },
    {
      name: 'energyCargoFee',
      type: 'number',
      label: 'labels.ENERGY_CARGO_FEE',
      unit: '€ / kWh',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableEnergyCargoFee,
    },
    {
      name: 'gridFeeEnabled',
      type: 'switcher',
      options: [
        { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
        { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
      ],
      label: 'Grid Fees',
      formView: EFormVariant.GridMarket,
      tooltipText: 'Specify the grid fees charged to trade in this market',
      showTooltip: true,
    },
    {
      name: 'gridImportFee',
      type: 'number',
      label: 'Grid Import Fee Value',
      unit: 'cents / kWh',
      formView: EFormVariant.GridMarket,
      exclude: !advancedSettings.enableGridImportFeeConst,
    },
    {
      name: 'taxesSurcharges',
      type: 'number',
      label: 'labels.ELECTRICITY_TAX',
      unit: '%',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableTaxesSurcharges,
    },
    {
      name: 'electricityTax',
      type: 'number',
      label: 'labels.ELECTRICITY_TAX',
      unit: '%',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableElectricityTax,
    },
    {
      name: 'fixedMonthlyFee',
      type: 'number',
      label: 'Service Fee',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableFixedMonthlyFee,
    },
    {
      name: 'assistanceMonthlyFee',
      type: 'number',
      label: 'Assistance Fee',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableAssistanceMonthlyFee,
    },
    {
      name: 'marketplaceMonthlyFee',
      type: 'number',
      label: 'Marketplace Fee',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableMarketplaceMonthlyFee,
    },
    {
      name: 'serviceMonthlyFee',
      type: 'number',
      label: 'bill_components.SERVICE_MONTHLY_FEE',
      unit: '€ / month',
      defaultValue: 0,
      formView: EFormVariant.GridMarketEnergy,
      exclude: !advancedSettings.enableServiceMonthlyFee,
    },
  ];
};
