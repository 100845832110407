import 'react-tooltip/dist/react-tooltip.css';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { TCanaryUserData } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import { ECommunityTabs } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import {
  TCanaryRegistryListFilters,
  TRegistryListProps,
  TRegistryUsersListProps,
} from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList';
import { RegistryListHeader } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList/RegistryListHeader';
import { RegistryUsersList } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList/RegistryUsersList';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { ListCommunityAssets } from 'src/features/Assets/ListAssets/ListCommunityAssets';
import { AreaUuidApplyMapping, UserRequestsStatusOutput } from 'src/graphql';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { setSelectedMember } from 'src/redux/application/application.slice';
import { selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetsTreeRelations,
} from 'src/redux/configuration/configuration.selectors';
import {
  setCreatedSCMMemberUUID,
  setSelectedAssetUuid,
  setSelectedAreaUuid,
} from 'src/redux/configuration/configuration.slice';
import {
  selectActiveSCMStep,
  selectIsOperationalCommunity,
  selectMeasurementRequests,
  selectCommunityMembers,
} from 'src/redux/scm/scm.selectors';
import { setUserListItemClicked } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EUserRoles } from 'src/typings/base-types';

import s from './RegistryList.module.scss';

export const CanaryRegistryList: React.FC<TRegistryListProps> = ({
  assetsData,
  usersToAssetRelations,
  setAssetView,
  onAssetRemove,
  setAssetsList,
  invitationEmails,
  activeTab,
  setActiveTab,
  wrapperTop,
}) => {
  const dispatch = useAppDispatch();

  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const assetsTree = useSelector(selectAssetsTreeRelations);
  const communityMembers = useSelector(selectCommunityMembers);
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const isOperational = useSelector(selectIsOperationalCommunity);
  const measurementRequests = useSelector(selectMeasurementRequests);

  const { mapService } = useContext(WorldMapContext);

  const [activeFilter, setActiveFilter] = useState<TCanaryRegistryListFilters>('All');
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isSelectAllTriggered, setIsSelectAllTriggered] = useState<boolean>(false);

  const scmMembersData = useMemo<TCanaryUserData[]>(() => {
    return communityMembers.map((member) => ({
      uuid: `${member?.uuid}`,
      name: member.email,
      subtitle: member.name,
      avatarUrl: undefined,
      requestStatus: UserRequestsStatusOutput.NotRequested,
      canaryInvitations: [],
      isAggregator: false,
      isGridOperator: false,
      aggregatorInformation: undefined,
    }));
  }, [communityMembers]);

  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });
  const showInfoToolTip = useMemo(
    () => measurementRequests && measurementRequests.length > 0 && userRole !== EUserRoles.Admin,
    [measurementRequests, userRole],
  );
  const showApplyButton = useMemo(() => {
    const availableAreSelected = selectedItems.filter((uuid) => {
      // Check if user already applied or approved for selected asset
      if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
        return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
      }
      return true;
    });
    return (
      activeTab === ECommunityTabs.CommunityAssets &&
      userRole === EUserRoles.Aggregator &&
      availableAreSelected.length > 0
    );
  }, [activeTab, selectedItems, userName, userRole, usersToAssetRelations]);

  const toggleTileSelection = (value: string) => {
    if (selectedItems.includes(value)) {
      setSelectedItems([...selectedItems].filter((name) => name !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const onUserItemClick: TRegistryUsersListProps['onItemClick'] = (data) => {
    const { name, subtitle } = data;
    toggleTileSelection(name);

    if (data.uuid) {
      dispatch(setSelectedAssetUuid(data.uuid));
      dispatch(setSelectedAreaUuid(data.uuid));
      dispatch(setCreatedSCMMemberUUID(data.uuid));
      dispatch(setUserListItemClicked(true));
      dispatch(setSelectedMember(data.uuid));
      flyToAsset(data.uuid, assetFlyConfig);

      setAssetView(true);
      setAssetsList({
        data: {
          ...data,
          ...data.assignedAssets,
          name: subtitle,
          owners: [],
        },
        children: data.assignedAssets?.children,
      });
    }
  };

  const onAssetItemClick = (value) => {
    toggleTileSelection(value.uuid);

    if (value.uuid) {
      dispatch(setSelectedMember(value.uuid));
      dispatch(setSelectedAssetUuid(value.uuid));
      dispatch(setSelectedAreaUuid(value.uuid));
      dispatch(setCreatedSCMMemberUUID(value.uuid));
      flyToAsset(value.uuid, assetFlyConfig);
      setAssetView(true);
      setAssetsList(value);
    }
  };

  const getParentUuid = useCallback(
    (assetUuid: string) => {
      return Object.keys(assetsTree).find((key) => assetsTree[key].includes(assetUuid));
    },
    [assetsTree],
  );

  useEffect(() => {
    setSelectedItems([]);
  }, [activeTab]);

  return (
    <div className={s.container}>
      <Tooltip
        anchorId="manage-anchor-element"
        isOpen={
          activeTab === ECommunityTabs.CommunityMembers && activeSCMStep !== 4 && showInfoToolTip
        }
        position={{ x: 350, y: 420 - wrapperTop }}
      />
      <div
        id="manage-anchor-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <h3> 👈 Manage members </h3>
            <p>Here you can add or remove already added members or edit their information.</p>
          </div>,
        )}
        data-tooltip-place="right">
        <Tooltip
          anchorId="manage-member-element"
          isOpen={activeTab === ECommunityTabs.CommunityAssets && isOperational && showInfoToolTip}
          position={{ x: 350, y: 550 - wrapperTop }}
        />
        <div
          id="manage-member-element"
          data-tooltip-html={ReactDOMServer.renderToString(
            <div className={s.tooltipContent}>
              <h3> 👈 Click and connect</h3>
              <p>Click to connect single member assets</p>
            </div>,
          )}
          data-tooltip-place="right"></div>
        <RegistryListHeader
          activeTab={activeTab}
          setActiveTab={(val) => {
            setActiveTab(val);
            setIsSelectAllTriggered(false);
          }}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isSelectAllTriggered={isSelectAllTriggered}
          showApplyButton={showApplyButton}
          onClickApplyButton={() => {
            const applicationMapping: AreaUuidApplyMapping[] = selectedItems
              .filter((uuid) => {
                if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
                  return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
                }
                return true;
              })
              .map((uuid) => ({
                areaUuid: uuid,
                apply: true,
              }));

            if (configUuid)
              canaryNetworkServiceContext.applyForExternalConnection(
                applicationMapping,
                configUuid,
              );
          }}
        />
      </div>
      <div>
        {activeTab === ECommunityTabs.CommunityMembers && (
          <RegistryUsersList
            assetsData={assetsData}
            usersData={[...invitationEmails, ...scmMembersData]}
            usersToAssetRelations={usersToAssetRelations}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onUserItemClick}
            getParentUuid={getParentUuid}
          />
        )}
        {activeTab === ECommunityTabs.CommunityAssets && (
          <ListCommunityAssets
            usersData={[...invitationEmails, ...scmMembersData]}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onAssetItemClick}
            onAssetRemove={onAssetRemove}
          />
        )}
      </div>
    </div>
  );
};
