import React, { useContext, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { EditMember } from 'src/features/Members/EditMember';
import { MemberFormWrapper } from 'src/features/Members/SingleMember/MemberForm/MemberFormWrapper';
import { UserTileShowData } from 'src/features/Members/SingleMember/UserTileShowData';
import {
  useReadConfigurationQuery,
  ConfigurationOutput,
  ScmCommunityMember,
  ReadConfigurationDocument,
} from 'src/graphql';
import { useRemoveCommunityMemberMutation } from 'src/graphql';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { selectSelectedMember } from 'src/redux/application/application.selectors';
import { setSelectedMember } from 'src/redux/application/application.slice';
import { setSidebarMode } from 'src/redux/application/application.slice';
import {
  selectReadOnly,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAssetUuid,
  setSelectedAreaUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { setIsAppLoading } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import { AssetsCard } from './AssetsCard';
import s from './SingleMember.module.scss';
import { TSingleMemberProps } from './SingleMember.types';
export enum EAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export const SingleMember: React.FC<TSingleMemberProps> = ({
  assetsList,
  onAssetClick,
  onAddAssetClick,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mapService } = useContext(WorldMapContext);

  const { zoomIntoConfiguration } = useConfigurationUtils();
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const readOnly = useSelector(selectReadOnly);
  const isAppLoading = useSelector(selectIsAppLoading);
  const selectedMember = useSelector(selectSelectedMember);
  const assetsValues = useSelector(selectAssetsValues);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const areaUuid = useSelector(selectSelectedAreaUuid);
  const [action, setAction] = useState<EAction>(EAction.VIEW);
  console.log('selectedMember', selectedMember);
  console.log('areaUuid', areaUuid);
  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const area = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    const communityArea = scenario?.children.find((child) => child.type === 'Area');
    return communityArea?.children?.find((area) => area.uuid === selectedMember);
  }, [configuration, selectedMember]);
  const assets = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return area?.children?.map((asset) => objectCamelCase<any>(asset));
  }, [area]);
  const member: ScmCommunityMember | undefined = useMemo(
    () =>
      (configuration?.scenarioData?.homeInfo?.scmHomeDetails?.find(
        (member) => member?.uuid === selectedMember,
      ) ?? undefined) as ScmCommunityMember | undefined,
    [configuration, selectedMember],
  );

  const [removeCommunityMemberMutation] = useRemoveCommunityMemberMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: () => {
      dispatch(setIsAppLoading(false));
      dispatchSuccessToast();
      onClickAllMembers();
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  useEffect(() => {
    if (isUserACommunityMember && mapService) {
      let isBusy = true;
      const customListener = setInterval(() => {
        isBusy =
          mapService.map.isEasing() || mapService.map.isMoving() || mapService.map.isRotating();
        if (isBusy === false) {
          clearInterval(customListener);
          mapService.set3DView(true);
        }
      }, 1000);
    }
  }, [isUserACommunityMember, mapService]);

  const handleAssetClick = (data) => {
    onAssetClick?.(assetsList);
    dispatch(setSelectedAssetUuid(data.uuid));
    dispatch(setSelectedAreaUuid(data.uuid));
  };

  const onClickAllMembers = () => {
    dispatch(setSelectedMember(null));
    dispatch(setSelectedAssetUuid(undefined));
    dispatch(setSelectedAreaUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
  };

  const onClickAsset = (childUuid) => {
    dispatch(setSidebarMode('editAsset'));
    dispatch(setSelectedAssetUuid(childUuid));
  };

  console.log('assets', assets);

  return (
    <>
      <div className={s.header} onClick={onClickAllMembers}>
        <BaseIcon size={14} icon="arrow-left-full" className={s.iconBack} />
        {t('navigation.ALL_MEMBERS')}
      </div>

      <div className={s.container}>
        {action === EAction.EDIT && <EditMember onClickClose={() => setAction(EAction.VIEW)} />}
        {action === EAction.VIEW && (
          <div className={classnames(s.userTileContainer)}>
            <div className={s.infoContainer}>
              {/* TODO: Put these action icons inline with the UserTileShowData name */}
              <div className={s.actionsContainer}>
                <BaseButtonSquare
                  onClick={() => setAction(EAction.EDIT)}
                  className={s.button}
                  icon="pencil-edit"
                  size="2"
                />
                <BaseButtonSquare
                  onClick={() =>
                    removeCommunityMemberMutation({
                      variables: { memberUuid: selectedMember!, configUuid: configUuid! },
                    })
                  }
                  className={s.button}
                  icon="trash"
                  size="2"
                />
              </div>
              {/* TODO: Put this card also in editMember */}
              <UserTileShowData
                name={member?.name ?? ''}
                email={member?.email ?? ''}
                address={member?.address ?? ''}
                setFormData={() => null}
                onSubmit={() => null}
              />
            </div>
          </div>
        )}
        <div className={s.listMemberAssets}>
          {area && (
            <div className={s.assetsContainer}>
              <AssetsCard
                isUserACommunityMember={isUserACommunityMember}
                key={area.uuid}
                data={area}
                assetIcon={get3dAssetIcon(area.type)}
                childrenCount={assets?.length || 0}
                onClick={() => handleAssetClick(area)}
                isMoreButtonActive={false}
              />
            </div>
          )}
          {assets && assets.length ? (
            <div className={s.assetsContainer}>
              {assets.map((asset) => {
                return (
                  <AssetsCard
                    isUserACommunityMember={isUserACommunityMember}
                    key={asset.uuid}
                    data={asset}
                    assetIcon={get3dAssetIcon(asset.type)}
                    isMoreButtonActive={false}
                    onClick={onClickAsset}
                  />
                );
              })}
            </div>
          ) : (
            <div className={s.noAssetsContainer}>
              <div className={s.noAssetPill}>{t('messages.NO_ASSETS')}</div>
            </div>
          )}
          {!readOnly && !isUserACommunityMember && (
            <BaseButton
              isLoading={isAppLoading}
              disabled={isOperationalCommunity}
              onClick={onAddAssetClick}
              theme="grey2"
              className={s.addAssetButton}
              icon="plus-circle">
              {t('commands.ADD_ASSET')}
            </BaseButton>
          )}
        </div>
      </div>
    </>
  );
};
