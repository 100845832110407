import React, { useMemo, useRef } from 'react';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TChartEnergyBill } from 'src/components/Charts/ChartEnergyBill/ChartEnergyBill.types';
import { EChartName } from 'src/components/Charts/chartsData';
import { useChartJS } from 'src/hooks/useChartJS';

import s from './ChartEnergyBill.module.scss';

export const ChartEnergyBill: React.FC<TChartEnergyBill> = ({ list }) => {
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const datasets = useMemo(() => {
    return [
      {
        data: list.map((item) => item.percentage),
        backgroundColor: list.map((item) => item.labelColor),
        borderWidth: 2,
      },
    ];
  }, [list]);

  const doughnutChartOptions = {
    animation: false,
    responsive: true,
    plugins: {
      datalabels: {
        backgroundColor: 'black',
        color: 'white',
        borderRadius: 10,
        padding: {
          left: 10,
          right: 10,
        },
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        },
      },
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  useChartJS(
    EChartName.EnergyBill,
    canvasRef,
    chartRef,
    {
      labels: list.map((item) => `${item.percentage}%`),
      datasets,
    },
    { chartOptions: doughnutChartOptions, plugins: [ChartDataLabels] },
  );

  return (
    <div className={s.doughnut}>
      <canvas ref={canvasRef} />
    </div>
  );
};
