import { ConfigType } from 'src/graphql';
import {
  TAssetFieldTemplatesArgs,
  TFieldsTemplateUnion,
} from 'src/utils/assetsFields/assetsFields.types';
import { Area } from 'src/utils/assetsFields/fieldTemplates/Area';
import { HeatPump } from 'src/utils/assetsFields/fieldTemplates/HeatPump';
import { InfiniteBus } from 'src/utils/assetsFields/fieldTemplates/InfiniteBus';
import { Load } from 'src/utils/assetsFields/fieldTemplates/Load';
import { Storage } from 'src/utils/assetsFields/fieldTemplates/Storage';

// Template of node's fields. They are listed in order in which they should appear in form.

// In most cases, these will be the fields which should be displayed when viewing
// a new or existing node.
export function baseFieldTemplates({
  type,
  configType,
  advancedSettings,
}: TAssetFieldTemplatesArgs): TFieldsTemplateUnion[] {
  switch (type) {
    /* Area (General Grid Market) */
    case 'Area':
      return Area({
        configType: configType as ConfigType,
        advancedSettings: advancedSettings || {},
      });

    /* Load */
    case 'Load':
      return Load({ configType: configType as ConfigType });

    case 'PV':
      return [];

    /* Heat Pump */
    case 'ScmHeatPump':
      return HeatPump({ configType: configType as ConfigType });

    case 'ScmStorage':
      return Storage({ configType: configType as ConfigType });

    /* InfiniteBus */
    case 'InfiniteBus':
      return InfiniteBus({
        configType: configType as ConfigType,
        advancedSettings: advancedSettings || {},
      });

    default:
      throw new Error(`Invalid type passed to getAssetFieldTemplates function - ${type}`);
  }
}
