import React, { useState, useMemo } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { EAccordionType } from 'src/components/Accordion';
import { Accordion } from 'src/components/Accordion';
import { InputField } from 'src/components/InputField';
import { Tabs } from 'src/components/Tabs';
import { ETabs } from 'src/components/Tabs';
import { GENERATION_PROFILE_OPTIONS } from 'src/constants/application';
import singleAssetStyles from 'src/features/Assets/SingleAsset/SingleAsset.module.scss';
import { TFormAssetProps } from 'src/features/Assets/SingleAsset/SingleAsset.types';
import { ConfigType, useReadConfigurationQuery } from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

import s from './CustomPV.module.scss';
import pvfields from './fields';
import { Orientation, EOrientationParam, TOrientationParam } from './Orientation';

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

export const CustomPV: React.FC<TFormAssetProps> = ({
  values,
  handleChange,
  handleBlur,
}: TFormAssetProps) => {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);

  const [mainTab, setMainTab] = useState<ETabs>(ETabs.BASIC);
  const [openTabs, setOpenTabs] = useState<EAccordionType[]>([EAccordionType.capacityAndProfile]);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !activeConfigurationUuid,
    variables: { uuid: activeConfigurationUuid! },
  });
  const configuration = useMemo(() => configurationResponse?.readConfiguration, [
    configurationResponse,
  ]);
  const isOperationalCommunity = useMemo(() => configuration?.type === ConfigType.CanaryNetwork, [
    configuration,
  ]);

  const onTabClick = (tab: EAccordionType) => {
    setOpenTabs((prevTabs) =>
      prevTabs.includes(tab) ? prevTabs.filter((x) => x !== tab) : [...prevTabs, tab],
    );
  };

  const isTabOpen = (tab: EAccordionType) => openTabs.includes(tab);

  const fields: TFieldsTemplateUnion[] = useMemo(
    () => pvfields({ configType: configuration?.type, values }),
    [configuration, values],
  );

  const onChange = ({ name, value }) => {
    if (name == 'profileMode' && value == GENERATION_PROFILE_OPTIONS[1].value) {
      return handleChange([{ name, value }]);
    }
    handleChange([{ name, value }]);
  };

  return (
    <div className={classnames(s.customPV, s['light'])}>
      <Tabs
        handleClick={(tab) => setMainTab(tab)}
        activeTab={mainTab}
        tabs={isOperationalCommunity ? [ETabs.BASIC] : [ETabs.BASIC, ETabs.ADVANCED]}
      />

      {mainTab === ETabs.BASIC && (
        <div className={singleAssetStyles.fieldsContainer}>
          {fields
            .filter((field) => field.formView == ETabs.BASIC)
            .map((field: TFieldsTemplateUnion) => (
              <InputField
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(field as any)}
                key={field.name as string}
                value={!!values && !!values[field.name] ? values[field.name] : field.defaultValue}
                fieldType={field.type}
                onChange={onChange}
              />
            ))}
        </div>
      )}
      {mainTab === ETabs.ADVANCED && (
        <div>
          <div className={s.container}>
            <Accordion
              name={EAccordionType.capacityAndProfile}
              isOpen={isTabOpen(EAccordionType.capacityAndProfile)}
              handleClick={onTabClick}
            />
            {isTabOpen(EAccordionType.capacityAndProfile) && (
              <div className={singleAssetStyles.fieldsContainer}>
                {fields
                  .filter((field) => field.formView == ETabs.ADVANCED)
                  .map((field: TFieldsTemplateUnion) => (
                    <InputField
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      {...(field as any)}
                      key={field.name as string}
                      value={
                        !!values && !!values[field.name] ? values[field.name] : field.defaultValue
                      }
                      fieldType={field.type}
                      onChange={onChange}
                    />
                  ))}
              </div>
            )}
          </div>
          <div className={s.container}>
            <Accordion
              name={EAccordionType.orientation}
              isOpen={isTabOpen(EAccordionType.orientation)}
              handleClick={onTabClick}
            />
            {isTabOpen(EAccordionType.orientation) && (
              <Orientation
                azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
                setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                theme="light"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
