import React from 'react';

import classnames from 'classnames';
import { TSidebarSubTabProps } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/SidebarSubTab/SidebarSubTab.types';
import s from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/MapSidebarSingleCommunity.module.scss';

export const SidebarSubTab: React.FC<TSidebarSubTabProps> = ({ tabs }) => (
  <div className={classnames(s.subtabHeader)}>
    <ul className={s.headerNav}>
      {tabs.map((item) => (
        <li
          key={`sidebarSubTabItem-${item.label}-${item.liTitle}`}
          className={classnames(s.communityName, {
            [s.active]: item.isActive(),
          })}
          title={item.liTitle}>
          <button type="button" onClick={item.onClick}>
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);
