import React from 'react';

import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import s from 'src/components/MapSidebar/components/ListMembersAndAssets/components/UserInvite/UserInvite.module.scss';

import { TManagerInviteProps } from './ManagerInvite.types';

export const ManagerInvite: React.FC<TManagerInviteProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.emailInvitation}>
        <div>
          <BaseButton className={s.fullWidthButton} size="medium" theme="primary" onClick={onClick}>
            {t('commands.ADD_MANAGER')} <BaseIcon icon="plus-circle" size={12} />
          </BaseButton>
        </div>
      </div>
      <div className={s.line}></div>
    </div>
  );
};
