import React, { useCallback, useEffect, useRef, useState } from 'react';

import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ETabs } from 'src/components/Tabs';
import { ListItemBlock } from 'src/features/Communities/ListCommunities/ListItem/CommunityListItem/ListItemBlock';
import { ListItemDefault } from 'src/features/Communities/ListCommunities/ListItem/CommunityListItem/ListItemDefault';
import { ConfigType } from 'src/graphql';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { useSimulationStatus } from 'src/hooks/useSimulationStatus';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { selectIsLoggedIn, selectUsername } from 'src/redux/auth/auth.selectors';
import {
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
} from 'src/redux/configuration/configuration.slice';
import { selectActiveCommunityListTab } from 'src/redux/scm/scm.selectors';
import { setActiveSCMStep } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { EAppMode } from 'src/utils/appMode';
import { getPlaceNameFromCoords } from 'src/utils/worldMap/helpers';

import { TCommunityListItemProps } from './CommunityListItem.types';
export const CommunityListItem: React.FC<TCommunityListItemProps> = function CommunityListItem({
  item,
  className,
  style,
  description,
  locationCoords,
  onMenuClick,
}) {
  const dispatch = useAppDispatch();
  const modalRefObject = useRef<HTMLButtonElement>(null);
  const [place, setPlace] = useState('');
  const [jobId, setJobId] = useState<string>();
  const [communityId] = useState<string>();
  const history = useHistory();
  const belongsToLoggedUser = item.user === useSelector(selectUsername);
  const { sidebarOpen } = useConfigurationFlowState();
  const { simulationStatus } = useSimulationStatus(jobId, !sidebarOpen, item.uuid?.toString());
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const appMode = useSelector(selectAppMode);

  const activeCommunitiesListTab = useSelector(selectActiveCommunityListTab);

  const { runButtonState, runButtonOptions, stopButtonOptions } = useSimulationButtons({
    jobUuid: jobId,
    configurationUuid: item.uuid?.toString(),
    simulationStatus,
  });

  const runSimulation = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (runButtonOptions.onClick) {
        const jobUuid = await runButtonOptions.onClick();
        if (runButtonState === 'run') {
          if (jobUuid) setJobId(jobUuid);
        }
      }
    },
    [runButtonOptions, runButtonState],
  );

  const stopSimulation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (stopButtonOptions.onClick) stopButtonOptions.onClick(jobId);
      setJobId(undefined);
    },
    [jobId, stopButtonOptions],
  );

  const goToResults = useCallback(() => {
    batch(() => {
      dispatch(setActiveConfigurationUuid(item.uuid?.toString()));
      if (jobId) {
        dispatch(setActiveConfigurationJobUuid(jobId));
      }
      history.push(routesConfig.scmMapResults(item.uuid?.toString(), ''));
    });
  }, [item, dispatch, history, jobId]);

  const handleCommunityClick = useCallback(() => {
    goToResults();
    if (activeCommunitiesListTab === ETabs.OPERATIONAL) {
      dispatch(setActiveSCMStep(4));
    }
  }, [goToResults, activeCommunitiesListTab, dispatch]);

  const handleMenuClick = useCallback(
    (buttonRef: HTMLButtonElement) => {
      onMenuClick(buttonRef, {
        communityUuid: item.uuid?.toString(),
      });
    },
    [item, onMenuClick],
  );

  const commonProps = {
    onCommunityClick: handleCommunityClick,
    onMenuClick: handleMenuClick,
    communityId: communityId || '',
    jobId: jobId || '',
    className,
    style,
    place,
    title: item.name ?? '',
    isLoggedIn,
    modalRefObject,
    belongsToLoggedUser,
    configType: (item.type as ConfigType) ?? ('defaultConfigType' as ConfigType),
  };

  useEffect(() => {
    let isComponentMounted = true;
    const asyncAction = async () => {
      if (locationCoords) {
        const v = await getPlaceNameFromCoords(locationCoords);
        if (isComponentMounted) {
          setPlace(v?.place_name || '');
        }
      }
    };

    asyncAction();

    return () => {
      isComponentMounted = false;
    };
  }, [item, locationCoords]);

  /**
   * ListItemDefault => Default view for desktop mode
   * ListItemBlock => Default view for mobile mode
   */
  return appMode === EAppMode.Desktop ? (
    <ListItemDefault
      item={item}
      activeCommunitiesListTab={activeCommunitiesListTab}
      configurationUuid={item.uuid?.toString() ?? ''}
      description={description}
      actionNumber={1}
      onResultsClick={goToResults}
      onRunButtonClick={runSimulation}
      onStopButtonClick={stopSimulation}
      {...commonProps}
    />
  ) : (
    <ListItemBlock loading={false} {...commonProps} />
  );
};
