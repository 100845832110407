import React, { useCallback, useContext, useMemo, useRef } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BaseButton } from 'src/components/BaseButton';
import { BaseHamburger } from 'src/components/BaseHamburger';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { BaseMobileModal } from 'src/components/BaseMobileModal';
import { BaseOverflowMenu } from 'src/components/BaseOverflowMenu';
import { NAV_LINKS } from 'src/components/Header/constants';
import { EHeaderMode } from 'src/components/Header/Header';
import s from 'src/components/Header/Header.module.scss';
import { LanguageSelect } from 'src/components/Header/LanguageSelect/LanguageSelect';
import { SimulationRunButton } from 'src/components/Header/SimulationRunButton';
import { FormInviteUser } from 'src/components/MapSidebar/components/FormInviteUser';
import { NotificationsModalList } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity/components/NotificationsModalList';
import { MeasurementNotificationList } from 'src/components/MeasurementNotificationList';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { ModalBurgerNavigation } from 'src/components/ModalBurgerNavigation';
import { ShareSimulation } from 'src/components/ShareSimulation';
import { RequestSentNotification } from 'src/components/SingleNotifications/RequestSentNotificcation';
import { RunSimulationNotification } from 'src/components/SingleNotifications/RunSimulationNotification';
import { ETabs } from 'src/components/Tabs';
import { UserAvatar } from 'src/components/UserAvatar';
import { START_LOCATION } from 'src/constants/application';
import { EDomIds } from 'src/constants/domSelectors';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { HeaderSimulationFlag } from 'src/features/Results/DashboardHeader/CustomResultHeader/HeaderSimulationFlag';
import { ConfigType, UserRequestsStatus, useSendScmInvitationEmailMutation } from 'src/graphql';
import { useMoveCollaborationToCanaryNetworkMutation } from 'src/graphql';
import { useReadConfigurationQuery } from 'src/graphql';
import { useAppFlow } from 'src/hooks/useAppFlow';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useModal } from 'src/hooks/useModal';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { selectActiveDashboard, selectIsEmbed } from 'src/redux/application/application.selectors';
import {
  setAddNewLibrary,
  setGuideStep,
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
  setOnBoardingStep,
} from 'src/redux/application/application.slice';
import { selectIsAdmin, selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectConfigType,
  selectReadOnly,
  selectCommunityLogo,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal, toggleModal } from 'src/redux/modals/modals.slice';
import {
  addSingleNotification,
  clearMeasurementNotificationList,
  deleteSingleNotification,
} from 'src/redux/notifications/notificaitons.slice';
import {
  selectGlobalNotificationsList,
  selectSingleNotificationByNotificationPlace,
} from 'src/redux/notifications/notifications.selectors';
import { ENotificationPlace } from 'src/redux/notifications/notifications.types';
import {
  selectIsOperationalCommunity,
  selectMeasurementRequests,
  selectSCMDataSheetResponse,
} from 'src/redux/scm/scm.selectors';
import {
  clearSCMResults,
  setActiveSCMStep,
  setDataSheetResponse,
  setActiveCommunityListTab,
  setNewOperationalCommunityUuid,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { routesConfig, routesTitles } from 'src/routes/routes.config';
import { EDashboard, EUserRoles } from 'src/typings/base-types';
import { convertUserToAdminRequestOnNotification } from 'src/utils/notification';

export const LoggedComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { id: notificationsModalUuid } = useModal();
  const RunPauseBtnRef = useRef<HTMLElement>(null);
  const { id: burgerMenuModalId } = useModal();
  const communityAsset = useSelector(selectCommunityAsset);
  const { resetSCMSteps } = useAppFlow();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const notificationsList = useSelector(selectGlobalNotificationsList);
  const notificationsBellButtonRef = useRef<HTMLButtonElement>(null);
  const [moveCollaborationToCanaryNetwork] = useMoveCollaborationToCanaryNetworkMutation();
  const { runButtonOptions, runButtonState, stopButtonOptions } = useSimulationButtons({
    jobUuid: activeConfigurationJobUuid,
    configurationUuid: activeConfigurationUuid,
  });
  const { id: shareMenuModalId } = useModal();

  const { isAdminInspecting, isOwnerCM, isOwnerAdmin } = useIsAdminInspecting();
  const { discardCurrentConfiguration } = useConfigurationUtils();
  const { headerMode, communityIsReadyToSave } = useConfigurationFlowState();
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const scmDataSheetResponse = useSelector(selectSCMDataSheetResponse);
  const communityLogo = useSelector(selectCommunityLogo);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const measurementRequests = useSelector(selectMeasurementRequests);
  const simulationStatus = useSelector(selectSimulationStatus);
  const userRole = useSelector(selectUserRole);
  const modalRefShare = useRef<HTMLButtonElement>(null);
  const runSimulationNotification = useSelector(
    selectSingleNotificationByNotificationPlace(ENotificationPlace.HEADER_RUN_SIMULATION),
  );
  const isAdmin = useSelector(selectIsAdmin);

  const { data: configuration } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !activeConfigurationUuid,
    variables: { uuid: activeConfigurationUuid || '' },
  });
  const communityName = useMemo(() => configuration?.readConfiguration?.name, [configuration]);

  const [sendScmInvitationEmailMutation] = useSendScmInvitationEmailMutation();
  const sendScmInvitationEmail = useCallback(
    async (configurationUuid: string) => {
      await sendScmInvitationEmailMutation({
        variables: {
          configUuid: configurationUuid,
          selectedMemberNames: null,
        },
      });
    },
    [sendScmInvitationEmailMutation],
  );

  const _handleStartOperating = () => {
    if (activeConfigurationUuid) {
      moveCollaborationToCanaryNetwork({
        variables: {
          uuid: activeConfigurationUuid,
          name: communityName || '',
        },
      }).then(({ data }) => {
        dispatch(
          openToast({
            message: 'simulation moved to an operational community successfully',
            type: 'success',
          }),
        );
        handleCommunityDiscard();
        dispatch(setActiveCommunityListTab(ETabs.OPERATIONAL));
        const newCommunityUuid = data?.moveCollaborationToCanaryNetwork?.uuid;
        if (newCommunityUuid) dispatch(setNewOperationalCommunityUuid(newCommunityUuid));
      });
    }
  };

  const additionalActionForOperationalCommunity = useCallback(() => {
    if (!activeConfigurationUuid) return;

    sendScmInvitationEmail(activeConfigurationUuid).then(() => {
      dispatch(
        openToast({
          message: 'Invitation sent successfully',
          type: 'success',
        }),
      );
    });
  }, [activeConfigurationUuid, sendScmInvitationEmail, dispatch]);
  function handleAddNewLibraryClick() {
    dispatch(setAddNewLibrary(true));
  }
  const requestSentNotification = useSelector(
    selectSingleNotificationByNotificationPlace(ENotificationPlace.HEADER_REQUEST_SENT),
  );
  const activeDashboard = useSelector(selectActiveDashboard);
  const isBurgerMenuOpened = useSelector(selectIsModalOpened(burgerMenuModalId));
  const configType = useSelector(selectConfigType);
  const isEmbed = useSelector(selectIsEmbed);
  const handleAddNewUser = () => {
    batch(() => {
      dispatch(openModal(EPredefinedModalIds.MODAL_ADD_USER));
    });
  };
  const { mapService } = useContext(WorldMapContext);
  function handleHamburgerClick() {
    if (isBurgerMenuOpened) {
      dispatch(closeModal(burgerMenuModalId));
    } else {
      dispatch(openModal(burgerMenuModalId));
    }
  }

  const handleLogoRedirection = () => {
    if (discardCurrentConfiguration) discardCurrentConfiguration({ zoomOut: true });
  };

  const memoizedNotifications = useMemo(() => {
    if (notificationsList.length !== 0) {
      return notificationsList.map(
        // @ts-ignore
        (item) => item && convertUserToAdminRequestOnNotification(item),
      );
    }
  }, [notificationsList]);

  const handleCommunityDiscard = () => {
    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration({ zoomOut: true });
    dispatch(clearMeasurementNotificationList());
    resetSCMSteps();
    dispatch(setActiveSCMStep(0));
    dispatch(clearSCMResults());
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    if (scmDataSheetResponse) {
      // clear hook constant route change:: useMapLoadAfterBeResponse
      dispatch(setDataSheetResponse(null));
    }
  };
  const readOnly = useSelector(selectReadOnly);
  const isCN = configType === ConfigType.CanaryNetwork;

  const title =
    headerMode === EHeaderMode.Community ? communityName : routesTitles[location.routeName];
  const isBackBtnDisable = false;
  const showLaunchButton = useMemo(() => {
    if (!isOperationalCommunity || !(isOwnerCM || isOwnerAdmin) || measurementRequests.length === 0)
      return false;

    const status =
      measurementRequests.filter((request) => request.status === UserRequestsStatus.Executed)
        .length > 0;
    if (status && !simulationStatus) {
      dispatch(
        addSingleNotification({
          description:
            'You can now connect more assets or proceed to launch the operation. Once the commmunity operation is initiated, all community members will receive an invitation to login.',
          notificationPlace: ENotificationPlace.HEADER_LAUNCH_BUTTON,
        }),
      );
    } else {
      dispatch(deleteSingleNotification());
    }

    return status;
  }, [
    dispatch,
    isOperationalCommunity,
    isOwnerCM,
    isOwnerAdmin,
    measurementRequests,
    simulationStatus,
  ]);

  const handleCreateCommunityClick = () => {
    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    history.push(routesConfig.scmMapCreate());
    mapService?.fitBounds(START_LOCATION);
  };
  const closeShareSimulationModal = () => {
    dispatch(closeModal(shareMenuModalId));
  };

  return (
    <header id={EDomIds.HEADER} className={classnames(s.container, s.itemsCenter)}>
      <nav className={classnames(s.nav_con, s.itemsCenter)}>
        <BaseHamburger
          isOpened={isBurgerMenuOpened}
          onClick={handleHamburgerClick}
          className={s.hamburger}
        />
        <ModalBurgerNavigation modalId={burgerMenuModalId} />
        <BaseLink
          isNavLink={true}
          onClick={handleLogoRedirection}
          to={routesConfig.scm()}
          className={s.logoLink}
          activeStyle={false}>
          <BaseIcon icon="gs-logo" />
        </BaseLink>

        <span className={s.vertLineA}></span>

        <>
          {headerMode !== EHeaderMode.Community && (
            <div className={s.scmNavLinks}>
              {NAV_LINKS.map((link) => {
                return (
                  <BaseLink
                    key={link.route}
                    isNavLink={location?.routeName === link.route}
                    to={routesConfig[link.route]()}
                    className={classnames(s.pageNavLink, 'click-area')}>
                    {link.title}
                  </BaseLink>
                );
              })}
            </div>
          )}
          {requestSentNotification && (
            <RequestSentNotification singleNotification={requestSentNotification} />
          )}
          {headerMode == EHeaderMode.Community && (
            <>
              <div className={s.headerTitleWrapper}>
                {!isUserACommunityMember && (
                  <button
                    type="button"
                    className={classnames(s.backIcon, 'click-area', {
                      [s.disabled]: isBackBtnDisable,
                    })}
                    onClick={handleCommunityDiscard}
                    disabled={isBackBtnDisable}>
                    <BaseIcon icon="arrow-left-full" size={17} />
                  </button>
                )}
                <span className={s.title}>{title}</span>
                {typeof communityLogo === 'string' && communityLogo.length > 0 && (
                  <img src={communityLogo} className={s.logoIcon} />
                )}
              </div>
              {!isUserACommunityMember && (
                <HeaderSimulationFlag
                  isIconActive={false}
                  className={s.headerSimulationFlag}
                  title={
                    isOperationalCommunity ? t('feedback.OPERATIONAL') : t('feedback.SIMULATION')
                  }
                />
              )}
            </>
          )}
        </>
      </nav>

      <nav className={classnames(s.nav_con, s.mla, s.itemsCenter)}>
        <LanguageSelect />
        {showLaunchButton && (
          <SimulationRunButton
            runButtonOptions={runButtonOptions}
            runButtonState={runButtonState}
            runPauseBtnRef={RunPauseBtnRef}
            additionalAction={() => {
              additionalActionForOperationalCommunity();
            }}
          />
        )}
        {!isEmbed &&
          (activeDashboard === EDashboard.Libraries ? (
            <BaseButton
              className={classnames(s.buildCommunityBtn, s.ml25)}
              onClick={handleAddNewLibraryClick}>
              <BaseIcon icon={'plus'} size={10} className={s.socialIcon} />
              Add new library item
            </BaseButton>
          ) : headerMode === EHeaderMode.Community ? (
            <>
              {!isOperationalCommunity && !stopButtonOptions.disabled && (
                <button
                  type="button"
                  className={classnames(s.abortBtn, s.centerAll)}
                  onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
                  disabled={stopButtonOptions.disabled}>
                  <BaseIcon icon={stopButtonOptions.icon} size={19.5} />
                </button>
              )}
              {activeConfigurationUuid ? (
                !readOnly && !isOperationalCommunity && !isAdminInspecting ? (
                  <SimulationRunButton
                    runButtonOptions={runButtonOptions}
                    runButtonState={runButtonState}
                    runPauseBtnRef={RunPauseBtnRef}
                  />
                ) : null
              ) : communityIsReadyToSave ? (
                <BaseButton
                  type="submit"
                  form={EPredefinedModalIds.MODAL_COMMUNITY_ASSET_MANAGER_FORM}
                  className={s.buildCommunityBtn}
                  onClick={() => {
                    dispatch(setSelectedAssetUuid(communityAsset?.uuid));
                    dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Form));
                  }}>
                  Save Community
                </BaseButton>
              ) : null}
            </>
          ) : (
            // the below condition will ensure no build button on user dashboard
            activeDashboard !== EDashboard.Users &&
            // PH-1283-Hide build community for GSY user
            userRole !== EUserRoles.Aggregator &&
            userRole !== EUserRoles.Researcher && (
              <BaseButton
                className={classnames(s.buildCommunityBtn, s.ml5)}
                onClick={handleCreateCommunityClick}>
                {t('commands.BUILD_COMMUNITY')}
              </BaseButton>
            )
          ))}
        {isAdmin && activeDashboard === EDashboard.Users && (
          <BaseButton
            icon={'plus-thick'}
            className={classnames(s.buildCommunityBtn, s.ml25)}
            onClick={handleAddNewUser}>
            Add new user
          </BaseButton>
        )}
        {userRole && [EUserRoles.Admin, EUserRoles.ExchangeOperator].includes(userRole) && (
          <>
            <button
              className={s.notificationsButton}
              type="button"
              title="Notificatons"
              ref={notificationsBellButtonRef}
              onClick={() => {
                dispatch(toggleModal(notificationsModalUuid));
              }}>
              <BaseIcon icon="bell" size={22} />
              {memoizedNotifications && memoizedNotifications.length !== 0 && (
                <div className={s.notificationsBubble}>
                  <span className={s.notificationsBubbleText}>{memoizedNotifications.length}</span>
                </div>
              )}
            </button>

            {runSimulationNotification && (
              <RunSimulationNotification
                singleNotification={runSimulationNotification}
                runPauseBtnRef={RunPauseBtnRef}
              />
            )}
            {!runSimulationNotification && isOperationalCommunity && (
              <BaseOverflowMenu
                relativeElement={notificationsBellButtonRef}
                modalId={notificationsModalUuid}
                position={{
                  side: 'bottom',
                  anchorPosition: 90,
                }}
                size={EModalSize.L330}
                className={s.notificationsModal}>
                <MeasurementNotificationList />
              </BaseOverflowMenu>
            )}
            {!runSimulationNotification && !isOperationalCommunity && (
              <BaseOverflowMenu
                relativeElement={notificationsBellButtonRef}
                modalId={notificationsModalUuid}
                position={{
                  side: 'bottom',
                  anchorPosition: 90,
                }}
                size={EModalSize.L330}
                className={s.notificationsModal}>
                <NotificationsModalList
                  items={memoizedNotifications ? memoizedNotifications : []}
                  onChange={() => null}
                  className={s.notificationsModalList}
                />
              </BaseOverflowMenu>
            )}
          </>
        )}

        {isUserACommunityMember && (
          <>
            <button
              className={s.notificationsButton}
              type="button"
              title="Notificatons"
              ref={notificationsBellButtonRef}
              onClick={() => {
                dispatch(toggleModal(notificationsModalUuid));
              }}>
              <BaseIcon icon="bell" size={22} />
              {memoizedNotifications && memoizedNotifications.length !== 0 && (
                <div className={s.notificationsBubble}>
                  <span className={s.notificationsBubbleText}>{memoizedNotifications.length}</span>
                </div>
              )}
            </button>
            <BaseMobileModal modalId={notificationsModalUuid}>
              <FormInviteUser />
            </BaseMobileModal>
            <BaseOverflowMenu
              relativeElement={notificationsBellButtonRef}
              modalId={notificationsModalUuid}
              hideMobile={true}
              position={{
                side: 'bottom',
                anchorPosition: 90,
              }}
              size={EModalSize.L395}
              className={s.notificationsModal}>
              <div className={s.scmSimulationNotification}>
                <div>
                  <FormInviteUser />
                </div>
              </div>
            </BaseOverflowMenu>
          </>
        )}
      </nav>
      <UserAvatar />
      {/* User menu */}
      {!isCN && (
        <BaseOverflowMenu
          relativeElement={modalRefShare}
          modalId={shareMenuModalId}
          position={{
            side: 'bottom',
            anchorPosition: 6,
          }}
          size={EModalSize.M280}
          className={s.shareMenu}>
          <ShareSimulation close={closeShareSimulationModal} />
        </BaseOverflowMenu>
      )}
    </header>
  );
};
