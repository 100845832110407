export enum EAccordionType {
  capacityAndProfile = 'navigation.CAPACITY_AND_PROFILE',
  orientation = 'navigation.ORIENTATION',
  tradingStrategy = 'Trading Strategy',
}

export type TAccordionProps = {
  handleClick: (name: EAccordionType) => void;
  isOpen: boolean;
  name: EAccordionType;
  className?: string;
};
