import React, { useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseIcon } from 'src/components/BaseIcon';
import { ContextMenu } from 'src/components/ContextMenu';
import { useModal } from 'src/hooks/useModal';

import s from './SingleManagerTile.module.scss';
import { TSingleManagerTile } from './SingleManagerTile.types';

export const SingleManagerTile: React.FC<TSingleManagerTile> = ({ username, handleRemove }) => {
  const { t } = useTranslation();

  const modalRefObject = useRef<HTMLDivElement>(null);

  const { id: modalId, onClick, modalTriggerRef } = useModal();

  return (
    <div className={classNames(s.managerTile)}>
      <div className={classNames(s.leftContainer)}>
        <div className={s.nameContainer}>
          <p className={s.name}>{username}</p>
        </div>
      </div>
      <div
        className={s.menuIcon}
        ref={modalRefObject}
        onClick={() => {
          onClick(modalRefObject.current);
        }}>
        <BaseIcon icon="more-horizontal" size={15} />
      </div>
      <ContextMenu
        relativeElement={modalTriggerRef}
        modalId={modalId}
        items={[
          {
            title: t('commands.REMOVE'),
            icon: 'custom-trash' as const,
            onClick: async () => handleRemove(username),
          },
        ]}
      />
    </div>
  );
};
