import { optionalFile } from 'src/features/Assets/utils';
import { AreaInput } from 'src/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function prepareLoad({ values }): AreaInput {
  const output: AreaInput = {
    type: values.type,
    uuid: values.uuid,
    name: values.name || '',
    geoTagLocation: values.geoTagLocation,
    ...optionalFile('dailyLoadProfile', values.dailyLoadProfile),
    dailyLoadProfileUuid: values.dailyLoadProfileUuid,
  };

  return output;
}
