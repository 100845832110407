import React, { useCallback, useMemo, useState } from 'react';

import classnames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseList } from 'src/components/BaseList';
import { EOrientationParam } from 'src/features/Assets/SingleAsset/CustomPV/Orientation/Orientation.types';

import s from './OrientationBasic.module.scss';
import { TOptionsType, TOrientationBasicProps } from './OrientationBasic.types';
import { getOrientationBasicOptions } from './OrientationBasicContants';

export const OrientationBasic: React.FC<TOrientationBasicProps> = ({
  theme,
  className,
  orientationParam,
  setOrientationParam,
  isWhiteIcons = false,
}) => {
  const options = useMemo(
    () =>
      getOrientationBasicOptions({
        isWhite: isWhiteIcons,
      }),
    [isWhiteIcons],
  );

  const [activeItem, setActiveItem] = useState(() => {
    return options.find((item) => item.value == orientationParam[EOrientationParam.TILT]);
  });

  const onItemClick = useCallback(
    (item: TOptionsType) => {
      setActiveItem(item);
      setOrientationParam({ ...orientationParam, [EOrientationParam.TILT]: item.value });
    },
    [orientationParam, setOrientationParam],
  );

  const isItemActive = ({ value }: TOptionsType) => activeItem?.value === value;

  return (
    <div
      className={classnames(s.orientationBasic, className, {
        [s.light]: theme === 'light',
      })}>
      <BaseList width="100%" className={s.list}>
        {options.map((item, index) => (
          <div
            key={index}
            className={classnames(s.item, {
              [s.itemActive]: isItemActive(item),
              [s.menuItemBottomBorder]: index !== options.length - 1,
            })}
            onClick={() => {
              onItemClick(item);
            }}>
            <div className={s.optionContainer}>
              <BaseIcon icon={item?.icon} size={42} />
              <label htmlFor={item.label}>
                <div className={s.label}>{item.label}</div>
                <div className={s.description}>{item.description}</div>
              </label>
            </div>
            <input
              type="radio"
              id={item.label}
              name="filter"
              defaultChecked={item?.value === activeItem?.value}
            />
          </div>
        ))}
      </BaseList>
    </div>
  );
};
