import { TSelectedLibraryType } from 'src/components/LibraryChooser';
import { TLocationSearchValue } from 'src/components/LocationSearch';
import { AreaInput } from 'src/graphql';
import { TDateRangeFieldWithValue } from 'src/utils/assetsFields/assetsFields.types';

export type TOnChangePayload<TValue, TName> = { value: TValue; name: TName };

type TLat = number;
type TLng = number;

export type TLngLat = {
  lng: TLng;
  lat: TLat;
};

export type TLngLatArray = [TLng, TLat];

export type TAssetType =
  | 'Area'
  | 'FiniteDieselGenerator'
  | 'Load'
  | 'PV'
  | 'Storage'
  | 'ScmStorage'
  | 'MarketMaker'
  | 'HeatPump'
  | 'ScmHeatPump'
  | 'InfiniteBus';

// These are all LibrariesTags supported by the backend.
export type TLibrariesTags =
  | 'Load'
  | 'Public'
  | 'Storage'
  | 'ScmStorage'
  | 'PV'
  | 'FiniteDieselGenerator'
  | 'CommercialProducer'
  | 'Area'
  | 'MarketMaker'
  | 'InfiniteBus'
  | 'DefaultLib'
  | 'Home'
  | 'CustomAssets'
  | 'HeatPump'
  | 'ScmHeatPump';

export enum EFormVariant {
  Express = 'FORM_EXPRESS',
  Advanced = 'FORM_ADVANCED',
  OnlyAdvanced = 'ONLY_FORM_ADVANCED',
  KpiSettings = 'FORM_KPI_SETTINGS',
  CanaryNetwork = 'FORM_CANARY_NETWORK',
  GridMarket = 'GRID_MARKET',
  CommunityManagers = 'COMMUNITY_MANAGERS',
  GridAgent = 'GRID_AGENT',
  GridMarketEnergy = 'GRID_MARKET_ENERGY',
  BasicLibrarySettings = 'FORM_BASIC_LIBRARY_SETTINGS',
  CoefficientMechanism = 'COEFFICIENT_MECHANISM',
}

export type TFileIdentifiers = Pick<File, 'lastModified' | 'name' | 'size'>;

export type TUploadedProfile = string | TFileIdentifiers;

export type TFieldValue =
  | string
  | boolean
  | number
  | undefined
  | null
  | TLocationSearchValue['coords']
  | TUploadedProfile
  | TDateRangeFieldWithValue['value']
  | TSelectedLibraryType;

export type TFieldUnits =
  | 'kVA'
  | 'kWh'
  | 'cents / kWh'
  | '€ / kWh'
  | '€ / month'
  | 'kW'
  | 'W'
  | 'cents / kWh per update'
  | 'sec'
  | 'min'
  | '%'
  | 'kWp'
  | 'ºC'
  | 'L';

export enum EUserRoles {
  Admin = 'Admin',
  Researcher = 'Researcher',
  DSO = 'DSO',
  ExchangeOperator = 'Exchange Operator',
  Aggregator = 'Aggregator',
}

export type TRenamedUserRoles = 'Admin' | 'Researcher' | 'DSO' | 'Exchange Operator' | 'Aggregator';

// TODO: Backend implemented enum SimulationStatusType, let's use it 🎉
export type TSimResultsStatus =
  | 'loading'
  | 'queued'
  | 'initializing'
  | 'started'
  | 'running'
  | 'paused'
  | 'stopped'
  | 'stopping'
  | 'finished'
  | 'error'
  | 'failed'
  | 'timed-out';

export type TLiveEvent = {
  type: 'delete_area' | 'update_area' | 'create_area';
  description: string;
  representation: AreaInput;
};

export type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type TNotificationModalItem = {
  uuid: string;
  profilePicture: string;
  profileThumbnail: string;
  name: string;
  description: string;
  descriptionAction?: {
    title: string;
    action: () => void;
  };
};

export type TNotificationsModalListProps = {
  items: TNotificationModalItem[];
  onChange: () => void;
  className?: string;
};

export enum EDashboard {
  Libraries = 'LIBRARIES',
  Users = 'USERS',
}

export enum EGridMarketTab {
  Market = 'MARKET',
  Agent = 'AGENT',
}

// ConfigType is defined in the file: src/graphql/index.tsx
