import React, { useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import fileUpload from 'src/assets/images/Scm/icon-file-plus.png';
import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseLink } from 'src/components/BaseLink';
import { EActiveTabState } from 'src/components/MapSidebar/components/MapSidebarSingleCommunity';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useMapLoadAfterBeResponse } from 'src/hooks/useMapLoadAfterBeResponse';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal, updateModalContent } from 'src/redux/modals/modals.slice';
import { setActiveSCMStep, setDataSheetResponse, setSCMsidebarTab } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

import { useUploadCommunityDatasheetMutation } from '../../../../graphql';
import { FileUploadDrop } from './components/FileUploadDrop';
import { TFileType } from './components/FileUploadDrop/FileUploadDrop.types';
import { downloadFile } from './downloadFile';
import s from './ModalUploadDataSheet.module.scss';

export const ModalUploadDataSheet: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // This hook will take care of saving BE response data in store
  useMapLoadAfterBeResponse();

  const loadingModalData = useMemo(
    () => ({
      title: 'Processing Sheet',
      subTitle: 'Scanning Data',
      icon: 'file',
    }),
    [],
  );

  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET),
  );
  const scmLocation = useSelector(selectSelectedLocation);

  const modalRef = useRef<HTMLDivElement>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [file, setFile] = useState<TFileType>();

  const [uploadCommunityDataSheetMutation, { loading }] = useUploadCommunityDatasheetMutation();

  const closeUploadDataSheetModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
  };

  const handleDownloadModelSheet = async (e) => {
    await downloadFile('CommunityDataSheet.xlsx');
    e.stopPropagation();
  };

  const handleUploadDataSheet = async () => {
    try {
      const { data } = await uploadCommunityDataSheetMutation({
        variables: {
          configUuid: activeConfigurationUuid as string,
          file,
        },
      });
      if (data && data?.uploadCommunityDatasheet) {
        batch(() => {
          dispatch(setDataSheetResponse(data.uploadCommunityDatasheet));
          dispatch(closeModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
          dispatch(setActiveSCMStep(1));
          dispatch(setSCMsidebarTab(EActiveTabState.Registry));
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const msg = error && error?.message ? error.message : 'Something went wrong';
      const modalData = {
        ...loadingModalData,
        title: 'Upload Failed',
        subTitle: msg,
        icon: 'close',
      };
      dispatch(updateModalContent(modalData));
    }
  };

  useEffect(() => {
    // loading case
    if (loading) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
      const modalData = {
        ...loadingModalData,
        title: 'Processing Sheet',
      };
      batch(() => {
        dispatch(updateModalContent(modalData));
        dispatch(openModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
      });
    }
  }, [dispatch, loading, loadingModalData]);

  //TODO: It Should be deleted, it has been added for development purposes
  useEffect(() => {
    if (
      activeConfigurationUuid &&
      scmLocation &&
      !!matchPath(location.pathname, {
        path: routesConfig.scmMapCreate(),
      })
    ) {
      dispatch(setActiveSCMStep(1));
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    }
  }, [activeConfigurationUuid, dispatch, scmLocation]);

  if (!isModalDeployOpened) return null;

  return (
    <>
      <a ref={downloadLinkRef} href="/dataSheet/CommunityDataSheet.xlsx" target="_blank" download />
      <div className={s.background} onClick={closeUploadDataSheetModal} />
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalUploadDataSheet)}>
          <header className={s.header}>
            <h2 className={s.modalTitle}>{t('tooltips.UPLOAD_YOUR_COMMUNITY_DATASHEET')}</h2>
            <p>{t('tooltips.ADD_MULTIPLE_HOMES_FAST_CUSTOMISE_IT_LATER')}</p>
          </header>
          <div className={s.body}>
            <FileUploadDrop handleChange={(file) => setFile(file[0])}>
              <div className={s.uploadSection}>
                <img src={fileUpload} />
                <div className={s.title}>
                  {file ? file?.name : t('commands.DROP_YOUR_FILE_HERE')}
                </div>
                <div className={s.subTitle}>{file ? file?.type : 'Format: Xcel/CSV'}</div>
              </div>
            </FileUploadDrop>
            <BaseLink onClick={handleDownloadModelSheet} className={s.downloadLink}>
              {t('commands.DOWNLOAD_MODEL_SHEET')}
            </BaseLink>
            <BaseButton
              disabled={!Boolean(file)}
              className={s.uploadBtn}
              onClick={handleUploadDataSheet}>
              {t('commands.UPLOAD')}
            </BaseButton>
            <BaseLink to={'https://youtu.be/83aoZ6RHDck'} target="_blank" className={s.plainLink}>
              {t('commands.WATCH_COMMUNITY_DATASHEET_TUTORIAL')}
            </BaseLink>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
