import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { KeyResultInfoArea } from 'src/components/KeyResults/components/KeyResultInfoArea';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { formatter } from 'src/utils/formatter';

import s from './KeyResults.module.scss';
import { EViewType, TChartSavingsProps } from './KeyResults.types';
import { useGetDetails } from './KeyResultsHelpers';

export const KeyResultsMember: React.FC<TChartSavingsProps> = ({
  currency,
  homeBillValue,
  homeSavings,
  totalBenefit,
  title = '⚡ Key Results',
  showToggle = false,
  horizontal = false,
}) => {
  const { scmGroupSettingsName } = useSelector(selectSettingsData);

  const currencySymbol = formatter.getCurrencySymbol(currency);
  function normalizePercentage(value = 0) {
    return value.toFixed(2).replace('.', ',');
  }

  const { getDetails } = useGetDetails();

  const isVergy = useMemo(() => scmGroupSettingsName === 'VERGY', [scmGroupSettingsName]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        {title.length > 0 && <div className={s.title}>{title}</div>}
        {showToggle && (
          <button type="button" className={s.toggle}>
            <span>Monthly</span>
          </button>
        )}
      </div>
      <div className={horizontal ? s.horizontalWrapper : undefined}>
        <KeyResultInfoArea
          className={horizontal ? s.horizontalBlock : undefined}
          symbol={currencySymbol}
          symbolPosition="left"
          value={normalizePercentage(homeBillValue)}
          resultInfo={getDetails(EViewType.EnergyBill)}
        />
        <KeyResultInfoArea
          className={horizontal ? s.horizontalBlock : undefined}
          symbol={currencySymbol}
          symbolPosition="left"
          value={normalizePercentage(homeSavings)}
          resultInfo={getDetails(EViewType.Savings)}
        />
        {isVergy && (
          <KeyResultInfoArea
            className={horizontal ? s.horizontalBlock : undefined}
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(totalBenefit)}
            resultInfo={getDetails(EViewType.TotalBenefit)}
          />
        )}
      </div>
    </div>
  );
};
