import React from 'react';

import { TTooltipDefaultProps } from 'src/components/Charts/TooltipFactory/tooltips/TooltipDefault/TooltipDefault.types';
import { formatter } from 'src/utils/formatter';

import s from './TooltipDefault.module.scss';

export const TooltipDefault: React.FC<TTooltipDefaultProps> = ({ context, width }) => {
  const { chart, tooltip } = context;
  const titleLines = tooltip.title || [];
  const bodyLines = tooltip.body.reduce((acc, item, index) => {
    const text = item.lines.join('');
    if (Object.values(acc).includes(text)) return acc; // Filter out duplicates
    acc[index] = text;
    return acc;
  }, {});

  return (
    <div className={s.container} style={{ width: width + 'px' }}>
      <h4 className={s.title}>{titleLines.join('')}</h4>
      <ul>
        {Object.entries(bodyLines).map(([index, body]) => {
          const colors = tooltip.labelColors[index];
          const { dataset, raw } = tooltip.dataPoints[index];
          const type: 'line' | 'bar' = dataset.type || chart.config.type;
          const items: string[] = [];

          if (Array.isArray(raw)) {
            const { title, unit } = JSON.parse(dataset.label);
            items.push(
              ...raw.map(
                (value, i) => `${title[i]}: ${formatter.toOptionalFixed(value, 3)} ${unit[i]}`,
              ),
            );
          } else {
            items.push(body as string);
          }

          const bagdeStyle =
            type === 'line'
              ? {
                  background: colors.borderColor,
                  height: '2px',
                }
              : {
                  background: colors.backgroundColor,
                  height: '10px',
                  borderRadius: '50%',
                };

          return items.map((item, index2) => (
            <li key={'' + index + index2} className={s.item}>
              <span className={s.badge} style={bagdeStyle} />
              {item}
            </li>
          ));
        })}
      </ul>
    </div>
  );
};
