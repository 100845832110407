import React, { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardKeyResultDefault } from 'src/components/Cards/CardKeyResultDefault';
import { ChartSelf, EChartSelfType } from 'src/components/Charts/ChartSelf';
import { PieChart } from 'src/components/Charts/PieChart';
import { TableBills } from 'src/components/Charts/TableBills';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { EViewType } from 'src/components/KeyResults';
import { useGetDetails } from 'src/components/KeyResults/KeyResultsHelpers';
import { EPredefinedModalIds } from 'src/constants/modals';
import { DashboardHeader } from 'src/features/Results/DashboardHeader';
import { LiteWorldMap } from 'src/features/Results/SingleCommunityResults/LiteWorldMap';
import {
  CommunityEnergyProfileInfoText,
  SCMMyCommunityNotification,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/features/Results/SingleCommunityResults/SingleCommunityConstants';
import { useScmAccumulatedBillsDifferencesQuery } from 'src/graphql';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAccumulatedResultsWithSubscription } from 'src/hooks/useAccumulatedResultsWithSubscription';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import {
  selectSettingsData,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationJobUuid } from 'src/redux/configuration/configuration.selectors';
import { selectCommunityAsset } from 'src/redux/configuration/configuration.selectors';
import { setSelectedAreaUuid } from 'src/redux/configuration/configuration.slice';
import {
  selectCommunityBillsDifference,
  selectCommunityKpi,
  selectCommunityKpiDifference,
  selectDataResolution,
  selectIsOperationalCommunity,
} from 'src/redux/scm/scm.selectors';
import {
  setCommunityBillsDifferences,
  setCommunityKpi,
  setCommunityKpiDifference,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EScreenMode } from 'src/typings/configuration.types';
import { TKpi } from 'src/typings/simulation.types';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';

import s from './SingleCommunity.module.scss';
import { TBills } from './SingleCommunityResults.types';
import { TSingleCommunityResultsProps } from './SingleCommunityResults.types';

export const SingleCommunityResults: React.FC<TSingleCommunityResultsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const settingsData = useSelector(selectSettingsData);
  const communityKpi = useSelector(selectCommunityKpi);
  const communityKpiDifference = useSelector(selectCommunityKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const communityBillsDifferences = useSelector(selectCommunityBillsDifference);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const simulationStatus = useSelector(selectSimulationStatus);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityUuid = useSelector(selectCommunityAsset)?.uuid;

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
  } = useAccumulatedResults({
    initialRunQueries: ['getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
  });
  const { data: billsDifferenceResponse } = useScmAccumulatedBillsDifferencesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      jobId: activeJobUuid || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
      uuid: communityUuid || '',
    },
  });
  const bills: TBills = useMemo(
    () =>
      billsDifferenceResponse?.scmAccumulatedBillsDifferences
        ? objectCamelCase(JSON.parse(billsDifferenceResponse?.scmAccumulatedBillsDifferences))
        : {
            boughtFromCommunity: 0,
            boughtFromGrid: 0,
          },
    [billsDifferenceResponse],
  );

  useSingleNotification({
    singleNotification: SCMMyCommunityNotification,
    checkStatement: !isOperationCommunity && !isUserACommunityMember && isOwnerCM,
  });

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  useAccumulatedResultsWithSubscription({
    mode: 'community',
  });

  useEffect(() => {
    if (isOperationCommunity && simulationStatus === 'running') {
    }
  }, [isOperationCommunity, simulationStatus]);

  useEffect(() => {
    if (resultsData?.kpi && resultsData?.billsEnergy)
      dispatch(
        setCommunityKpi(
          pickKpiData({
            kpi: JSON.parse(resultsData?.kpi),
            billsEnergy: JSON.parse(resultsData?.billsEnergy),
          }),
        ),
      );
  }, [resultsData, dispatch]);

  //create a useEffect to dispatch the setCommunityBillsDifferences
  useEffect(() => {
    if (billsDifferenceData) {
      dispatch(setCommunityBillsDifferences(objectCamelCase(JSON.parse(billsDifferenceData))));
    }
  }, [billsDifferenceData, dispatch]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    if (kpiDifferenceData) {
      dispatch(setCommunityKpiDifference(objectCamelCase(JSON.parse(kpiDifferenceData))));
    }
  }, [kpiDifferenceData, dispatch]);

  const onBillClick = useCallback(
    (uuid: string) => {
      dispatch(setSidebarExpanded(true));
      dispatch(setSelectedAreaUuid(uuid));
    },
    [dispatch],
  );

  const currencySymbol = formatter.getCurrencySymbol(settingsData.currency);
  function normalizePercentage(value = 0) {
    return value.toFixed(2).replace('.', ',');
  }

  const { getDetails } = useGetDetails();

  if (!sidebarExpanded) return null;
  return (
    <div className={s.container}>
      <DashboardHeader mode="community" className={s.dashboardHeader} />
      <CardKeyResultDefault
        symbol={currencySymbol}
        symbolPosition="left"
        value={normalizePercentage(communityBillsDifferences?.savings)}
        resultInfo={getDetails(EViewType.CommunitySavings)}
        useTooltip
        className={s.communitySavings}
      />
      <PieChart
        title="Energy Supply Share"
        items={[
          {
            label: 'Community Energy',
            value: communityKpiDifference?.totalSelfConsumptionKwh || 0,
            info: '',
          },
          {
            label: 'Imported Energy (Utility)',
            value: bills?.boughtFromGrid || 0,
            info: '',
          },
        ]}
        isDecimalPercentage={true}
        isTitleInfoActive={true}
        isItemInfoActive={false}
        titleInfo={t(SelfSufficiencyInfoText) || ''}
        className={s.energySupplyShare}
      />

      {isUserACommunityMember && screenMode === EScreenMode.Desktop && (
        <div className={s.litewordMap}>
          <LiteWorldMap className={s.worldMap} />
        </div>
      )}

      <div className={s.tableBills}>
        {!isUserACommunityMember && <TableBills onBillClick={onBillClick} />}
      </div>
      <div className={s.selfSufficiency}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(communityKpiDifference || {})}
          type={EChartSelfType.Sufficiency}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfSufficiencyInfoText) || ''}
        />
      </div>
      <div className={s.selfConsumption}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(communityKpiDifference || {})}
          type={EChartSelfType.Consumption}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfConsumptionInfoText) || ''}
        />
      </div>
      <div className={s.homeEnergyInfo}>
        <HomeEnergyInfo
          generatedValue={`${
            communityKpiDifference?.totalEnergyProducedKwh
              ? (communityKpiDifference?.totalEnergyProducedKwh).toFixed(0)
              : 0
          } kWh`}
          consumedValue={`${
            communityKpiDifference?.totalEnergyDemandedKwh
              ? (communityKpiDifference?.totalEnergyDemandedKwh).toFixed(0)
              : 0
          } kWh`}
        />
      </div>
      <div className={s.energyProfile}>
        <EnergyProfileComponent
          title={t('COMMUNITY_ENERGY_PROFILE')}
          mode={dataResolution}
          initialData={communityKpi || []}
          info={t(CommunityEnergyProfileInfoText) || ''}
        />
      </div>
    </div>
  );
};
