import React, { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT } from 'src/features/Assets/constants';
import { SingleAsset } from 'src/features/Assets/SingleAsset';
import { useUpdateConfigurationAreaMutation } from 'src/graphql';
import { ReadConfigurationDocument } from 'src/graphql';
import { ConfigurationOutput, useReadConfigurationQuery } from 'src/graphql';
import { setSidebarMode } from 'src/redux/application/application.slice';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  selectSelectedAssetUuid,
  selectSelectedAreaUuid,
} from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { areObjectsEqualByFields } from 'src/utils/general';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { dispatchErrorToast, dispatchSuccessToast } from 'src/utils/toasts';

import s from './EditAsset.module.scss';

export const EditAsset: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const areaUuid = useSelector(selectSelectedAreaUuid);
  const assetUuid = useSelector(selectSelectedAssetUuid);

  const { data: configurationResponse } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: !configUuid,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => configurationResponse?.readConfiguration as ConfigurationOutput | null,
    [configurationResponse],
  );
  const asset = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    const communityArea = scenario?.children?.find((child) => child.type === 'Area');
    const area = communityArea?.children?.find((area) => area.uuid === areaUuid);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectCamelCase<any>(area?.children?.find((asset) => asset.uuid === assetUuid));
  }, [configuration, assetUuid, areaUuid]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newAsset, setNewAsset] = useState<any>(asset);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [originalAsset, setOriginalAsset] = useState<any>(asset);

  const [updateConfigurationArea] = useUpdateConfigurationAreaMutation({
    errorPolicy: 'none',
    refetchQueries: [{ query: ReadConfigurationDocument, variables: { uuid: configUuid } }],
    onCompleted: () => {
      dispatchSuccessToast();
      setOriginalAsset(newAsset);
    },
    onError: (err) => {
      dispatchErrorToast(err);
    },
  });

  const onCancelClick = () => dispatch(setSidebarMode('assetPreview'));

  const onChangeSingleAsset = (values: TValuesByFieldName) => {
    setNewAsset(values);
  };

  const onClickSave = () => {
    if (assetUuid && configUuid) {
      const areaParams = ASSET_TYPE_TO_FIELDS_PARAMS_COMPONENT[newAsset.type]['params']({
        values: newAsset,
      });

      return updateConfigurationArea({
        variables: {
          configurationUuid: configUuid,
          areaUuid: assetUuid,
          areaParams,
        },
      });
    }
  };

  const canSave = useMemo(() => !areObjectsEqualByFields(newAsset, originalAsset), [
    newAsset,
    originalAsset,
  ]);

  return (
    <div className={s.editAsset}>
      <div className={s.container}>
        <header className={s.header}>
          <p>{t('navigation.EDIT_ASSET')}</p>
          <BaseButton theme="grey3" onClick={onCancelClick} className={s.cancelButton}>
            <BaseIcon icon="close" className={s.svgIcon} />
          </BaseButton>
        </header>

        {assetUuid && areaUuid && <SingleAsset onChange={onChangeSingleAsset} asset={newAsset} />}
      </div>

      <BaseButton
        type="submit"
        className={s.saveSettings}
        form={'asset-settings'}
        disabled={!canSave}
        onClick={onClickSave}>
        Save
      </BaseButton>
    </div>
  );
};
